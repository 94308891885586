import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Select from "../../../../components/input/Select";
import Option from "../../../../components/input/SelectOptions";
import BankName from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/BankName";
import JobName from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/JobName";
import OptionsField from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/OptionsField";
import MonthPicker from "../../../../appcomponents/EscrowTrakker/detailbanks/FiltersTransaction";
import DatePicker from "../../../../components/datepickers/DatePicker";
//import Select from "../../../../components/input/Select";
//import Option from "../../../../components/input/SelectOptions";
import useTrialBalanceSummaryForm from "../../../../appcomponents/EscrowTrakker/forms/reports/useTrialBalanceSummaryForm";
import { connect } from "react-redux";
import RootForm from "../../../../components/form/index";
import Button from "../../../../components/button";
import { changePassword } from "../../../../configurations/Schemas/index";
import { Row, Col } from "antd";
import { changePasswordRequest } from "../../../../actions/App";
import { trialBalanceSummaryRequest } from "../../../../actions/Reports";
import { closeAllModal } from "../../../../actions/Modal";
import moment from "moment";

const TrialBalanceSummary = (props) => {
  const [jobValue, setJobValue] = useState(props.initialValues ? props.initialValues.property_name : undefined);

  const mainForm = useTrialBalanceSummaryForm({
    initialValues: props.initialValues
      ? {
          ...props.initialValues,
        }
      : {
          bank_account_id: "",
          tenant_id: "",
          property_id: "",
          end_date: moment().format("YYYY-MM-DD"),
        },

    onSubmit: (values) =>
      props.trialBalanceSummaryRequest(
        { ...values, format: props.format },
        (report) => {
          props.closeAllModal();
          props.history.push({
            pathname: "/reports/preview-trial-balance-summary",
            state: {
              referrer: "/reports",
              referrerState: {
                ...props.location.state,
                trialBalanceSummaryValues: {
                  formValues: values,
                  format: props.format,
                },
              },
              report,
              format: props.format,
            },
          });
        }
      ),
  });

  console.log("FORM", mainForm);

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      className="textCenter"
      gutter={[0, 30]}
    >
      <Col span={24} style={{ padding: 0 }}>
        <h4 className="mr-b mr-t-md" style={{ marginBottom: "2.5rem" }}>
          Trial Balance Summary
        </h4>
      </Col>

      <Col xs={24} sm={20} md={22} lg={16}>
        <Row type="flex" justify="center">
          <Col span={24} style={{ textAlign: "left" }}>
            <BankName
              className="inputField"
              name="bank_account_id"
              placeholder="All Bank Accounts"
              withoutAddButton
              hideDeleteBtn
              withAllOption
              isReport={true}
              initialValue={mainForm.values.bank_name}
              onChange={(id, name) => {
                mainForm.customHandleChange(id, "bank_account_id");
                mainForm.customHandleChange(name, "bank_name");
                mainForm.setFieldValue("tenant_name", "All Tenants");
                mainForm.setFieldValue("property_name", "All Properties");
                setJobValue("All Properties");
              }}
              onBlur={(e) => mainForm.customHandleBlur(e, "bank_account_id")}
            />
            {mainForm.touched["bank_account_id"] &&
            mainForm.errors["bank_account_id"] ? (
              <div className="dangerColor caption mr-b">
                {mainForm.touched["bank_account_id"] &&
                  mainForm.errors["bank_account_id"]}
              </div>
            ) : null}
          </Col>

          <Col span={24} style={{ textAlign: "left" }}>
            <OptionsField
              className="inputField"
              //title="Lawyer Name"
              //addBtnText="Add Lawyer"
              name="lawyer_id"
              //link="/add-contact"
              tenantValue={mainForm.values.tenant_name}
              contactType="tenant"
              placeholder="All Tenants"
              id={mainForm.values.bank_account_id}
              isBankACC={!!mainForm.values.bank_account_id}
              isCharts={true}
              isEmail={!!mainForm.values.bank_account_id}
              withoutAddButton
              withClearOption
              initialValue={mainForm.values.lawyer_name}
              onChange={(id, name) => {
                mainForm.customHandleChange(id, "tenant_id");
                mainForm.customHandleChange(name, "tenant_name");
              }}
              onBlur={(e) => mainForm.customHandleBlur(e, "lawyer_id")}
            />
            {mainForm.touched["lawyer_id"] && mainForm.errors["lawyer_id"] ? (
              <div className="dangerColor caption mr-b">
                {mainForm.touched["lawyer_id"] && mainForm.errors["lawyer_id"]}
              </div>
            ) : null}
          </Col>

          <Col span={24} style={{ textAlign: "left" }}>
            <JobName
            className="inputField"
            name="job_id"
            jobValue={jobValue}
            withJobValue
            placeholder="All Properties"
            withoutAddButton
            hideDeleteBtn
            withClearOption
            id={mainForm.values.bank_account_id}
            isBankACC={!!mainForm.values.bank_account_id}
            isCharts={true}
            //withAllOption={mainForm.values.report_type !== "detail"}
            onChange={(id, name) => {
              mainForm.customHandleChange(id, "property_id");
              mainForm.customHandleChange(name, "property_name");
              setJobValue(name || id);
            }}
            onBlur={e => mainForm.customHandleBlur(e, "job_id")}
            />
            {mainForm.touched["job_id"] && mainForm.errors["job_id"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["job_id"] && mainForm.errors["job_id"]}
            </div> : null}
          </Col>
          <Col span={24} style={{ textAlign: "left", marginLeft: "5px" }}>
            <h6>End Date</h6>
          </Col>
          <Col span={24} style={{ textAlign: "left" }}>
            <DatePicker
              allowClear={false}
              //open={yearPickerOpen}
              //onOpenChange={open => setYearPickerOpen(open)}
              modalField
              className="inputField"
              name="end_date"
              value={
                mainForm.values.end_date
                  ? moment(mainForm.values.end_date)
                  : undefined
              }
              //onChange={e => mainForm.customHandleChange(e, "year")}
              //onFocus={() => setYearPickerOpen(true)}
              onBlur={(e) => {
                mainForm.customHandleBlur(e, "end_date");
                //setYearPickerOpen(false);
              }}
              style={{ width: "100%" }}
              //picker="year"
              format="MM-DD-YYYY"
              //mode="year"
              placeholder="End Date"
              onChange={(e) => {
                // if(!mainForm.values.date_type === "quaterly")
                //   mainForm.customHandleChange(e.year(), "year")
                // else
                //   mainForm.onChangeQuarterYear(e, "year")
                mainForm.customHandleChange(e.format("YYYY-MM-DD"), "end_date");

                //setYearPickerOpen(false);
              }}
            />
            {mainForm.touched["end_date"] && mainForm.errors["end_date"] ? (
              <div className="dangerColor caption mr-b">
                {mainForm.touched["end_date"] && mainForm.errors["end_date"]}
              </div>
            ) : null}
          </Col>

          <Col span={16} className="mr-t-md">
            <Button
              loading={props.reports.trialBalanceSummaryLoading}
              block
              onClick={mainForm.handleSubmit}
            >
              Preview Report
            </Button>
          </Col>

          <Col span={16} className="mr-t">
            <Button block outlined onClick={props.closeAllModal}>
              Cancel
            </Button>
          </Col>
        </Row>
        {/* // controls={[
          //   {
          //     span: 24,
          //     className: "inputField",
          //     name: "bankAccount",
          //     //value: this.state.bankAccount,
          //     placeholder: "All Bank Accounts",
          //     props: {
          //       withoutAddButton: true,
          //       hideDeleteBtn: true
          //     },
          //     Type: AccountName
          //   },
          //   {
          //     span: 24,
          //     className: "inputField",
          //     name: "new_password",
          //     value: this.state.new_password,
          //     placeholder: "New Password",
          //     Type: Select
          //   },
          //   {
          //     span: 24,
          //     className: "inputField",
          //     name: "confirm_password",
          //     value: this.state.confirm_password,
          //     placeholder: "Confirm Password",
          //     Type: Select
          //   } */}
      </Col>
    </Row>
  );
};

export default withRouter(
  connect((state) => ({ reports: state.Reports }), {
    trialBalanceSummaryRequest,
    closeAllModal,
  })(TrialBalanceSummary)
);
