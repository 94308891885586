import React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import Image from "../../../components/image";
import bannerImg from "../../../assets/images/escrow-banner-adminpanel.png";
import bannerIos from "../../../assets/images/ios.png";
import appleDownload from "../../../assets/icons/apple-store-apple.svg";
import androidDownload from "../../../assets/icons/google-play-badge.svg";

const RowWrap = styled(Row)`
.li-Option-mr-b{
  margin-bottom: 0.5rem;
}
  & .bannerDashImg {
    z-index: 0;
    text-align: right;
    align-self: center;
    position: static;
  }
  & .dashboardImg {
    position: absolute;
    right: -9%;
    top: 50%;
    transform: translateY(-50%);
    width: 60%;
  }
  & .mobImg {
    position: absolute;
    right: 25rem;
    width: 40%;
    bottom: -10.5rem;
  }
  @media screen and (max-width: 991px) {
    & .mobImg {
      right: 13rem;
      width: 45%;
      bottom: -10.5rem;
    }
  }
  @media screen and (max-width: 767px) {
    & .dashboardImg {
      position: relative;
      right: auto;
      top: auto;
      transform: translateY(0);
      width: auto;
    }
    & .mobImg {
      right: 0;
      width: 60%;
      bottom: -7.5rem;
    }
  }
`;

const lawyersEdition = [
  " Cloud based",
  "Double entry",
  " Based on multi-variable entry and search functions",
  // "Multi-platform",
  // "Real time"
];
const banner = props => {
  return (
    <RowWrap gutter={[60, 60]} type="flex" align="stretch" justify="center">
      <Col xs={24} sm={24} lg={10} md={12}>
        <Row
          type="flex"
          align="middle"
          justify="center"
          style={{ height: "100%" }}
        >
          <Col span={24} style={{ height: "100%" }}>
            <h1
              style={{ margin: "1em 0 1.5em" }}
              className="fontItalic fontBold"
            >
              Landlords Edition
            </h1>
            <p
              style={{
                marginBottom: "1em",
                width: "66%",
                color: "#1E1E22",
                fontWeight: "600"
              }}
            >
              A revolution in escrow accounting. This simple, easy
              patent-pending accounting system is:
            </p>

            <ul
              className="textLeft"
              style={{ width: "60%", color: "#58BA27", paddingInlineStart: 18 }}
            >
              {lawyersEdition.map((content, index) => (
                <li key={index} className="li-Option-mr-b">
                  <span style={{ color: "#1E1E22" }} className="fontMedium">
                    {content}
                  </span>
                </li>
              ))}
            </ul>
            <Row
              type="flex"
              gutter={18}
              justify={props.size <= 767 ? "center" : null}
            >
              <Col xs={10} sm={10} md={8} lg={8} xl={8}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.escrowtrakker"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>
                    <Image src={androidDownload} alt="Android" />
                    <img height="1" width="1" style={{display: "none"}} alt="" src="https://px.ads.linkedin.com/collect/?pid=2247842&conversionId=2580618&fmt=gif" />
                  </div>
                </a>
              </Col>
              <Col xs={10} sm={10} md={8} lg={8} xl={8}>
                <a
                  href="https://apps.apple.com/us/app/id1495369177"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>
                    <Image src={appleDownload} alt="IOS" />
                    <img height="1" width="1" style={{display: "none"}} alt="" src="https://px.ads.linkedin.com/collect/?pid=2247842&conversionId=2580618&fmt=gif" />
                  </div>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col lg={14} md={12} sm={24} xs={24} className="bannerDashImg">
        <div>
          <Image className="dashboardImg" src={bannerImg} alt="Banner" />

          <Image src={bannerIos} alt="Banner Ios" className="mobImg" />
        </div>
      </Col>
    </RowWrap>
  );
};

export default banner;
