import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TransferTotalCard from "../../../EscrowTrakker/dashboard/TransferTotalCard";
import Button from "../../../../components/button";
import Field from "../InputWithTitle";
import AccountName from "../InputWithTitle/selectWithSearch/AccountName";
import JobName from "../InputWithTitle/selectWithSearch/JobName";
import Job from "../../../../views/shell/addtranscation/Job";
import AddAccountName from "../../../../views/shell/addtranscation/AddAccountName";
import { openModal } from "../../../../actions/Modal";
import PlusButton from "../IconButton";
import { Row, Col } from "antd";
import Item from "antd/lib/list/Item";
import { filter } from "minimatch";

const Split = props => {
  let { splits, amount, remainingAmount, errors, touched, disabled, filterIds } = props;
  //let splitTotal = splits && splits.length ? splits.reduce((total, current) => total + (parseInt(current.amount) || 0), 0) : 0;
  const [initialValues, setInitialValues] = useState(props.initialValues);

  //To reflect deleted accounts and jobs throughout the split
  const [deletedAccount, setDeletedAccount] = useState(null);
  const [deletedJob, setDeletedJob] = useState(null);

  useEffect(() => {
    //if(!splits || !splits.length) {
      setInitialValues(undefined)
    //}
  }, [])
  return (
    <Row gutter={[10, 30]} type="flex" justify="center" align="stretch">
      <Col span={24} style={{ textAlign: "center" }}>
        <Button disabled={disabled} onClick={splits && splits.length ? props.onClearSplits : props.onAddSplit} outlined style={{ width: "20%" }}>
          {splits && splits.length ? "Clear Splits" : "Split Transaction"}
        </Button>
      </Col>

      {splits && splits.length ? (
        <React.Fragment>
          <Col xs={24} sm={12}>
            <TransferTotalCard
              heading="Total Amount"
              // label="bank name"
              amount={`${props.settings.currency || "$"}` + (parseFloat(amount) ? parseFloat(amount).toFixed(2) : "0.00")}
            />
          </Col>

          <Col xs={24} sm={12}>
            <TransferTotalCard
              heading="Amount Remaining"
              // label="bank name"
              amount={`${props.settings.currency || "$"}` + (parseFloat(remainingAmount) ? parseFloat(remainingAmount).toFixed(2) : "0.00")}
            />
          </Col>

          {splits.map((item, index) => {
            return (
              <Col span={20}>
                <Row gutter={20} type="flex" gutter={[40, 30]}>
                  <Col xs={24} sm={12}>
                    <AccountName
                      name="account_head_id"
                      //className="inputField"
                      disabled={disabled}
                      addBtnText="Add Account Name"
                      addBtnClick={() =>
                        props.openModal(
                          null,
                          <AddAccountName accType={props.accountType} />,
                          null,
                          null,
                          { footer: null }
                        )
                      }
                      //value={splits[index]["account_head_id"]}
                      initialValue={initialValues && initialValues[index] && [initialValues[index].account_head_name, initialValues[index].account_head_id]}
                      accountType={props.accountType}
                      placeholder="Select Account Name"
                      isEditTransaction={props.editTransaction}
                      //filterIds={splits.map(val => val.account_head_id).concat(filterIds)}
                      accountSplitID={splits.map(val => val.account_head_id)}
                      onChange={e =>{
                        props.onSplitChange(e, "account_head_id", index);
                        console.log("E new index: ", e, "INDEX:", index);
                      }
                      }
                      onBlur={e => props.onSplitBlur(e, "account_head_id", index)}
                      onDelete={id => setDeletedAccount(id)}
                      deletedAccount={deletedAccount}
                      title="Account Name"
                    />
                    <span className="dangerColor caption">{touched && errors && touched[index] && errors[index] && touched[index]["account_head_id"] && errors[index]["account_head_id"]}</span>
                  </Col>
                  {/* 
                  <Col xs={24} sm={12}>
              <AccountNameField
                key={formId}
                title="Account Name"
                addBtnText="Add Account Name"
                accountType="income"
                placeholder="Select Account Name"
                accValue={[mainForm.values.accountNameString, mainForm.values.accountName]}
                //accValue={ localStorage.getItem("accname") ? localStorage.getItem("accname") : console.log("abc")}
                //accountID={mainForm.values.accountName ? mainForm.values.accountName : console.log("abc") }
                onMouseDown={e => e.preventDefault()}
                addBtnClick={() =>
                  props.openModal(
                    null,
                    <AddAccountName accType="income" />,
                    null,
                    null,
                    { footer: null }
                  )
                }
                name="accountName"
                onChange={(e, v, name) => { 
                  mainForm.customHandleChange(e, "accountName");
                  mainForm.customHandleChange(name, "accountNameString");
                  console.log("ABVD", name); 
                  localStorage.setItem("accname", name);
                  setAccountName(name);
                  setAccountID(e);
                }}
                // mainForm.customHandleChange(name, "accname");
                // console.log("name is this: ", name);
                // //mainForm.customHandleChange(name, "accountNameer");
                // //localStorage.setItem("accname", name);
                onBlur={e => mainForm.customHandleBlur(e, "accountName")}
              />
              <span className="dangerColor caption textCenter">
                {mainForm.touched["accountName"] &&
                  mainForm.errors["accountName"]}
              </span>
            </Col>
                  
                  <Col xs={24} sm={12}>
                    <JobName
                      name="job_id"
                      addBtnText="Add Job Name"
                      addBtnClick={() =>
                        props.openModal(null, <Job />, null, null, {
                          footer: null
                        })
                      }
                      initialValue={initialValues && initialValues[index] && [initialValues[index].job_name, initialValues[index].job_id]}
                      //className="inputField"
                      disabled={disabled}
                      placeholder="Select Job"
                      onChange={e => props.onSplitChange(e, "job_id", index)}
                      onBlur={e => props.onSplitBlur(e, "job_id", index)}
                      onDelete={id => setDeletedJob(id)}
                      deletedJob={deletedJob}
                      title="Job Name"
                    />
                    <span className="dangerColor caption">{touched && errors && touched[index] && errors[index] && touched[index]["job_id"] && errors[index]["job_id"]}</span>
                  </Col> */}
                  <Col xs={24} sm={12}>
                    <Field
                      name="amount"
                      value={splits[index]["amount"]}
                      placeholder={`${props.settings.currency || "$"}0.00`}
                      disabled={disabled}
                      onChange={e => props.onSplitChange(e, "amount", index)}
                      onBlur={e => props.onSplitBlur(e, "amount", index)}
                      title="Amount"
                    />
                    <span className="dangerColor caption">{touched && errors && touched[index] && errors[index] && touched[index]["amount"] && errors[index]["amount"]}</span>
                  </Col>
                </Row>
              </Col>
            );
          })}
          <Col span={24}>
          <Row type="flex" justify="center" align="middle">
            <Col>
            <PlusButton disabled={disabled} onClick={props.onAddSplit}>
              Add More
            </PlusButton>
            </Col>
          </Row>
          </Col>
        </React.Fragment>
      ) : null}
    </Row>
  );
};

export default connect(state => ({ settings: state.settings.settings }), { openModal })(Split);
