import React, { useState } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
// import { openModal, closeAllModal } from "../../actions/Modal";
import PopconfirmWrapper from "../../components/pop-confirm";
import TableWrapper from "../../components/react-table";
import TagWrapper from "../../components/tag";
import Switch from "../../components/switch";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import {
  searchTransactionsRequest,
  deleteTransactionRequest,
  ClearedTransactionsRequest,
} from "../../actions/SearchTransactions";
import { FiEdit, FiMoreHorizontal, FiAlertTriangle } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { Divider } from "antd";
import { current_sprint } from "../../configurations/Constants";
import PopOver from "../../components/popover";
import SearchWithFilter from "../../appcomponents/general/ContentHeaderComponents/SearchWithFilter";
import Select from "../../components/input/Select";
import Options from "../../components/input/SelectOptions";
import MonthPickerFilter from "../../appcomponents/EscrowTrakker/detailbanks/FiltersTransaction";
import YearPicker from "../../components/datepickers/DatePicker";
import { AccountingFormat } from "../../configurations/Config";

let map = {
  DEPOSIT: "primary",
  WITHDRAWAL: "secondary",
  "interest deposit": "third",
  "interest withdrawal": "fourth",
  //Banned: "fourth
};

const SearchTransactions = (props) => {
  // console.log("Users props", props);
  // const [profileOpen, setProfileOpen] = useState(false);
  // const [type, setType] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(moment());
  // const [selectedMonth, setSelectedMonth] = useState("");
  const [tableChanged, setTableChanged] = useState(false);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    like: props.location.state ? props.location.state.like : "",
    transaction_type: props.location.state
      ? props.location.state.transaction_type
      : undefined,
    month: props.location.state ? props.location.state.month : "",
    year: props.location.state
      ? props.location.state.year
      : moment().format("YYYY"),
    //amount: pro
  });
  const [filterText, setFilterText] = useState('');
  console.log("props . new trasnsa", props.transactionsData.transactions);
  const [state] = useState({
    apiCall: (...params) => {
      props.searchTransactionsRequest(...params);
      console.log("SERACH API", params);
    },
  });
  const searchChange = (e) => {
    // console.log("serachChange", e);
    setSearch(e);
    console.log("EEERRRR: ", e);
    setFilters({ ...filters, like: e });
    // //let filteredTransactions = 
    //   setFilterText(e); 
    //   let filteredTransactions = props.transactionsData.transactions.filter((trans) => trans.amount.includes(e));
    //   console.log("these are filtered: ", filteredTransactions);
    //  setBankAccountList(filteredBankAccounts);
  };
  // const filterChange = e => {
  //   setFilter(e);
  // };
  const resetFilters = () => {
    setSearch("");
    // setFilter("");
  };

  const handleSelect = (e) => {
    // console.log("handle select", e);
    // setType(e);
    // setSearch(e);
    setFilters({ ...filters, transaction_type: e });
  };

  const handleYearChange = (val) => {
    // console.log("handleYearChange", val);
    // let year = date.format("YYYY");
    setOpen(false);
    setFilters({ ...filters, year: val.format("YYYY") });
    setSelectedYear(val);
  };

  const handleFilterChange = (val) => {
    // fun(val);
    setFilters({ ...filters, month: val });
  };

  // console.log("serach Transaction Props", handleYearChange("sadasd"));
  return (
    <React.Fragment>
      <Row gutter={[0, 20]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Row type="flex" justify="start" align="middle" gutter={[20, 20]}>
            <Col xs={24} sm={12}>
              <SearchWithFilter
                onChange={(e) => searchChange(e)}
                value={filters.like}
                istransact={true}
              />
            </Col>
            <Col xs={24} sm={12} md={10}>
              <Select
                style={{ width: "100%", boxShadow: "none" }}
                value={filters.transaction_type}
                onChange={(e) => handleSelect(e)}
                placeholder="View All"
              >
                <Options value="">View All</Options>
                <Options value="deposit">Deposit</Options>
                <Options value="withdrawal">Withdrawal</Options>
                <Options value="interest deposit">Interest Deposit</Options>
                <Options value="interest withdrawal">Interest Withdrawal</Options>
              </Select>
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={12}>
          <Row type="flex" justify="end" align="middle" gutter={[20, 20]}>
            <Col xs={12} md={7}>
              <MonthPickerFilter
                months={{
                  value: filters.month,
                  onChange: (val) => handleFilterChange(val),
                }}
              />
            </Col>

            <Col xs={12} md={8}>
              <YearPicker
                className="no-mr"
                style={{ width: "100%" }}
                allowClear={false}
                open={isOpen}
                picker="year"
                filters={filters}
                value={selectedYear}
                onOpenChange={(open) => setOpen(open)}
                onFocus={() => setOpen(true)}
                onBlur={() => setOpen(false)}
                format="YYYY"
                noBorder
                mode="year"
                placeholder="Year"
                onPanelChange={handleYearChange}

                // months={{
                //   value: selectedMonth,
                //   onChange: e => handleFilterChange(e, setSelectedMonth)
                // }}
              />
            </Col>
          </Row>
        </Col>

        {current_sprint > 1 ? (
          <Col span={24} style={{ margin: "40px 0px 50px 0px" }}>
            <TableWrapper
              tableData={props.transactionsData.transactions}
              getData={state.apiCall}
              search={search}
              filters={filters}
              pageSize={10}
              dataCount={parseInt(props.transactionsData.totalCount)}
              getTrProps={(state, rowInfo, column) => ({
                onClick: () =>
                  props.history.push({
                    pathname: `/view-transaction/${rowInfo.original.transaction_source_id}`,
                    state: {
                      referrer: "/search-transaction",
                      referrerState: {
                        transaction_type: filters.transaction_type,
                        month: filters.month,
                        year: filters.year,
                        like: filters.like,
                      },
                    },
                  }),
                style: { cursor: "pointer" },
              })}
              getTdProps={(state, rowInfo, column, c, d) => {
                console.log(state, rowInfo, column, c, d);
                return column.className === "clickable" ? {
                    onClick: e => e.stopPropagation(),
                    style: { cursor: "default" }
                  } : {
  
                  }
                }
              }
              // loading={props.transactionsData.tableLoading}
              //enableReInit={true}
              tableChanged={tableChanged}
              content={[
                {
                  name: "Account Name",
                  id: "account_name",
                  style: { color: "red" },
                  render: (r) => (
                    <span
                      //  style={{
                      //     fontStyle:
                      //       r.original.is_split === "true" ? "italic" : "",
                      //   }}
                      className={
                        r.original.is_split === true
                          ? null
                          : r.original.transaction_type === "deposit" || r.original.transaction_type === "interest deposit"  
                          ? "appColor"
                          : "secondaryAppColor"
                      }
                    >
                      {r.original.is_split === true ? (
                        <TagWrapper
                        style={{
                          width: "100px",
                          backgroundColor: "orange"
                          //fontStyle:"italic"
                        }}
                        className="small"
                        // type={
                          // map[
                          //   // r.original.is_recurring === "true"
                          //   //   ? "RECURRING"
                          //   //   :
                          //   //r.original.transaction_type.toUpperCase()
                          // ]
                        //}
                      >
                        {
                          // r.original.is_recurring === "true"
                          //   ? "Recurring"
                          //   :
                          "Splits"
                        }
                      </TagWrapper>
                      ) : r.original.account_name !== "" ? (
                        r.original.account_name
                      ) : (
                        "-"
                      )}
                    </span>
                  ),
                },
                {
                  name: "Tenant Name",
                  id: "tenant_name",
                },
                {
                  name: "Property Name",
                  id: "property_name",
                },
                {
                  name: "Date",
                  id: "date_of_transaction",
                  render: (r) => (
                    <span>
                      {r.original.date_of_transaction
                        ? moment(r.original.date_of_transaction).format(
                            "MM/DD/YYYY"
                          )
                        : "-"}
                    </span>
                  ),
                },

                {
                  name: "Amount",
                  id: "amount",
                  render: (r) => (
                    <span
                      // style={{
                      //   fontStyle:
                      //     r.original.is_split === "true" ? "italic" : "",
                      // }}
                      className={
                        // r.original.is_split === "true"
                        //   ? null
                        //   :
                        r.original.transaction_type === "deposit" || r.original.transaction_type === "interest deposit"
                          ? "appColor"
                          : "secondaryAppColor"
                      }
                    >
                      {r.original.is_split === "true"
                        ? AccountingFormat(
                            r.original.amount,
                            r.original.currency
                          )
                        : AccountingFormat(
                            r.original.amount,
                            r.original.currency
                          )}
                    </span>
                  ),
                },
                {
                  name: "Cleared",
                  id: "cleared",
                  className: "clickable",
                  render: r => (
                    console.log("r orignal cleared: ", r.original.is_cleared),
                    ( r.original.transaction_type === "interest deposit" || r.original.transaction_type === "interest withdrawal" ? "-"
                    // <div style={{ width: "100%" }} onClick={e => e.stopPropagation()}>
                    // </div>
                    : <PopconfirmWrapper
                    //onClick={e => e.stopPropagation()}
                    title={
                      <h4>
                        Are you sure you want to
                        {r.original.is_cleared === true
                          ? ` unclear `
                          : ` clear`}{" "}
                        this transaction?
                      </h4>
                    }
                    icon={
                      <FiAlertTriangle
                        style={{
                          position: "absolute",
                          color: "#f44336",
                          left: 0,
                          top: 0,
                          marginTop: 6
                        }}
                      />
                    }
                    cancelText="No"
                    okText="Yes"
                    onConfirm={() =>
                      props.ClearedTransactionsRequest(
                        r.original.transaction_source_id,
                        r.original.is_cleared === true ? false : true,
                        () => setTableChanged(!tableChanged)
                      )
                    }
                  >
                    <div
                      style={{
                        //padding: "8px",
                        //width: "100%",
                        display: "flex",
                        alignItems: "center"
                      }}
                      //onClick={e => e.stopPropagation()}
                    >
                    <Switch
                      disabled
                      style={{
                        //padding: "8px",
                        //width: "100%",
                        opacity:"1"
                      }}
                      checked={r.original.is_cleared === true ? true : false}
                      />
                    </div>
                  </PopconfirmWrapper>)
                  )
                },
                {
                  name: "Transaction",
                  id: "transaction_type",
                  large: true,
                  render: (r) => (
                    <React.Fragment>
                      <TagWrapper
                        style={{
                          width: "100%", textTransform: "capitalize", backgroundColor: r.original.transaction_type === "deposit" || r.original.transaction_type === "interest deposit" ? "#58ba27" : "#ff4a4d"
                          //fontStyle:"italic"
                        }}
                        className="small"
                        type={
                          map[
                            // r.original.is_recurring === "true"
                            //   ? "RECURRING"
                            //   :
                            r.original.transaction_type.toUpperCase()
                          ]
                        }
                      >
                        {
                          // r.original.is_recurring === "true"
                          //   ? "Recurring"
                          //   :
                          r.original.transaction_type
                            ? r.original.transaction_type
                            : "-"
                        }
                      </TagWrapper>
                      <span style={{ marginLeft: "1rem" }}>
                        <PopOver
                          onClick={(e) => e.stopPropagation()}
                          // placement="left"
                          style={{ cursor: "pointer" }}
                          content={
                            <div onClick={(e) => e.stopPropagation()}>
                              <div
                                style={{
                                  padding: "8px",
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() =>
                                  props.history.push({
                                    pathname: `/view-transaction/${r.original.transaction_source_id}`,
                                    state: {
                                      referrer: "/search-transaction",
                                      referrerState: {
                                        transaction_type:
                                          filters.transaction_type,
                                        month: filters.month,
                                        year: filters.years,
                                        like: filters.like,
                                      },
                                    },
                                  })
                                }
                              >
                                <FiEdit style={{ marginRight: 5 }} />
                                <span>Edit</span>
                              </div>

                              <Divider style={{ margin: 0 }} />

                              {/* poprConfirm */}

                              <PopconfirmWrapper
                                title={
                                  <h4>
                                    Are you sure you want to delete this
                                    transaction?
                                  </h4>
                                }
                                icon={
                                  <FiAlertTriangle
                                    style={{
                                      position: "absolute",
                                      color: "#f44336",
                                      left: 0,
                                      top: 0,
                                      marginTop: 6,
                                    }}
                                  />
                                }
                                cancelText="No"
                                okText="Yes"
                                onConfirm={() =>
                                  props.deleteTransactionRequest(
                                    r.original.transaction_source_id,
                                    () => setTableChanged(!tableChanged)
                                  )
                                }
                              >
                                {/* <ButtonWrapper className="deleteBtn"> */}
                                <div
                                  style={{
                                    padding: "8px",
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <AiOutlineDelete
                                    className="deleteIcon"
                                    style={{ color: "#FF494C", marginRight: 5 }}
                                  />
                                  <span>Delete</span>
                                </div>
                                {/* </ButtonWrapper> */}
                              </PopconfirmWrapper>

                              {/* <Divider style={{ margin: 0 }} />
                              <PopconfirmWrapper
                                title={
                                  <h4>
                                    Are you sure you want to{" "}
                                    {r.original.cleared === "true"
                                      ? " unclear"
                                      : " clear"}{" "}
                                    this transaction?
                                  </h4>
                                }
                                icon={
                                  <FiAlertTriangle
                                    style={{
                                      position: "absolute",
                                      color: "#f44336",
                                      left: 0,
                                      top: 0,
                                      marginTop: 6,
                                    }}
                                  />
                                }
                                cancelText="No"
                                okText="Yes"
                                onConfirm={() =>
                                  props.ClearedTransactionsRequest(
                                    r.original.transaction_source_id,
                                    r.original.cleared === "true"
                                      ? "false"
                                      : "true",
                                    () => setTableChanged(!tableChanged)
                                  )
                                }
                              >
                                <div
                                  style={{
                                    padding: "8px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Switch
                                    checked={
                                      r.original.cleared === "true"
                                        ? true
                                        : false
                                    }
                                    size="small"
                                    style={{ marginRight: 5 }}
                                  />{" "}
                                  <span>Cleared</span>
                                </div>
                              </PopconfirmWrapper> */}
                            </div>
                          }
                        >
                          <FiMoreHorizontal style={{ color: "#154D94" }} />
                        </PopOver>
                      </span>
                    </React.Fragment>
                  ),
                },
              ]}
            />
          </Col>
        ) : null}
      </Row>
    </React.Fragment>
  );
};
export default withRouter(
  connect(
    (storeState) => ({ transactionsData: storeState.SearchTransactions }),
    {
      searchTransactionsRequest,
      deleteTransactionRequest,
      ClearedTransactionsRequest,
    }
  )(SearchTransactions)
);
