import React from "react";
import { Layout } from "antd";
import styled, { css } from "styled-components";

const { Content } = Layout;

export const StyledContent = css`
  background: ${props => props.theme[props.theme.mode].background.primary};
  color: ${props => props.theme[props.theme.mode].textColor.primary};
  font-size: ${props => props.theme[props.theme.mode].textSizes.relative.body};
  font-weight: 400;
  letter-spacing: 0.021vw;
  line-height: 1.3;
  /* padding: ${props => props.theme[props.theme.mode].spacing.outer} ${props =>
  props.theme[props.theme.mode].spacing.mainscreen}; */
  padding: 2.8rem ${props =>
  props.theme[props.theme.mode].spacing.mainscreen};
  ${props => props.externalscreen ? `
    padding-top: 2rem;
    ` : 
    null
  }
  //padding-bottom: 0;
  margin-top: ${props =>
    props.externalscreen
      ? "0px"
      : props.theme[props.theme.mode].spacing.mainscreen};
  min-height: ${props =>
    props.scroll
      ? `calc(100vh + ${props.theme[props.theme.mode].spacing.mainscreen})`
      : `calc(100vh - ${props.theme[props.theme.mode].spacing.mainscreen})`};
  white-space: normal;
  word-break: break-word;

  margin-left: ${props =>
    props.externalscreen
      ? "0px"
      : `${props.theme[props.theme.mode].spacing.sidebar}`};

  //overflow: hidden;

  /* Typography */
  h1, h3, h4, h5 { color: ${props =>
    props.theme[props.theme.mode].textColor.primary}; }
  h1 {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.heading};
    font-weight: 700;
    line-height: 1.21;
    letter-spacing: 0.075vw;
  }
  h2 {
    //color: ${props => props.theme[props.theme.mode].textColor.primary};
    //color: ${props => props.theme[props.theme.mode].textColor.hint};
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.subHeading};
    font-weight: 700;
    line-height: 1.22;
    letter-spacing: 0.06vw;
  }
  h3 {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.pageHeading};
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 0.034vw;
  }
  h4 {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.generalHeading};
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.027vw;
  }
  h5 {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.smallHeading};
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.027vw;
  }
  h6 {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.bodyLarge};
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.027vw;
  }
  p
   {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.body};
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0.021vw;
  }
  input, .inputField[type], .inputField input, 
  .selectField, .selectField.ant-select-dropdown-menu-item,
  .selectField.ant-select .ant-select-selection__rendered, textarea.inputField.ant-input {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.captions};
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0.021vw;
    //font-style: italic;
  }
  a {
    color: ${props => props.theme[props.theme.mode].primary.main};
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.links};
    //line-height: 1.46;
    letter-spacing: 0.0185vw;
    text-decoration: underline;
  }
  a.caption, .caption a {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.captions};
    text-decoration: none;
  }
  a:hover, a:focus, a:active { text-decoration: underline; }
  /* For Spacing classes Name */
  .inputField {margin-bottom: 4.5%}
  .mr-t-sm { margin-top: 10px!important; }
  .mr-t { margin-top: 15px!important; }
  .mr-t-md { margin-top: 25px!important; }
  .mr-t-lg { margin-top: 65px!important; }
  .mr-b-sm { margin-bottom: 0.46rem!important; }
  .mr-b{ margin-bottom: 15px!important; }
  .mr-b-md { margin-bottom: 25px!important; }
  .mr-b-lg { margin-bottom: 65px!important; }
  p {margin-bottom:0; white-space: normal;}
  .no-mr {margin: 0 !important;}
  .no-pd {padding: 0 !important;}
  .pd-y{padding:1rem 0}

  /* Common classes */
  /* custom Colors */
  .dangerColor { 
    color: ${props => props.theme[props.theme.mode].error.main}; 
    text-align: left;
    font-weight: 600;
  }
  .invertedColor { color: ${props =>
    props.theme[props.theme.mode].textColor.inverted}; }
  .hintColor { color: ${props =>
    props.theme[props.theme.mode].textColor.hint}; }
  .appColor { color: #58ba27; }
  .secondaryAppColor { color: ${props => props.theme[props.theme.mode].secondary.main}; }
  .secondaryAppBackColor { background-color: ${props => props.theme[props.theme.mode].secondary.main}; }
  .whiteColor { color: ${props => props.theme[props.theme.mode].common.white}; }
  .ant-select-dropdown-menu {
    background-color: ${props =>
      props.theme[props.theme.mode].background.primary};
    color: ${props => props.theme[props.theme.mode].textColor.primary}; 
  }
  /* Alignment */
  .textLeft {text-align: left}
  .textRight {text-align: right}
  .textCenter {text-align: center}
  .justifyCenter {justify-content: center}
  .justifyBase {justify-content: baseline}
  .justifyStart {justify-content: flex-start}
  .justifyEnd {justify-content: flex-end}

  /* Font Weights */
  .fontLight {font-weight: 300;}
  .fontNormal {font-weight: 400;}
  .font500 {font-weight: 500;}
  .fontMedium {font-weight: 600;}
  .fontBold {font-weight: 700;}

  .fullWidth {
    width: 100%;
  }
  /* Font Sizes */
  .large {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.banner};
    line-height: 1.2;
    letter-spacing: 0.125vw;
    word-break: normal;
  }
  .caption, .caption p {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.captions};
  }
  .medium {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.pageHeading};
      font-weight: 600;
  }
  .normal {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.body};
  }
  .small {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.links};
    line-height: 1.46;
    letter-spacing: 0.0185vw;
  }
  .button, .navigation-bar {
    font-size: ${props => props.theme[props.theme.mode].textSizes.relative.btn};
    letter-spacing: 0.021vw;
  }
  .inputField[type]::-webkit-input-placeholder ,.inputField input::-webkit-input-placeholder, .placeholders,
  textarea.inputField.ant-input::-webkit-input-placeholder, 
  .inputField[type]::placeholder ,.inputField input::placeholder, .placeholders,
  textarea.inputField.ant-input::placeholder, .ant-select-selection__placeholder
  {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.captions};
    color: ${props => props.theme[props.theme.mode].textColor.hint};
    text-align: left;
    letter-spacing: 0.0185vw;
    font-weight: 600;
  }
   /* For block border */ 
  .borderCircle { border-radius: 50%; }
  .borderRound { border-radius: ${props =>
    props.theme[props.theme.mode].card.borderRadius}px;}
  .borderSquare { border-radius: 0; }

  /* For image fit */
  .fitImage {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  /* For Font Style */
  .fontItalic {font-style: italic;}
  .fontNormal {font-style: normal;}
  .fontOblique {font-style: oblique;}
  .allCaps {text-transform: uppercase;}

  hr.stepsLine {
  width: 14%;
  height: 0.2vw;
  background-color: ${props => props.theme[props.theme.mode].primary.main};
  position: relative;
  margin: 0;
  margin-left: 1vw;
  margin-right: auto;
}
hr.stepsLine:after {
  content: "";
  width: 0.45vw;
  height: 0.45vw;
  background-color: ${props => props.theme[props.theme.mode].common.white};
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: auto;
  right: -2px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme[props.theme.mode].primary.main};
  }
/* @Need to remove this block */
  .mainContentDiv {
    margin-bottom: ${props => props.theme[props.theme.mode].spacing.outer}px;
  }
  .mainContentDivSmall {
    margin-bottom: ${props => props.theme[props.theme.mode].spacing.inner}px;
  }
  
  .mainContentDivMedium {
    margin-bottom: ${props =>
      props.theme[props.theme.mode].spacing.inner + 4}px;
  }
/* @Need to remove this block */

  .ant-input {
    height: auto;
    padding: 0.7vw 0.8vw;
    /* padding: 0.7vw 0.2vw; */
  }
  .ant-select-selection {
    //padding: 0.7vw 0.2vw;
  }
  .messageBoxTitle{
    padding-left:0.1em !important;
  }
  .messageBoxTitleHeading, .h3 {
    font-size: 1.05rem;
    font-weight: 700;
    margin-bottom:0em !important;
  }
  .messageBoxTitleDate, .span {
    font-size: 0.76rem;
    font-weight: 700;
    color: ${props => props.theme[props.theme.mode].textColor.hint};
  }
  .messageBoxText, .p {
    margin-top: 1em!important;
    margin-bottom:2em !important;
  }
  .flagBoxReasonText, .p {
    font-size: 0.76rem;
    margin-top: 1em!important;
  }
  .flagBoxReportText, .p {
    font-size: 0.76rem;
    margin-top: 0.5em!important;
  }

  /* Reharts Pie Charts */
  .recharts-wrapper .recharts-legend-wrapper ul.recharts-default-legend {
    display: flex;
    flex: 1;
    flex-wrap: nowrap;
    justify-content: space-around;
  }
  .recharts-wrapper .recharts-legend-wrapper ul.recharts-default-legend 
  li.recharts-legend-item {
    display: flex!important;
    flex: 1;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-right: 5px !important;
  }
  .recharts-wrapper .recharts-legend-wrapper ul.recharts-default-legend 
  li.recharts-legend-item svg {
    width: 25%;
    height: 25%;
    margin-right: -5px !important;
  }
/* Media Queries */
  @media screen and (max-width: 991px) {
    margin-bottom: calc(${props =>
    props.externalscreen
      ? "0px"
      : props.theme[props.theme.mode].spacing.mainscreen} + 10px);

    ${props => props.externalscreen ? "margin-bottom: 0;" : null}

    margin-left: 0;
    font-size: calc(${props =>
      props.theme[props.theme.mode].textSizes.relative.body} - 4px);
    letter-spacing: 0.4px;
    h1 {
      font-size: calc(${props =>
        props.theme[props.theme.mode].textSizes.relative.heading} - 4px);
      letter-spacing: 1.44px;
    }
    h2 {
      font-size: calc(${props =>
        props.theme[props.theme.mode].textSizes.relative.subHeading} - 4px);
      letter-spacing: 1.17px;
    }
    h3 {
      font-size: calc(${props =>
        props.theme[props.theme.mode].textSizes.relative.pageHeading} - 4px);
      letter-spacing: 0.63px;
    }
    h4 {
      font-size: calc(${props =>
        props.theme[props.theme.mode].textSizes.relative.generalHeading} - 4px);
      letter-spacing: 0.5px;
    }
    h5 {
    font-size: calc(${props =>
      props.theme[props.theme.mode].textSizes.relative.smallHeading} - 4px);
    letter-spacing: 0.4px;
  }
  h6 {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.bodyLarge};
    letter-spacing: 0.4px;
  }
    p
    {
      font-size: calc(${props =>
        props.theme[props.theme.mode].textSizes.relative.body} - 4px);
      letter-spacing: 0.4px;
    }
    .large {
      font-size: calc(${props =>
        props.theme[props.theme.mode].textSizes.relative.banner} - 4px);
      line-height: 1.2;
      letter-spacing: 0.125vw;
    }
    .caption, .caption p, a.caption, .caption a {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.captions};
  }
    a, .small {
      font-size: calc(${props =>
        props.theme[props.theme.mode].textSizes.relative.links} - 4px);
      letter-spacing: 0.35px;
    }
    .button, .navigationBar {
      font-size: calc(${props =>
        props.theme[props.theme.mode].textSizes.relative.btn} - 4px);
      letter-spacing: 0.45px;
    }
  /* Font Sizes */
  input, .inputField[type], .inputField input, 
  .selectField, .selectField.ant-select-dropdown-menu-item,
  .selectField.ant-select .ant-select-selection__rendered, textarea.inputField.ant-input,
  .inputField[type]::-webkit-input-placeholder, .inputField input::-webkit-input-placeholder, .placeholders,
  textarea.inputField.ant-input::-webkit-input-placeholder, 
  .inputField[type]::placeholder ,.inputField input::placeholder, .placeholders,
  textarea.inputField.ant-input::placeholder, .ant-select-selection__placeholder {
    font-size: calc(${props =>
      props.theme[props.theme.mode].textSizes.relative.captions} - 4px);
    letter-spacing: 0.38px;
  }
  .recharts-wrapper .recharts-legend-wrapper ul.recharts-default-legend 
  li.recharts-legend-item  {flex-wrap: wrap;}
  .recharts-wrapper .recharts-legend-wrapper ul.recharts-default-legend 
  li.recharts-legend-item svg {margin-right: 4px !important}
  .ant-input {
      padding: .5rem;
    }
  .ant-select-selection {
    //padding: calc(.5rem + 1px) .5rem;
    padding: calc(.5rem) .5rem;
  }
  .profilePic {
    height: 100% !important;
  }
  }
  @media screen and (max-width: 767px){
    padding: 35px 20px;
    font-size: ${props => props.theme[props.theme.mode].textSizes.actual.body};
    letter-spacing: 0.4px;
    h1 {
      font-size: ${props =>
        props.theme[props.theme.mode].textSizes.actual.heading};
      letter-spacing: 1.44px;
    }
    h2 {
      font-size: ${props =>
        props.theme[props.theme.mode].textSizes.actual.subHeading};
      letter-spacing: 1.17px;
    }
    h3 {
      font-size: ${props =>
        props.theme[props.theme.mode].textSizes.actual.pageHeading};
      letter-spacing: 0.63px;
    }
    h4 {
      font-size: ${props =>
        props.theme[props.theme.mode].textSizes.actual.generalHeading};
      letter-spacing: 0.5px;
    }
    h5 {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.actual.smallHeading};
    letter-spacing: 0.4px;
  }
  h6 {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.actual.bodyLarge};
    letter-spacing: 0.4px;
  }
    p, input, .inputField[type], .inputField input, 
  .selectField, .selectField.ant-select-dropdown-menu-item,
  .selectField.ant-select .ant-select-selection__rendered, textarea.inputField.ant-input
    {
      font-size: ${props =>
        props.theme[props.theme.mode].textSizes.actual.body};
      letter-spacing: 0.4px;
    }
    .large {
      font-size: ${props =>
        props.theme[props.theme.mode].textSizes.actual.banner};
      line-height: 1.2;
      letter-spacing: 0.125vw;
    }
    a, .small {
      font-size: ${props =>
        props.theme[props.theme.mode].textSizes.actual.links};
      letter-spacing: 0.35px;
    }
    .button, .navigationBar {
      font-size: ${props => props.theme[props.theme.mode].textSizes.actual.btn};
      letter-spacing: 0.45px;
    }
  /* Font Sizes */
  .inputField[type]::-webkit-input-placeholder, .inputField input::-webkit-input-placeholder, .placeholders,
  textarea.inputField.ant-input::-webkit-input-placeholder, .ant-select-selection__placeholder
  {
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.actual.captions};
    letter-spacing: 0.38px;
  }
  }
`;

const ContentStyle = styled(Content)`
  ${StyledContent}
`;

const ContentWrapper = props => {
  return <ContentStyle {...props}></ContentStyle>;
};

export default ContentWrapper;
