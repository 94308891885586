import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Select from "../../../../components/input/Select";
import Option from "../../../../components/input/SelectOptions";
import BankName from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/BankName";
import JobName from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/JobName";
import OptionsField from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/OptionsField";
import MonthPicker from "../../../../appcomponents/EscrowTrakker/detailbanks/FiltersTransaction";
import DatePicker from "../../../../components/datepickers/DatePicker";
//import Select from "../../../../components/input/Select";
//import Option from "../../../../components/input/SelectOptions";
import useProfitAndLossForm from "../../forms/reports/useProfitAndLossForm";
import { connect } from "react-redux";
import RootForm from "../../../../components/form/index";
import Button from "../../../../components/button";
import { changePassword } from "../../../../configurations/Schemas/index";
import { Row, Col } from "antd";
import { changePasswordRequest } from "../../../../actions/App";
import { profitAndLossRequest } from "../../../../actions/Reports";
import { closeAllModal } from "../../../../actions/Modal";

import moment from "moment";

const ProfitAndLoss = props => {
  const [yearPickerOpen, setYearPickerOpen] = useState(false);
  const [bankValue, setBankValue] = useState(props.initialValues ? props.initialValues.bank_name : undefined);
  const [jobValue, setJobValue] = useState(props.initialValues ? props.initialValues.property_name : undefined);

  const mainForm = useProfitAndLossForm({
    initialValues: props.initialValues ? {
      ...props.initialValues
    } : {
      bank_account_id: "",
      tenant_id: "",
      property_id: "",
      report_type: "detail",
      date_type: "monthly",
      month: moment().month() + 1,
      year: moment().year(),
      start_date: "",
      end_date: ""
    },

    onSubmit: values => props.profitAndLossRequest({ ...values, format: props.format }, report => {
      props.closeAllModal();
      props.history.push({ pathname: "/reports/preview-profit-and-loss", state: { 
        referrer: "/reports",
        referrerState: {
          ...props.location.state,
          profitAndLossValues: {
            formValues: values,
            format: props.format
          }
        }, 
        report, 
        format: props.format 
        } 
      })
    })
  })
  
  console.log("FORMDATEVAL", (((new Date().getFullYear())+50).toString()));

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      className="textCenter"
      gutter={[0, 30]}
    >
      <Col span={24} style={{ padding: 0 }}>
        <h4 className="mr-b mr-t-md" style={{ marginBottom: "2.5rem" }}>
          Profit and Loss
        </h4>
      </Col>

      <Col xs={24} sm={20} md={22} lg={16}>
        <Row type="flex" justify="center">
          <Col span={24} style={{ textAlign: "left" }}>
            <BankName
            className="inputField"
            name="bank_account_id"
            bankValue={bankValue}
            withBankValue
            placeholder={mainForm.values.report_type === "detail" ? "Select Bank Account Name" : "All Bank Accounts"}
            withoutAddButton
            hideDeleteBtn
            isReport={true}
            withAllOption={mainForm.values.report_type !== "detail"}
            onChange={(id, name) => {
              mainForm.customHandleChange(id, "bank_account_id");
              mainForm.customHandleChange(name, "bank_name");
              setBankValue(name || id);
              mainForm.setFieldValue("tenant_name", "All Tenants");
              mainForm.setFieldValue("property_name", "All Properties");
              setJobValue("All Properties");
            }}
            onBlur={e => mainForm.customHandleBlur(e, "bank_account_id")}
            />
            {mainForm.touched["bank_account_id"] && mainForm.errors["bank_account_id"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["bank_account_id"] && mainForm.errors["bank_account_id"]}
            </div> : null}
          </Col>

          <Col span={24} style={{ textAlign: "left" }}>
            <OptionsField
              className="inputField"
              //title="Lawyer Name"
              //addBtnText="Add Lawyer"
              name="lawyer_id"
              //link="/add-contact"
              contactType="tenant"
              placeholder="All Tenants"
              id={mainForm.values.bank_account_id}
              isBankACC={!!mainForm.values.bank_account_id}
              isCharts={true}            
              isEmail={!!mainForm.values.bank_account_id}
              withoutAddButton
              tenantValue={mainForm.values.tenant_name}
              withClearOption
              initialValue={mainForm.values.lawyer_name}
              onChange={(id, name) => {
                mainForm.customHandleChange(id, "tenant_id");
                mainForm.customHandleChange(name, "tenant_name");
                mainForm.setFieldValue("date_type", "alldata");
                mainForm.setFieldValue("year", "") ;
                mainForm.setFieldValue("start_date", "");
                mainForm.setFieldValue("start_day", new Date().getDate().toString());
                mainForm.setFieldValue("start_month", ((new Date().getMonth()+1).toString()));
                mainForm.setFieldValue("start_year", (((new Date().getFullYear())-50).toString()));
                mainForm.setFieldValue("end_date", "");
                mainForm.setFieldValue("end_day", new Date().getDate().toString());
                mainForm.setFieldValue("end_month", ((new Date().getMonth()+1).toString()));
                mainForm.setFieldValue("end_year", (((new Date().getFullYear())+50).toString()));  
              }}
              onBlur={e => mainForm.customHandleBlur(e, "lawyer_id")}
            />
            {mainForm.touched["lawyer_id"] && mainForm.errors["lawyer_id"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["lawyer_id"] && mainForm.errors["lawyer_id"]}
            </div> : null}
          </Col>

          <Col span={24} style={{ textAlign: "left" }}>
            <JobName
            className="inputField"
            name="job_id"
            jobValue={jobValue}
            withJobValue
            placeholder="All Properties"
            withoutAddButton
            hideDeleteBtn
            withClearOption
            id={mainForm.values.bank_account_id}
            isBankACC={!!mainForm.values.bank_account_id}
            isCharts={true}
            //withAllOption={mainForm.values.report_type !== "detail"}
            onChange={(id, name) => {
              mainForm.customHandleChange(id, "property_id");
              mainForm.customHandleChange(name, "property_name");
              setJobValue(name || id);
            }}
            onBlur={e => mainForm.customHandleBlur(e, "job_id")}
            />
            {mainForm.touched["job_id"] && mainForm.errors["job_id"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["job_id"] && mainForm.errors["job_id"]}
            </div> : null}
          </Col>

          <Col span={24} style={{ textAlign: "left" }}>
            <Select
            className="inputField"
            name="report_type"
            placeholder="Report Type"
            value={mainForm.values.report_type}
            onChange={e => {
              mainForm.customHandleChange(e, "report_type");
              if(e === "detail" && mainForm.values.bank_account_id === "") {
                setBankValue(undefined);
              }
            }}
            onBlur={e => mainForm.customHandleBlur(e, "report_type")}
            style={{ width: "100%" }}
            >
              <Option value="detail">Detailed</Option>
              <Option value="summery">Summary</Option>
            </Select>
            {mainForm.touched["report_type"] && mainForm.errors["report_type"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["report_type"] && mainForm.errors["report_type"]}
            </div> : null}
          </Col>

          <Col span={24} style={{ textAlign: "left" }}>
            <Select
            className="inputField"
            name="date_type"
            placeholder="Date Type"
            value={mainForm.values.date_type}
            onChange={e => {
              mainForm.onChangeDateType(e, "date_type");
              e==="alldata" ? mainForm.setFieldValue("year", "") : console.log("yes it will work");
              e==="alldata" ? mainForm.setFieldValue("start_date", "") : console.log("yes date1");
              e==="alldata" ? mainForm.setFieldValue("start_day", new Date().getDate().toString()) : console.log("yes date1");
              e==="alldata" ? mainForm.setFieldValue("start_month", ((new Date().getMonth()+1).toString())) : console.log("yes date1");
              e==="alldata" ? mainForm.setFieldValue("start_year", (((new Date().getFullYear())-50).toString())) : console.log("yes date1");
              e==="alldata" ? mainForm.setFieldValue("end_date", "") : console.log("yes date2");
              e==="alldata" ? mainForm.setFieldValue("end_day", new Date().getDate().toString()) : console.log("yes date2");
              e==="alldata" ? mainForm.setFieldValue("end_month", ((new Date().getMonth()+1).toString())) : console.log("yes date2");
              e==="alldata" ? mainForm.setFieldValue("end_year", (((new Date().getFullYear())+50).toString())) : console.log("yes date2");
            }}
            onBlur={e => mainForm.customHandleBlur(e, "date_type")}
            style={{ width: "100%" }}
            >
              <Option value="monthly">Monthly</Option>
              <Option value="yearly">Yearly</Option>
              <Option value="alldata">All Data</Option>
            </Select>
            {mainForm.touched["date_type"] && mainForm.errors["date_type"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["date_type"] && mainForm.errors["date_type"]}
            </div> : null}
          </Col>

          {mainForm.values.date_type === "monthly" && 
          <Col span={24} style={{ textAlign: "left" }}>
            <MonthPicker
            singleMonth
            outlined
            className="inputField"
            name="month"
            value={mainForm.values.month}
            placeholder="Month"
            onChange={e => mainForm.customHandleChange(e, "month")}
            onBlur={e => mainForm.customHandleBlur(e, "month")}
            />
            {mainForm.touched["month"] && mainForm.errors["month"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["month"] && mainForm.errors["month"]}
            </div> : null}
          </Col>
          }

          {(mainForm.values.date_type === "monthly" || mainForm.values.date_type === "yearly") && 
          <Col span={24} style={{ textAlign: "left" }}>
            <DatePicker
            allowClear={false}
            open={yearPickerOpen}
            onOpenChange={open => setYearPickerOpen(open)}
            modalField
            className="inputField"
            name="year"
            value={mainForm.values.year ? moment().year(mainForm.values.year) : undefined}
            //onChange={e => mainForm.customHandleChange(e, "year")}
            onFocus={() => setYearPickerOpen(true)}
            onBlur={e => {
              mainForm.customHandleBlur(e, "year");
              //setYearPickerOpen(false);
            }}
            style={{ width: "100%" }}
            //picker="year"
            format="YYYY"
            mode="year"
            placeholder="Year"
            onPanelChange={e => {
              mainForm.customHandleChange(e.year(), "year")
              setYearPickerOpen(false);
            }}
            />
            {mainForm.touched["year"] && mainForm.errors["year"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["year"] && mainForm.errors["year"]}
            </div> : null}
          </Col>
            }
          <Col span={16} className="mr-t-md">
            <Button loading={props.reports.profitAndLossLoading} block onClick={mainForm.handleSubmit}>Preview Report</Button>
          </Col>

          <Col span={16} className="mr-t">
            <Button block outlined onClick={props.closeAllModal}>Cancel</Button>
          </Col>
          
        </Row>
      </Col>
    </Row>
  );
};

export default withRouter(connect(state => ({ reports: state.Reports }), { profitAndLossRequest, closeAllModal })(ProfitAndLoss));
