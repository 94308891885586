import React from "react";
import { Row, Col, Divider } from "antd";
import ContactForm from "../../appcomponents/shares/landing/ContactForm";
import { AiOutlineDelete } from "react-icons/ai";
import HeaderWrapper from "../../components/header/index";
import TransferTotalCard from "../../appcomponents/EscrowTrakker/dashboard/TransferTotalCard";
import BankFieldName from "../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/BankName";
import Field from "../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/index";
import Button from "../../components/button/index";
import { AccountingFormat } from "../../configurations/Config";
import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { getReconciledRequest } from "../../actions/transaction/Transaction";
import TableWrapper from "../../components/react-table";
import { RECONCILED_ERROR } from "../../configurations/Types";
import { openModal } from "../../actions/Modal";
import Info from "../../components/message";
import LinkTrasaction from "../../appcomponents/EscrowTrakker/detailbanks/LinkButtons";
import styled from "styled-components";
import {
  deleteTransactionRequest,
  ClearedTransactionsRequest,
} from "../../actions/SearchTransactions";
import TagWrapper from "../../components/tag";
import Switch from "../../components/switch";
import { getUserSettingRequest } from "../../actions/Settings";
import { useHistory, withRouter } from "react-router-dom";
import {
  FiEdit,
  FiMoreHorizontal,
  FiAlertTriangle,
  FiInfo,
  FiPlus,
} from "react-icons/fi";
import PopconfirmWrapper from "../../components/pop-confirm";
import PopOver from "../../components/popover";
import BankAccountSummary from "./BankAccountSummary";
import { InputNumber } from "antd";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";
import info from "../../components/message";

const ButtonIcon = styled.div`
  /* width: 100%; */
  & .ant-btn {
    /* width: 100%; */
    border: none;
    box-shadow: none;
    color: ${(props) => props.theme[props.theme.mode].textColor.primary};
    font-size: ${(props) =>
    props.theme[props.theme.mode].textSizes.relative.links};
    display: flex;
    align-items: center;
  }
  & svg {
    color: ${(props) => props.theme[props.theme.mode].common.white};
    background: ${(props) => props.theme[props.theme.mode].primary.main};
    padding: 0.4rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    margin: 0 0.4rem;
  }
`;
// const SignupSchema = Yup.object().shape({
//   firstName: Yup.string()
//     .min(2, 'Too Short!')
//     .max(50, 'Too Long!')
//     .required('Required'),
//   lastName: Yup.string()
//     .min(2, 'Too Short!')
//     .max(50, 'Too Long!')
//     .required('Required'),
//   email: Yup.string().email('Invalid email').required('Required'),
// });

var TransactionIDsStorage;
const Reconcile = (props) => {
  console.log("pasdpasp", props.transactionDetails.bankReconciledTransactionCountt)
  const history = useHistory();
  const [transactionIDs, setTransactionIDs] = useState([]);
  let initialState = props?.location?.state;
  let referrerState = props?.location?.state?.referrerState;
  const [clearedBalance, setClearedBalance] = useState();
  const [amount, setAmount] = useState(
    referrerState
      ? referrerState?.bankbalance
      : initialState
        ? initialState?.bankbalance
        : ""
  );
  const [bankId, setBankId] = useState(
    referrerState
      ? referrerState?.bankId
      : initialState
        ? initialState?.bankId
        : ""
  );
  const [bankName, setBankName] = useState(
    referrerState
      ? referrerState?.bankName
      : initialState
        ? initialState?.bankName
        : ""
  );
  const [differenceBalance, setDifferenceBalance] = useState();
  const [transactions, setTransactions] = useState([]);
  const [enableValue, setEnableValue] = useState(false);
  const [options, setOptions] = useState([]);
  const [isCleared, setIsCleared] = useState(JSON.parse(localStorage.getItem('isCleared')) ? JSON.parse(localStorage.getItem('isCleared')) : {});
  const [filters, setFilters] = useState({
    bankId,
    amount,
  });
  const [ls, setLs] = useState();
  const [tableChanged, setTableChanged] = useState(false);
  const [isTrue, setIsTrue] = useState(false);

  let map = {
    DEPOSIT: "primary",
    WITHDRAWAL: "secondary",
    TRANSFER: "fourth",
    //Banned: "fourth
  };

  const [state, setState] = useState({
    apiCall: (...params) => dispatch(getReconciledRequest(...params)),
  });

  const dispatch = useDispatch();
  useEffect(() => {
    console.log(" ", filters);
    if (filters.bankId && filters.amount>=0) {
      // let params = {
      //   bankId: bankId,
      //   amount: amount
      // };
      state.apiCall(
        filters,
        (data, error) => {
          if (error) {
            console.log("This is error");
          } else {
            setOptions(data);
            console.log("this is data is data reconcile: ", data);
            setOptions(data);
            setIsTrue(true);
            if (initialState?.clearedBalance) {
              setClearedBalance(initialState?.clearedBalance);
            } else if (referrerState?.clearedBalance) {
              setClearedBalance(referrerState?.clearedBalance);
            }
            else {
              setClearedBalance(data.cleared_balance);
            }
          }
        },
        true
      );
    }
  
  }, [filters]);
  // const getTransaction = (id) => {
  //   console.log("ID IS : ", id);
  //   dispatch(getReconciledRequest(id, amount, (data, error) => {
  //     if(error){
  //       console.log("This is error")
  //     }
  //     else {
  //       setOptions(data);
  //       console.log("this is data");
  //     }
  //   }));
  // }
  useEffect(() => {
    props.transactionDetails.bankReconciledTransactions = [];
    props.transactionDetails.differenceReconciledBal = "";
    props.transactionDetails.clearedReconciledBal = "";
    props.getUserSettingRequest();
  }, []);
  // useEffect(() => {
  //   props.transactionDetails.bankReconciledTransactions=[];
  // }, [initialState?.bankbalance])
  console.log("cleared is : ", props.location.state);
  useEffect(() => {
    setTransactions(props?.transactionDetails?.bankReconciledTransactions);
    console.log("Transaction IDS: ", transactionIDs);
  }, [props?.transactionDetails?.bankReconciledTransactions]);
  // useEffect(() => {
  //   //setDifferenceBalance(props?.transactionDetails?.differenceReconciledBal);
  //   setClearedBalance(props?.transactionDetails?.clearedReconciledBal);
  // }, [props?.transactionDetails?.clearedReconciledBal]);
  // useEffect(() => {
  //   // setDifferenceBalance(initialState?.differenceBalance);
  //   console.log("this is cleared balance: ", initialState?.clearedBalance, referrerState?.clearedBalance);
  //   if (initialState?.clearedBalance) {
  //     setClearedBalance(initialState?.clearedBalance);
  //   } else {
  //     setClearedBalance(referrerState?.clearedBalance);
  //   }
  //   setClearedBalance(initialState?.clearedBalance);
  // }, [initialState?.clearedBalance, referrerState?.clearedBalance]);
  // useEffect(() => {
  //   setClearedBalance(referrerState?.clearedBalance);
  // }, [referrerState?.clearedBalance])
  const setCalc = (r, flag) => {
    console.log("VALUE IS ", r);
    setEnableValue(true);
    setIsCleared({
      ...isCleared,
      [r.transaction_source_id]: [
        !isCleared[r.transaction_source_id]?.[0],
        r.amount,
      ],
    });
    console.log(isCleared, "ddddddd");
    if (!flag === true) {
      if (r.transaction_type === "deposit") {
        setClearedBalance(Number(clearedBalance) + Number(r.amount));
        transactionIDs.push(r.transaction_source_id);
        setTransactionIDs(transactionIDs);
      } else {
        setClearedBalance(Number(clearedBalance) - Number(r.amount));
        transactionIDs.push(r.transaction_source_id);
        setTransactionIDs(transactionIDs);
      }
      setEnableValue(true);
    } else {
      if (r.transaction_type === "deposit") {
        setClearedBalance(Number(clearedBalance) - Number(r.amount));
      } else {
        setClearedBalance(Number(clearedBalance) + Number(r.amount));
      }
      let a = transactionIDs.indexOf(r.transaction_source_id);
      transactionIDs.splice(a, 1);
      console.log("id num", a);
      setTransactionIDs(transactionIDs);

      setEnableValue(true);

      // setDifferenceBalance(parseInt(filters.amount) + parseInt(clearedBalance));
    }
  };
  useEffect(() => {
    setDifferenceBalance(Number(amount) - Number(clearedBalance).toFixed(2));
  }, [clearedBalance, amount]);
  useEffect(() => {
    if (Object.keys(isCleared).length !== 0) {
      localStorage.setItem("isCleared", JSON.stringify({ ...isCleared }));
    }
  }, [isCleared]);
  console.log("Transaction IDS: new", localStorage.getItem("isCleared"));

  console.log("CLEARED BALANCE: ", isCleared);
  console.log("props?.location?.state ", props?.location?.state);
  //console.log("lenghttttt", a);
  let ab = JSON.parse(localStorage.getItem("isCleared")) ? JSON.parse(localStorage.getItem("isCleared")) : "";
  console.log('abbababab==>',{ enableValue, transactionIDs, differenceBalance })
  return (
    <Row
      type="flex"
      justify="center"
      align="stretch"
      className="textCenter"
      gutter={[40, 30]}
    >
      <Col span={24}>
        <HeaderWrapper
          externalscreen={1}
          rightAlign={[
            <Button
              disabled={differenceBalance == 0 && transactionIDs.length ? false : true }
              bankDetail={state}
              style={{ padding: "0.596vw 0.8vw" }}
              onClick={() => {
                console.log("TRANSACATION IDS: ", transactionIDs);
                props.ClearedTransactionsRequest(transactionIDs, "true");
                props.history.push({
                  pathname: `/`,
                  referrer: props.match.url,
                  referrerState: {
                    bankbalance: amount,
                    bankId: filters.bankId,
                    bankName: bankName,
                    differenceBalance: differenceBalance,
                    clearedBalance: clearedBalance,
                  },
                });
              }}
            >
              Reconcile
            </Button>,
          ]}
        />
      </Col>

      <Col xs={24} md={12} lg={8}>
        <TransferTotalCard
          heading="Difference"
          // label="bank name"
          amount={
            isTrue
              ? AccountingFormat(
                differenceBalance ? differenceBalance : 0,
                props.settings.currency
              )
              : "0.00"
          }
        />
      </Col>
      <Col xs={24} md={12} lg={8}>
        <TransferTotalCard
          heading="Cleared Total"
          amount={
            clearedBalance
              ? AccountingFormat(clearedBalance, props.settings.currency)
              : "0.00"
          }
        />
      </Col>
      <Row
        type="flex"
        justify="space-between"
        align="stretch"
        className="textCenter"
        gutter={[40, 30]}
      >
        <Col xs={24} md={12} lg={7}>
          <ButtonIcon>
            <Button
              outlined
              onClick={() =>
                history.push({
                  pathname: "/add-transaction",
                  state: {
                    bank: { bankId: filters.bankId, bankName: bankName },
                    isRedirect: true,
                    referrer: props.match.url,
                    referrerState: {
                      bankbalance: amount,
                      bankId: filters.bankId,
                      bankName: bankName,
                      differenceBalance: differenceBalance,
                      clearedBalance: clearedBalance,
                    },
                  },
                })
              }
            >
              <FiPlus className="iconBtn" /> Add Transaction
            </Button>
          </ButtonIcon>
        </Col>
        <Col xs={12} md={12} lg={8}>
          <h4 style={{ color: "#1E1E22", marginBottom: "11px" }}>
            Enter Bank Balance
          </h4>
          <input
            type="number"
            //key={formId}
            style={{
              color: "#1E1E22",
              backgroundColor: "#ffffff",
              border: "1px solid #58ba2766",
              borderRadius: "5px",
              height: "50%",
            }}
            value={amount}
            //placeholder="Check Number"
            name="checkNumber"
            //customInput={initialState?.bankbalance && initialState?.bankbalance}
            onChange={(e) => {
              console.log("E IS: ", e.target.value);
              setAmount(e.target.value);
            }}
          //nBlur={e => mainForm.customHandleBlur(e, "checkNumber")}
          />
        </Col>
        <Col xs={12} md={12} lg={9} onClick={() => {
          if (!amount) {
            Info("error","Please Enter Bank balance") 
        }  }}>
          <BankFieldName
            value={bankName}
            title="Bank Account Name"
            placeholder="Select Bank Account"
            addBtnText="Add Bank Account"
            //initialValue={bank ? bank.bankName : null}
            // value={mainForm.values.bankName}
            disabled={!amount}
            name="bankName"
            link="/add-bank"
            withoutAddButton
            onChange={(id, name) => {
              //setFilters({ ...filters, bankId: id });
              setBankName(name);
              setFilters({ bankId: id, amount: amount });
              //getTransaction(id);
            }}
          //onBlur={e => mainForm.customHandleBlur(e, "bankName")}
          />
        </Col>
      </Row>
      {/* <Col span={24}>
          <Row type="flex" justify="center" align="middle">
            <Col xl={8} lg={10} md={14} sm={18} xs={20}>
              <Button loading={props.loading} block className="">
                Submit
              </Button>
            </Col>
          </Row>
        </Col> */}
      <Col span={24}>
        <TableWrapper
          tableData={props.transactionDetails.bankReconciledTransactions}
          style={{ height: "35vh", overFlow: "auto" }}
          // getData={state.apiCall}
          noCallOnMount
          getTrProps={(state, rowInfo, column) => ({
            onClick: () =>
              props.history.push({
                pathname: `/view-transaction/${rowInfo.original.transaction_source_id}`,
                state: {
                  referrer: props.match.url,
                  referrerState: {
                    bankbalance: amount,
                    bankId: filters.bankId,
                    bankName: bankName,
                    differenceBalance: differenceBalance,
                    clearedBalance: clearedBalance,
                  },
                },
              }),
            style: { cursor: "pointer" },
            //className: rowInfo && rowInfo.original.cleared === "true" ? "cleared" : ""
          })}
          getTdProps={(state, rowInfo, column, c, d) => {
            return column.className === "clickable"
              ? {
                onClick: (e) => e.stopPropagation(),
                style: { cursor: "default" },
              }
              : {};
          }}
          filters={filters}
          pageSize={10}
          dataCount={props.transactionDetails.bankReconciledTransactionCountt}
          // loading={props.transactionDetails.tableLoading}
          //enableReInit={true}
          tableChanged={tableChanged}
          content={[
            {
              name: "Account Name",
              id: "account_name",
              style: { color: "red" },
              render: (r) => (
                <span
                  //  style={{
                  //     fontStyle:
                  //       r.original.is_split === "true" ? "italic" : "",
                  //   }}
                  className={
                    r.original.is_split === true
                      ? null
                      : r.original.transaction_type === "deposit" || r.original.transaction_type === "interest deposit"
                        ? "appColor"
                        : "secondaryAppColor"
                  }
                >
                  {r.original.is_split === true ? (
                    <TagWrapper
                      style={{
                        width: "100px",
                        backgroundColor: "orange"
                        //fontStyle:"italic"
                      }}
                      className="small"
                    // type={
                    // map[
                    //   // r.original.is_recurring === "true"
                    //   //   ? "RECURRING"
                    //   //   :
                    //   //r.original.transaction_type.toUpperCase()
                    // ]
                    //}
                    >
                      {
                        // r.original.is_recurring === "true"
                        //   ? "Recurring"
                        //   :
                        "Splits"
                      }
                    </TagWrapper>
                  ) : r.original.account_name !== "" ? (
                    r.original.account_name
                  ) : (
                    "-"
                  )}
                </span>
              ),
            },
            {
              name: "Tenant Name",
              id: "tenant_name",
            },
            {
              name: "Property Name",
              id: "property_name",
            },
            {
              name: "Date",
              id: "date_of_transaction",
              render: (r) => (
                console.log("RENDER IS: ", r.original.cleared),
                (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {r.original.date_of_transaction
                      ? moment(r.original.date_of_transaction).format(
                        "MM/DD/YYYY"
                      )
                      : "-"}
                    &nbsp;
                    <FiInfo
                      style={{ pointer: "cursor" }}
                      onClick={(e) => {
                        console.log("Modal Attaced");
                        e.stopPropagation();
                        props.openModal(
                          null,
                          <BankAccountSummary banAccount={r.original} />,
                          null,
                          null,
                          {
                            footer: null,
                          }
                        );
                      }}
                    />
                  </span>
                )
              ),
            },

            {
              name: "Amount",
              id: "amount",
              render: (r) => (
                <span
                  // style={{
                  //   fontStyle:
                  //     r.original.is_split === "true" ? "italic" : "",
                  // }}
                  className={
                    // r.original.is_split === "true"
                    //   ? null
                    //   :
                    r.original.transaction_type === "deposit" || r.original.transaction_type === "interest deposit"
                      ? "appColor"
                      : "secondaryAppColor"
                  }
                >
                  {r.original.is_split === "true"
                    ? AccountingFormat(
                      r.original.amount,
                      r.original.currency
                    )
                    : AccountingFormat(
                      r.original.amount,
                      r.original.currency
                    )}
                </span>
              ),
            },
            {
              name: "Cleared",
              id: "cleared",
              className: "clickable",
              render: (r) => (
                // <div style={{ width: "100%" }} onClick={e => e.stopPropagation()}>
                <React.Fragment>
                  {r.original.transaction_type != "transfer" && (
                    <div
                      style={{
                        //padding: "8px",
                        //width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    //onClick={e => e.stopPropagation()}
                    >
                      <Switch
                        checked={

                          ab[r?.original.transaction_source_id]?.[0]
                            ? true
                            : false
                        }
                        onChange={() => {
                          setCalc(
                            r?.original,
                            ab[r?.original.transaction_source_id]?.[0]
                          );
                        }}
                      />
                    </div>
                  )}
                </React.Fragment>
                // </div>
              ),
            },
            {
              name: "Transaction",
              id: "transaction_type",
              large: true,
              render: (r) => (
                <React.Fragment>
                  <TagWrapper
                    style={{ width: "100%", textTransform: "capitalize", backgroundColor: r.original.transaction_type === "deposit" || r.original.transaction_type === "interest deposit" ? "#58ba27" : "#ff4a4d" }}
                    className="small"
                    type={
                      map[
                      // r.original.is_recurring === "true"
                      //   ? "RECURRING"
                      //   :
                      r.original.transaction_type.toUpperCase()
                      ]
                    }
                  >
                    {
                      // r.original.is_recurring === "true"
                      //   ? "Recurring"
                      //   :
                      r.original.transaction_type
                        ? r.original.transaction_type
                        : "-"
                    }
                  </TagWrapper>
                  <span style={{ marginLeft: "1rem" }}>
                    <PopOver
                      onClick={(e) => e.stopPropagation()}
                      // placement="left"
                      style={{ cursor: "pointer" }}
                      content={
                        <div onClick={(e) => e.stopPropagation()}>
                          <div
                            style={{ padding: "8px" }}
                            style={{
                              padding: "8px",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={() =>
                              props.history.push({
                                pathname: `/view-transaction/${r.original.transaction_source_id}`,
                                state: {
                                  referrer: props.match.url,
                                  referrerState: {
                                    bankbalance: amount,
                                    bankId: filters.bankId,
                                    bankName: bankName,
                                    differenceBalance: differenceBalance,
                                    clearedBalance: clearedBalance,
                                  },
                                },
                              })
                            }
                          >
                            <FiEdit onClick="" style={{ marginRight: 5 }} />
                            <span>Edit</span>
                          </div>

                          <Divider style={{ margin: 0 }} />

                          {/* poprConfirm */}

                          <PopconfirmWrapper
                            onClick={(e) => e.stopPropagation()}
                            title={
                              <h4>
                                Are you sure you want to delete this
                                transaction?
                              </h4>
                            }
                            icon={
                              <FiAlertTriangle
                                style={{
                                  position: "absolute",
                                  color: "#f44336",
                                  left: 0,
                                  top: 0,
                                  marginTop: 6,
                                }}
                              />
                            }
                            cancelText="No"
                            okText="Yes"
                            onConfirm={() =>
                              props.deleteTransactionRequest(
                                r.original.transaction_source_id,
                                () => setTableChanged(!tableChanged)
                              )
                            }
                          >
                            {/* <ButtonWrapper className="deleteBtn"> */}
                            <div
                              onClick={(e) => e.stopPropagation()}
                              style={{
                                padding: "8px",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <AiOutlineDelete
                                className="deleteIcon"
                                style={{ color: "#FF494C", marginRight: 5 }}
                              />
                              <span>Delete</span>
                            </div>
                            {/* </ButtonWrapper> */}
                          </PopconfirmWrapper>

                          {/* <Divider style={{ margin: 0 }} />
                            <PopconfirmWrapper
                              title={
                                <h4>
                                  Are you sure you want to
                                  {r.original.cleared === "true"
                                    ? ` unclear `
                                    : ` clear`}{" "}
                                  this transaction?
                                </h4>
                              }
                              icon={
                                <FiAlertTriangle
                                  style={{
                                    position: "absolute",
                                    color: "#f44336",
                                    left: 0,
                                    top: 0,
                                    marginTop: 6
                                  }}
                                />
                              }
                              cancelText="No"
                              okText="Yes"
                              onConfirm={() =>
                                props.ClearedTransactionsRequest(
                                  r.original.transaction_source_id,
                                  r.original.cleared === "true"
                                    ? "false"
                                    : "true",
                                  () => setTableChanged(!tableChanged)
                                )
                              }
                            >
                              <div
                                style={{
                                  padding: "8px",
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                <Switch
                                  checked={
                                    r.original.cleared === "true" ? true : false
                                  }
                                  size="small"
                                  style={{ marginRight: 5 }}
                                />{" "}
                                <span>Cleared</span>
                              </div>
                            </PopconfirmWrapper> */}
                        </div>
                      }
                    >
                      <FiMoreHorizontal style={{ color: "#154D94" }} />
                    </PopOver>
                  </span>
                </React.Fragment>
              ),
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default withRouter(
  connect(
    (storeState) => ({
      transactionDetails: storeState.transaction,
      settings: storeState.settings.settings,
    }),
    {
      deleteTransactionRequest,
      ClearedTransactionsRequest,
      getUserSettingRequest,
      openModal,
    }
  )(Reconcile)
);
