import React, { Fragment } from "react";
import { connect } from "react-redux";
import Card from "../../../../components/card/index";
import Tag from "../../../../components/tag";
import Image from "../../../../components/image";
import PopconfirmWrapper from "../../../../components/pop-confirm";
import check from "../../../../assets/icons/check.svg";
import { Row, Col } from "antd";
import { MdDelete } from "react-icons/md";
import { FiAlertTriangle } from "react-icons/fi";
import { cancelSubscriptionRequest } from "../../../../actions/Subscription";

const planFeatures = {
  "Free Trial": [
    "Upto 10 Tenants",//may have to change to 10 tenants in near future
    "No other restrictions",
    "Multi-Platform",
    "Multi-Device Login",
    //"No auto-renew"
  ],
  standard: [
    "2 Bank Accounts",
    "15 Customer Accounts",
    "Multi-Platform",
    "Multi-Device Login",
    "15 Custom Accounts"
  ],
  professional: [
    "20 Bank Accounts",
    "100 Customer Accounts",
    "Multi-Platform",
    "Multi-Device Login",
    "50 Custom Accounts"
  ],
  enterprise: [
    "200 Bank Accounts",
    "1000 Customer Accounts",
    "Multi-Platform",
    "Multi-Device Login",
    "100 Custom Accounts"
  ],
  // "professional annum": [
  //   "20 bank accounts",
  //   "No other restrictions",
  //   "Multi-Platform",
  //   "Multi-Device Login",
  //   //"No auto-renew"
  // ],
  // "enterprise annum": [
  //   "200 bank accounts",
  //   "No other restrictions",
  //   "Multi-Platform",
  //   "Multi-Device Login",
  //   //"No auto-renew"
  // ],
  "Landlord Level One": [
      "Upto 30 tenants",
      "No other restrictions",
      "Multi-Platform",
      "Multi-Device Login",
      //"No auto-renew"
    ],
  "Landlord Level Two": [
      "Upto 100 tenants",
      "No other restrictions",
      "Multi-Platform",
      "Multi-Device Login",
      //"No auto-renew"
    ],
  "Landlord Professional": [
      "Upto 500 tenants",
      "No other restrictions",
      "Multi-Platform",
      "Multi-Device Login",
      //"No auto-renew"
    ],//have to change for annum
  "Landlord Enterprise": [
      "Upto 25,000 Tenants",
      "No other restrictions",
      "Multi-Platform",
      "Multi-Device Login",
      //"No auto-renew"
    ],
};

const FreeTrial = (props) => {
  console.log("PLAN FEATURES", props.plan);
  return (
    <Card smallPadding>
      <Row type="flex" gutter={[0, 20]}>
        <Col span={24}>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            gutter={[0, 5]}
          >
            <Col>
              <h3 className="no-mr">
                {props.plan
                  ? props.plan === "trial"
                    ? "Free Trial"
                    : props.plan.split(" ").map(word => word[0].toUpperCase() +
                    word.substring(1)).join(" ")
                  : ""}
              </h3>
            </Col>
            <Col>
              <Tag style={{ width: "8rem", backgroundColor: props.status === "cancelled" ? "#ff4a4d" : "#c6954e"  }}>{props.status === "cancelled"
                        ? "Cancelled"
                        : "Active"}</Tag>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          {planFeatures[props?.plan]?.map((item) => (
            <Fragment>
              <Row
                type="flex"
                justify="start"
                style={{ flexWrap: "nowrap" }}
                gutter={[5, 30]}
              >
                <Col>
                  <div
                    style={{
                      backgroundColor: "#c6954e",
                      height: "1.2rem",
                      width: "1.2rem",
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Image
                      style={{ height: "0.7rem", width: "0.7rem" }}
                      src={check}
                      alt="Check"
                    />
                  </div>
                </Col>
                <Col style={{ flexGrow: 1 }}>
                  <p
                    style={{
                      color: "black",
                      paddingLeft: "0.45rem",
                      fontWeight: 500,
                    }}
                  >
                    {item}
                  </p>
                </Col>
              </Row>
            </Fragment>
          ))}
        </Col>

        <Col span={24}>
          <Row type="flex" justify="space-between" align="middle">
            {/* <Col> */}
            {/* <Row type="flex" justify="start" align="middle"> */}
            <Col>
              <h4
                className="no-mr secondaryAppColor"
                // style={{ paddingTop: "1rem" }}
              >
                {parseInt(props.remainingDays) < 0
                  ? "Expired"
                  : `Expires In: ${props.remainingDays} Days`}
              </h4>
            </Col>
            {/* </Row> */}

            {/* <Row
            type="flex"
            justify="end"
            align="middle"
            style={{ marginTop: "-1rem" }}
          > */}
            {!(parseInt(props.remainingDays) < 0) && (props.plan!=="Free Trial") &&(
              <Col>
                <PopconfirmWrapper
                  disabled={props.status === "cancelled"}
                  title={
                    <h4>Are you sure you want to cancel your subscription?</h4>
                  }
                  icon={
                    <FiAlertTriangle
                      style={{
                        position: "absolute",
                        color: "#f44336",
                        left: 0,
                        top: 0,
                        marginTop: 6,
                      }}
                    />
                  }
                  cancelText="No"
                  okText="Yes"
                  onConfirm={function () {
                    //deleteBank(props.val.bank_account_id);
                    props.cancelSubscriptionRequest();
                  }}
                >
                  <div
                    className="secondaryAppBackColor"
                    style={{
                      fontSize: "1rem",
                      height: " 1.5rem",
                      width: "1.5rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "3px",
                      cursor:
                        props.status === "cancelled"
                          ? "not-allowed"
                          : "pointer",
                      opacity: props.status === "cancelled" ? 0.5 : 1,
                    }}
                    title={
                      props.status === "cancelled"
                        ? "Subscription already cancelled"
                        : undefined
                    }
                  >
                    {" "}
                    <MdDelete style={{ color: "#FFFF" }} />{" "}
                  </div>
                </PopconfirmWrapper>
              </Col>
            )}
            {/* </Row>
        </Col> */}
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default connect(null, { cancelSubscriptionRequest })(FreeTrial);
