import React, { useState, useEffect, useRef } from "react";
import { debounce } from "debounce";
import { connect,useDispatch } from "react-redux";
import { Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import moment from "moment";
import useTransferForm from "../../../appcomponents/EscrowTrakker/forms/useTransferForm";
import BankFieldName from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/BankName";
import OptionsField from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/OptionsField";
import AccountNameField from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/AccountName";
import Field from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/index";
import DatePicker from "../../../components/datepickers/DatePicker";
import JobNameField from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/JobName";
import TextArea from "../../../components/input/TextArea";
import Button from "../../../components/button/index";
import Split from "../../../appcomponents/EscrowTrakker/formcontrols/splits";
import { getAccountsRequest } from "../../../../src/actions/FieldOptions";
import { getSingleBankRequest } from "../../../actions/BankAccount";
// import { loginSchema } from "../../../configurations/Schemas/login";
import { openModal, closeAllModal } from "../../../actions/Modal";
//import { recordTransferRequest } from "../../../actions/transaction/TransferTransaction";
import Job from "./Job";
import { addTransactionRequest } from "../../../actions/transaction/Deposit&WithDrawlTransaction";
import AddAccountName from "./AddAccountName";
import { getFileItem } from "antd/lib/upload/utils";
const DepositTranscation = props => {
  const dispatch = useDispatch()
  let { bank, isRedirect } = props;
  console.log("THIS IS TRANSFER");
  const [formId, setFormId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [Tenant, setTenant] = useState([]);
  const setLandlordName = (id) => {
    props.getSingleBankRequest(id, (error, data) => {
          if (error) {
            console.log("I AM HERE too for tenant");
          } else {
            console.log("this is dataish for tenant: ", data);  
            localStorage.setItem("landlordName", data?.bank_account?.landlord_name);
          }
        });
  }
  const mainForm = useTransferForm({
    initialValues: {
      bankName: bank ? bank.bankId + "" : localStorage.getItem("bankID") ? localStorage.getItem("bankID") : "",
      tenantName: localStorage.getItem("tenantNewID") ? localStorage.getItem("tenantNewID") : "",
      amount: "",
      accountName: "",
      accountNameString: "interest payment to tenant",
      dateOfTransaction: moment(),
      type: "interest withdrawal"
    },
    //validationSchema: deposit,
    onSubmit: (values, { ...rest }) => {
      console.log("IN SUBMIT OF VALUES", values);
      props.addTransactionRequest(values, () => {
        //true
        console.log("IN SUBMIT", values)
        rest.resetForm({ 
          values: {
            bankName: values.bankName,
            tenantName: values.tenantName,
            //propertyName: "",
            amount: "",
            accountName: "",
            accountNameString: "Select Account Name",
            //jobName: "",
            //payor: "",
            dateOfTransaction: moment(),
            //checkNumber: "",
            //cleared: props.settings.is_cleared_default ? props.settings.is_cleared_default === true : "",
            additionalNote: "",
            //image: "",
            type: "interest withdrawal"
          }
         });
        setFormId(formId + 1);
        setLandlordName(mainForm.values.bankName);
        (isRedirect ? 
          props.history.push({
            pathname: `/reconcile`,
            //pathname: `/send-transactionimage`,
            //state: props.location.state,
            state: {
              referrer: props.location.pathname,
              // referrerState: {
              //   initialValues: values
              // },
            }
            //   image: localStorage.getItem("imageURL")
            //   //accname: localStorage.getItem("accname")
            // }
            //image: localStorage.getItem("imageFile")
            //format: "pdf"
            }) : props.history.push({
              pathname: `/bank-account-details/${mainForm.values.bankName}`,
              //pathname: `/send-transactionimage`,
              //state: props.location.state,
              state: {
                referrer: props.location.pathname,
                // referrerState: {
                //   initialValues: values
                // },
              }
              //   image: localStorage.getItem("imageURL")
              //   //accname: localStorage.getItem("accname")
              // }
              //image: localStorage.getItem("imageFile")
              //format: "pdf"
              }) )
      }
      );
    }
  });
  const apiCall = useRef();
  useEffect(() => {
    console.log("YES YES YES");
    dispatch(getAccountsRequest(
      "expense",
      props.bankAccountID,
      (error, data) => {
        if (error) {
          setLoading(false);
          console.log("I AM HERE too");
        } else {
          setLoading(false);
          setOptions(data);
          //console.log("this is id new: ", getInterestIncomeID());
          console.log("this is dataish: ", data.length);
          for (var i=0; i < data.length; i++) {
            console.log("this is dataish new: ", data[i].name === "interest payment to tenant");
            if (data[i].name === "interest payment to tenant") {
                console.log("this is dataish id: ", data[i].id);
                mainForm.setValues({
                ...mainForm.values,
                accountName: data[i].id,
                accountNameString: "interest payment to tenant"
                });
                //localStorage.setItem("idinterest", data[i].id);
            }
            else{
              mainForm.initialValues.accountNameString="Select Account Name";
            }
        }
        }
      }
    ));
    { bank ? getTenant(bank.bankId) : console.log("abv")};
  }, []);
  console.log("this is options: ", options);
  // const getInterestIncomeID = () => {
  //   console.log("IT IS HIT: ", options);
  //   for (var i=0; i < options.length; i++) {
  //     if (options[i].name === "interest income") {
  //         return options[i].id;
  //     }
  // }
  //   console.log("this is options map", options.filter((x) => x.name==="interest income"));
  // }
  console.log("FORMINTERESTDEPOSIT", mainForm.values);
  console.log("THIS IS ID INTEREST", localStorage.getItem("idinterest"));
  const getTenant = (id, cb) => {
      props.getSingleBankRequest(id, (error, data) => {
        if (error) {
          //setLoading(false);
          console.log("I AM HERE too for tenant");
        } else {
          //setTenant(data);
          console.log("this is dataish for tenant: ", data);  
          //console.log("this is dataish for tenant that is set: ", Tenant); 
          mainForm.customHandleChange(data?.bank_account?.landlord_id, "tenantName");
          mainForm.customHandleChange(data?.bank_account?.landlord_name, "tenantID");
          mainForm.customHandleChange(data?.bank_account?.landlord_id, "payeeName");
          mainForm.customHandleChange(data?.bank_account?.landlord_name, "payeeID");
          mainForm.customHandleChange(data?.bank_account?.landlord_id, "payee");
          localStorage.setItem("tenantNewID", data?.bank_account?.landlord_id);
          localStorage.setItem("tenantNewName", data?.bank_account?.landlord_name);
          localStorage.setItem("payeeNewID", data?.bank_account?.landlord_id);
          localStorage.setItem("payeeNewName", data?.bank_account?.landlord_name);
        }
      });
  }
  return (
    <React.Fragment>
      <Row type="flex" gutter={[40, 30]}>
        <Col xs={24} sm={12}>
          <BankFieldName
            title="Bank Account Name"
            placeholder="Select Bank Account"
            addBtnText="Add Bank Account"
            initialValue={bank ? bank.bankName : localStorage.getItem("bankName") ? localStorage.getItem("bankName") : null}
            // value={mainForm.values.bankName}
            name="bankName"
            link="/add-bank"
            onChange={(id,name) => {
              console.log("CHECKING IF ONCHANGE OCCURS");
              mainForm.customHandleChange(id, "bankName");
              localStorage.setItem("bankID", id);
              localStorage.setItem("bankName", name);  
              getTenant(id);
              // props.getSingleBankRequest(id);
              // mainForm.customHandleChange(props?.bankAccount?.bank_account?.landlord_id, "tenantID");
              // mainForm.customHandleChange(props?.bankAccount?.bank_account?.landlord_name, "tenantName");
            }}
            onBlur={e => mainForm.customHandleBlur(e, "bankName")}
          />

          <span className="dangerColor caption textCenter">
            {mainForm.touched["bankName"] && mainForm.errors["bankName"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
          <OptionsField
            title="Tenant Name"
            addBtnText="Add Tenant"
            name="tenantName"
            link="/add-contact"
            contactType="tenant"
            placeholder="Select Tenant"
            propertyValue={mainForm.values.tenantID}
            disabled={true}
          //   onChange={(id,name) => {mainForm.customHandleChange(id, "tenantName");
          //   localStorage.setItem("tenantID", id);
          //   localStorage.setItem("tenantName", name); 
          // }}
            initialValue={localStorage.getItem("tenantNewName") ? localStorage.getItem("tenantNewName") : null}
            onBlur={e => mainForm.customHandleBlur(e, "tenantName")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["tenantName"] && mainForm.errors["tenantName"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
          <Field
          key={formId}
            title="Amount"
            placeholder={`${props.settings.currency || "$"}0.00`}
            value={mainForm.values.amount}
            //onChange={mainForm.handleTotalChange}
            //onBlur={mainForm.handleBlur}
            name="amount"
            onChange={e => mainForm.customHandleChange(e, "amount")}
            onBlur={e => mainForm.customHandleBlur(e, "amount")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["amount"] && mainForm.errors["amount"]}
          </span>
        </Col>

        {/*<{/*Col xs={24}>
          <Split
            splits={mainForm.values.splits}
            amount={mainForm.values.amount}
            remainingAmount={
              (parseFloat(mainForm.values.amount) || 0) - mainForm.splitTotal
            }
            errors={mainForm.errors.splits}
            touched={mainForm.touched.splits}
            onSplitChange={mainForm.handleSplitChange}
            onSplitBlur={mainForm.handleSplitBlur}
            onAddSplit={mainForm.addSplit}
            onClearSplits={mainForm.clearSplits}
            accountType="income"
          />
          <div className="dangerColor caption textCenter">
            {mainForm.touched["is_split"] && mainForm.errors["noOfSplits"]}
          </div>
          <div className="dangerColor caption textCenter">
            {mainForm.touched["is_split"] && mainForm.errors["remainingAmount"]}
          </div>
        </Col> */}

        
          
            <Col xs={24} sm={12}>
              <AccountNameField
              key={formId}
                title="Account Name"
                addBtnText="Add Account Name"
                accountType="expense"
                //placeholder="interest income"
                //accValue={["interest income", mainForm.values.accountName]}
                accValue={[mainForm.values.accountNameString, mainForm.values.accountName]}
                //accValue="interest income"
                onMouseDown={e => e.preventDefault()}
                addBtnClick={() =>
                  props.openModal(
                    null,
                    <AddAccountName accType="expense" />,
                    null,
                    null,
                    { footer: null }
                  )
                }
                name="accountName"
                onChange={(e, v, name) => {mainForm.customHandleChange(e, "accountName");
                mainForm.customHandleChange(name, "accountNameString");
              }}
                onBlur={e => mainForm.customHandleBlur(e, "accountName")} 
                //initialValue="interest income"
              />
              <span className="dangerColor caption textCenter">
                {mainForm.touched["accountName"] &&
                  mainForm.errors["accountName"]}
              </span>
            </Col>

        <Col xs={24} sm={12}>
          <Field
          key={formId}
            title="Date Of Transaction"
            customInput={DatePicker}
            value={mainForm.values.dateOfTransaction}
            placeholder="MM/DD/YY"
            format="MM-DD-YY"
            name="dateOfTransaction"
            onChange={e => mainForm.customHandleChange(e, "dateOfTransaction")}
            onBlur={e => mainForm.customHandleBlur(e, "dateOfTransaction")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["dateOfTransaction"] &&
              mainForm.errors["dateOfTransaction"]}
          </span>
        </Col>

        <Col span={24}>
          <Row type="flex" justify="center" align="middle">
            <Col xl={8} lg={10} md={14} sm={18} xs={20}>
              <Button loading={props.loading} onClick={mainForm.handleSubmit} block className="">
                Record
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};
//const mapStateToProps = state => ({loading: state.transaction.recordTransferLoading, settings: state.settings.settings});
const mapStateToProps = state => ({loading: state.transaction.recordTransferLoading, settings: state.settings.settings, bankAccount: state.AddBankAccount.bankDetail});
export default withRouter(
  connect(mapStateToProps, { addTransactionRequest, openModal, closeAllModal, getSingleBankRequest })(DepositTranscation)
);