import * as Yup from "yup";

export const InterestCalcSchema = Yup.object().shape({
  // bank_account_id: Yup.string()
  //   // .min(2, "Bank  Name is Too Short! it must be between 2 to 50 characters")
  //   // .max(50, "Bank  Name is Too Long! it must be between 2 to 50 characters")
  //   // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
  //   //.required("Please provide your bank account name"),
  //   .nullable(),

  tenant_id: Yup.string()
  // .min(2, "Bank  Name is Too Short! it must be between 2 to 50 characters")
  // .max(50, "Bank  Name is Too Long! it must be between 2 to 50 characters")
  // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
  .required("Please provide Tenant name"),

    // movein_date: Yup.date().required("Please provide transaction date").nullable(),
    // moveout_date: Yup.date().required("Please provide transaction date").nullable(),
    
    movein_date: Yup.date().required("Please provide Move in date").nullable(),
    //moveout_date: Yup.date().min(Yup.ref('movein_date'),"Move out date should be greater than Move in date").required("Please provide Move out date").nullable(),
    moveout_date: Yup.date().required("Please provide Move out date").nullable().when(
      'movein_date',
      (movein_date, schema) => movein_date ? schema.test("Is more than move in date", "Move out date should be greater than Move in date", (value) => (
        value>movein_date
      )) : schema
    ),
    // moveout_date: Yup.date().when(
    //   ['movein_date'],
    //   (movein_date, moveout_date, schema) => (movein_date ? schema.test(' test ', (movein_date, moveout_date) => {
    //     if(movein_date===moveout_date)
    //     {
    //       return new Yup.ValidationError("Move out date should be greater than Move in date");
    //     }
    //   }).required("Please provide ending date") : schema.required("Please provide ending date")),
    // )
    // .nullable(),

  // moveout_date: Yup.date().when(
    //   ['movein_date'],
    //   (movein_date, schema) => (movein_date ? schema.min(movein_date, "Move out date should be greater than Move in date").required("Please provide ending date") : schema.required("Please provide ending date")),

    // SignupSchema = SignupSchema.test(
    //   // this test is added additional to any other (build-in) tests
    //   "choicesTest",
    //   null, 
    //   (obj) => {
    //     // only testing the checkboxes here
    //     if (obj.choice1 || obj.choice2 || obj.choice3) {
    //       return true; // everything is fine
    //     }
    
    //     return new yup.ValidationError("Check at least one ", null, "choices");
    //   }
    // );
    // moveout_date: Yup.date().when(['movein_date'], (movein_date).min(movein_date, "Move out date should be greater than Move in date").required("Please provide Move out date").nullable(),
  
    
    amount: Yup.string()
    // .typeError("Please provide a valid amount")
    //.min(1, "Please input a valid balance")
    .matches(
      /(^\d*\.?\d*[1-9]+\d*$)|(^[1-9]+\d*\.\d*$)/,
      "Please provide a valid Escrow Amount"
    )
    .required("Please provide an Escrow Amount")
    .notOneOf(["0"], "Please provide an Escrow Amount"),

    interestRate: Yup.string()
    // .typeError("Please provide a valid amount")
    //.min(1, "Please input a valid balance")
    .matches(
      /(^\d*\.?\d*[1-9]+\d*$)|(^[1-9]+\d*\.\d*$)/,
      "Please provide a valid Interest Amount"
    )
    .required("Please provide an Interest Amount")
    .notOneOf(["0"], "Please provide an Interest Amount"),
  // date_type: Yup.string()
  // // .min(2, "lawyerName   is Too Short! it must be between 2 to 50 characters")
  // // .max(50, "lawyerName   is Too Long! it must be between 2 to 50 characters")
  // // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
  // .required("Please provide a date type")
  // .nullable(),

  // month: Yup.string().when(
  //   'date_type',
  //   (date_type, schema) => (date_type === "monthly" ? schema.required("Please provide month") : schema),
  // )
  // .nullable(),


  // year: Yup.string().when(
  //   'date_type',
  //   (date_type, schema) => ((date_type === "yearly" || date_type === "monthly") ? schema.required("Please provide year") : schema),
  // )
  // .nullable(),
});
