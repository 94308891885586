import React from "react";
import { Row, Col } from "antd";

import { 
  ChartOfAccounts, 
  BankAccountLedger, 
  TrialBalanceSummary,
  ProfitAndLoss,
  JournalTransferLedger,
  ReconciliationForLawyers,
  InterestCalc
} from "../../../appcomponents/EscrowTrakker/reports/cards";


const Reports = props => {
  let { chartOfAccountsValues, bankAccountLedgerValues, trialBalanceSummaryValues, profitAndLossValues, transferJournalLedgerValues, reconciliationForLawyersValues } = props.location.state || {};
  console.log("CHANGE", props.location.state, bankAccountLedgerValues)
  return (
    <React.Fragment>
      <Row
        type="flex"
        gutter={[{ xs: 8, sm: 16, md: 24, lg: 24 }, { xs: 8, sm: 16, md: 24, lg: 24 }]}
        justify="space-between"
      >
        <Col xs={24} md={12} lg={8}>
          <ChartOfAccounts initialType={chartOfAccountsValues && chartOfAccountsValues.format} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <BankAccountLedger initialType={bankAccountLedgerValues && bankAccountLedgerValues.format} initialFormValues={bankAccountLedgerValues && bankAccountLedgerValues.formValues} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <TrialBalanceSummary initialType={trialBalanceSummaryValues && trialBalanceSummaryValues.format} initialFormValues={trialBalanceSummaryValues && trialBalanceSummaryValues.formValues} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <ProfitAndLoss initialType={profitAndLossValues && profitAndLossValues.format} initialFormValues={profitAndLossValues && profitAndLossValues.formValues} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <ReconciliationForLawyers initialType={reconciliationForLawyersValues && reconciliationForLawyersValues.format} initialFormValues={reconciliationForLawyersValues && reconciliationForLawyersValues.formValues} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <JournalTransferLedger initialType={transferJournalLedgerValues && transferJournalLedgerValues.format} initialFormValues={transferJournalLedgerValues && transferJournalLedgerValues.formValues} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <InterestCalc />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Reports;
