import React, { useState, useRef, useEffect } from "react";
import General from "./General";
import { Row, Col } from "antd";
import SelectOptions from "../../../../../components/input/SelectOptions";
import Icon from "../../../../../components/Icon";
import { connect } from "react-redux";
import { debounce } from "debounce";
import { getBankAccountsRequest } from "../../../../../actions/BankAccount";
import { withRouter } from "react-router-dom";

const BankName = props => {
  // console.log("allBanks", props.allBanks);
  // console.log("props", props);
  const [value, setValue] = useState(props.initialValue ? { value: props.initialValue } : {});
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const apiCall = useRef();

  useEffect(() => {
    if(props.withBankValue) {
      setValue({ value: props.bankValue })
    }
  }, [props.bankValue])

  // useEffect(() => {
  //   if(options.length) {
  //     if(props.withAllOption) {
  //       setOptions(["View All", ...options]);
  //     }
  //     else if(options[0] === "View All") {
  //       //let newOptions = options.slice()
  //       setOptions(options.slice(1));
  //     }
  //   }
  // }, [props.withAllOption]);

  useEffect(() => {
    apiCall.current = 
    debounce(
      e => {
        // console.log("api call", e);
        setLoading(!options.length);
        props.getBankAccountsRequest(10, 0, e, (error, data) => {
          if (error) {
            setLoading(false);
          } else {
            setLoading(false);
            // console.log("data", data);

            // let viewAll = props.withAllOption ? ["View All"] : [];
            // setOptions([...viewAll, ...data.bank_accounts]);
            
            //setOptions(props.withAllOption ? ["View All", ...data.bank_accounts] : [...data.bank_accounts]);
            
            setOptions(data.bank_accounts);
          }
        });
      },
      1000,
      true
    )
  }, [options, props.withAllOption])

  //   const history = useHistory();
  // console.log("props==>", props);
  //   let { ...props } = props;
  console.log("link", value);
  return (
    <div>
      <General
        {...props}
        {...value}
        onChange={e => {
          if (props.onChange) {
            if (e) {
              let vals = e.split("*");
              props.onChange(vals[0], vals[1], vals[2], vals[3], vals[4]);
            } else {
              props.onChange(e);
            }
            setValue({ value: e });
          }
        }}
        addBtnClick={() =>
          props.history.push({
            pathname: props.link,
            state: { referrer: "/add-transaction" }
          })
        }
        onSearch={Search => {
          // if (open) {
          //   // let filtered = options.filter(e=>e.law_firm_name.includes(Search))
          //   // setOptions(filtered);
          //   // console.log("options", options);
          //   // apiCall.current(e);
          // }
        }}
        onDropdownVisibleChange={open => {
          // setOpen()
          if (open) {
            setOpen(true);
            apiCall.current();
            //debounce(apiCall, 1000)();
          } else {
            setOpen(false);

            //apiCall.clear();
          }
        }}
        open={open}
      >
        {loading ? (
          <SelectOptions
            className="loadingOption"
            disabled
            value={1}
            style={{ cursor: "normal" }}
          >
            <div
              className="textCenter"
              style={{ width: "100%", textAlign: "center" }}
            >
              <Icon type="loading" style={{ fontSize: "1.5rem" }} />
            </div>
          </SelectOptions>
        ) : (
          (props.withAllOption ? ["View All", ...options] : options).map((item, index) => (
            console.log("abcdasdsada", item, "indexer: " , index),
            
            item === "View All" ? (
              <SelectOptions key={index} value="">
                All Bank Accounts
              </SelectOptions>
            ) : (
            <SelectOptions
              key={item.bank_account_id}
              value={`${item.bank_account_id}*${item.bank_account_name}*${item.law_firm_name}*${item.contact_id}*${item.job_id}`}
            >
              <Row
                  type="flex"
                  style={{ width: "100%" }}
              >
                <Col span={24}>
                  <div>
                    {item.bank_account_name}
                  </div>
                  <div className="hideMe">
                    {item.law_firm_name}
                  </div>
                </Col>
              </Row>
            </SelectOptions>
            )
          ))
        )}
        {/* <SelectOptions value={2}>urturtu</SelectOptions> */}
      </General>
    </div>
  );
};

export default withRouter(connect(null, { getBankAccountsRequest })(BankName));
