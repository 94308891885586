import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import ChartsFilterType from "../../appcomponents/EscrowTrakker/charts/ChartType";
import ChartsFilterDate from "../../appcomponents/EscrowTrakker/charts/ChartFilter";
import BankFieldName from "../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/BankName";
import OptionsField from "../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/OptionsField";
import AccountNameField from "../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/AccountName";
import JobNameField from "../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/JobName";
import { getChartsRequest, clearCharts } from "../../actions/Charts";
import PieCharts from "../../appcomponents/EscrowTrakker/charts/PieChart";
import moment from "moment";
import Loader from "../../components/loading";
import Select from "../../components/input/Select";
import Option from "../../components/input/SelectOptions";
import Info from "../../components/message";
const Charts = (props) => {
  let initialState = props.location.state;
  console.log("initialstate: ", initialState);
  const [account, setAccount] = useState(
    initialState ? initialState.accountId : false
  );
  const [customer, setCustomer] = useState(
    initialState ? initialState.tenantId : true
  );
  const [job, setJob] = useState(initialState ? initialState.propertyId : false);
  const [accountId, setAccountId] = useState(
    initialState ? initialState.accountId : null
  );
  const [customerId, setCustomerId] = useState(
    initialState ? initialState.tenantId : null
  );
  const [jobId, setJobId] = useState(initialState ? initialState.propertyId : null);
  const [accountName, setAccountName] = useState(
    initialState ? initialState.accountName : null
  );
  const [customerName, setCustomerName] = useState(
    initialState ? initialState.tenantName : null
  );
  const [jobName, setJobName] = useState(
    initialState ? initialState.propertyName : null
  );
  const [lawyerId, setLawyerId] = useState(
    initialState ? initialState.lawyerId : null
  );
  const [bankId, setBankId] = useState(
    initialState ? initialState.bankId : null
  );
  const [lawyerName, setLawyerName] = useState(
    initialState ? initialState.lawyerName : null
  );
  const [bankName, setBankName] = useState(
    initialState ? initialState.bankName : null
  );
  const [year, setYear] = useState(
    initialState ? initialState.year : moment().year()
  );
  const [month, setMonth] = useState(
    initialState ? initialState.month : moment().month() + 1
  );
  const [quarter, setQuarter] = useState(
    initialState ? initialState.quarter : null
  );
  const [startDate, setStartDate] = useState(
    initialState && initialState.startDay
      ? moment(new Date(initialState.startYear, initialState.startMonth - 1, initialState.startDay))
      // moment()
      //     .date(initialState.startDay)
      //     .month(initialState.startMonth - 1)
      //     .year(initialState.startYear)
      : null
  );
  const [startDay, setStartDay] = useState(
    initialState ? initialState.startDay : null
  );
  const [startMonth, setStartMonth] = useState(
    initialState ? initialState.startMonth : null
  );
  const [startYear, setStartYear] = useState(
    initialState ? initialState.startYear : null
  );
  const [endDate, setEndDate] = useState(
    initialState && initialState.endDay
      ? moment(new Date(initialState.endYear, initialState.endMonth - 1, initialState.endDay))
      // moment()
      //     .date(initialState.endDay)
      //     .month(initialState.endMonth - 1)
      //     .year(initialState.endYear)
      : null
  );
  const [endDay, setEndDay] = useState(
    initialState ? initialState.endDay : null
  );
  const [endMonth, setEndMonth] = useState(
    initialState ? initialState.endMonth : null
  );
  const [endYear, setEndYear] = useState(
    initialState ? initialState.endYear : null
  );
  const [dateType, setDateType] = useState(
    initialState ? initialState.dateType : "yearly"
  );

  console.log("PROPS of charts", month,
  year,
  startDay,
  startMonth,
  startYear,
  endDay,
  endMonth,
  endYear,
  startDate,
  endDate,
  dateType,
  bankId,
  lawyerId,
  accountId,
  customerId,
  jobId);
  console.log("xxasasdasd: ", props.charts); 
  useEffect(() => {
    // console.log("IT HAS BEEN SET");
    // setDateType("alldata");
    // setStartDay(
    //   dateType === "alldata"
    //     ? new Date().getDate()
    //     : console.log("yes")
    // );
    // setStartMonth(
    //   dateType === "alldata"
    //     ? (new Date().getMonth()+1)
    //     : console.log("yes")
    // );
    // setStartYear(
    //   dateType === "alldata"
    //     ? ((new Date().getFullYear())-50)
    //     : console.log("yes")
    // );
    // setEndDay(
    //   dateType === "alldata"
    //     ? new Date().getDate()
    //     : console.log("yes")
    // );
    // setEndMonth(
    //   dateType === "alldata"
    //     ? (new Date().getMonth()+1)
    //     : console.log("yes")
    // );
    // setEndYear(
    //   dateType === "alldata"
    //     ? ((new Date().getFullYear())+50)
    //     : console.log("yes")
    // );
    return () => props.clearCharts();
  }, []);
  useEffect(() => {
    console.log("customer date type: ", customer, dateType);
    //!customer ? setDateType("yearly") : setDateType("alldata");
    console.log("customer date typerrr: ", customer, dateType);
  }, [customer])
  // For API call
  useEffect(() => {
    if (
      bankId &&
      (accountId || customerId || jobId) &&
      !(dateType === "custom" && !endDate)
    ) {
      props.getChartsRequest(
        month,
        year,
        startDay,
        startMonth,
        startYear,
        endDay,
        endMonth,
        endYear,
        startDate,
        endDate,
        dateType,
        bankId,
        lawyerId,
        accountId,
        customerId,
        jobId
      );
    }
    return () => {
      props.clearCharts();
    };
  }, [
    bankId,
    accountId,
    customerId,
    lawyerId,
    jobId,
    year,
    month,
    startDay,
    startMonth,
    startYear,
    endDay,
    endMonth,
    endYear,
    startDate,
    endDate,
    dateType,
  ]);
  // For filtering Account Head, Customer, Job select field
  const handleTypeClick = (e, checked, fun, fun2, fun3) => {
    if (!checked) {
      fun(true);
    } else {
      let acc = e.target.name === "account" ? !account : account;
      let cus = e.target.name === "customer" ? !customer : customer;
      let jo = e.target.name === "job" ? !job : job;
      if (acc || cus || jo) {
        fun(false);
        fun2(null);
        fun3(null);
      }
    }
  };
  //if customer then setDateType("yearly")
  console.log("THESE ARE JOBS YES: ", startDay);
  return (
    <React.Fragment>
      {/* Main Filter */}
      <Row type="flex" justify="space-between" align="middle" gutter={[0, 20]}>
        <Col xs={24} lg={12}>
          <Row type="flex" justify="start" align="middle">
            <Col xs={24} lg={4}>
              <h4>Filters</h4>
            </Col>
            <Col xs={24} lg={18}>
              <ChartsFilterType
                accountType={{
                  name: "account",
                  outlined: !account,
                  onClick: (e) =>
                    handleTypeClick(
                      e,
                      account,
                      setAccount,
                      setAccountId,
                      setAccountName
                    ),
                }}
                customerType={{
                  name: "customer",
                  outlined: !customer,
                  onClick: (e) =>
                    handleTypeClick(
                      e,
                      customer,
                      setCustomer,
                      setCustomerId,
                      setCustomerName
                    ),
                }}
                jobType={{
                  name: "job",
                  outlined: !job,
                  onClick: (e) =>
                    handleTypeClick(e, job, setJob, setJobId, setJobName),
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={12}>
          <Row type="flex" justify="end" align="middle">
            <Col xs={{ span: 24, push: 0 }} lg={{ span: 24 }}>
              <ChartsFilterDate
                getDate={(y, m) => {
                  setYear(y);
                  setMonth(m);
                }}
                onYearChange={(year) => {
                  setYear(year);
                }}
                onQuarterChange={(q) => {
                  let quart = moment()
                    .year(year)
                    .quarter(
                      q === "q1"
                        ? 1
                        : q === "q2"
                        ? 2
                        : q === "q3"
                        ? 3
                        : q === "q4"
                        ? 4
                        : null
                    );

                  setQuarter(q);
                  setStartDay(quart.startOf("quarter").date());
                  setStartMonth(quart.startOf("quarter").month() + 1);
                  setStartYear(year);
                  setEndDay(quart.endOf("quarter").date());
                  setEndMonth(quart.endOf("quarter").month() + 1);
                  setEndYear(year);
                }}
                onQuarterYearChange={(date) => {
                  let quart = moment()
                    .year(date.year())
                    .quarter(
                      quarter === "q1"
                        ? 1
                        : quarter === "q2"
                        ? 2
                        : quarter === "q3"
                        ? 3
                        : quarter === "q4"
                        ? 4
                        : null
                    );

                  setYear(date.year());
                  setStartDay(quart.startOf("quarter").date());
                  setStartMonth(quart.startOf("quarter").month() + 1);
                  setStartYear(date.year());
                  setEndDay(quart.endOf("quarter").date());
                  setEndMonth(quart.endOf("quarter").month() + 1);
                  setEndYear(date.year());
                }}
                onStartDateChange={(date) => {
                  setStartDate(date);
                  setStartDay(date ? date.date() : null);
                  setStartMonth(date ? date.month() + 1 : null);
                  setStartYear(date ? date.year() : null);
                }}
                onEndDateChange={(date) => {
                  setEndDate(date);
                  setEndDay(date ? date.date() : null);
                  setEndMonth(date ? date.month() + 1 : null);
                  setEndYear(date ? date.year() : null);
                }}
                bankId={bankId}
                lawyerId={lawyerId}
                accountId={accountId}
                jobId={jobId}
                customerId={customerId}
                bankName={bankName}
                lawyerName={lawyerName}
                accountName={accountName}
                customerName={customerName}
                jobName={jobName}
                month={month}
                year={year}
                quarter={quarter}
                startDay={startDay}
                startMonth={startMonth}
                startYear={startYear}
                endDay={endDay}
                endMonth={endMonth}
                endYear={endYear}
                startDate={startDate}
                endDate={endDate}
                dateType={dateType}
                dataAvailable={props.charts.transactions.length}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Filter typewise */}
      <Row
        type="flex"
        gutter={[0, 30]}
        className="no-mr"
        justify="space-between"
        align="middle"
      >
        <Col xs={24} lg={13}>
          <Row type="flex" gutter={[20, 10]} justify="start" align="middle">
            {customer && (
              <Col xs={24} lg={7}>
                <OptionsField
                  //title="Customer Name"
                  initialValue={customerName}
                  noBorder
                  disabled={!customer}
                  allowClear
                  id={bankId}
                  isBankACC={!!bankId}
                  isCharts={true}
                  isEmail={!!bankId}
                  name="customerId"
                  contactType="tenant"
                  placeholder="Select Tenant"
                  withoutAddButton
                  onChange={(e, customerName) => {
                    setCustomerId(e);
                    setCustomerName(customerName);
                    setDateType("alldata");
                    console.log("IT HAS BEEN SET");
                    //setDateType("alldata");
                    setStartDay(
                      //dateType === "alldata"
                          moment().date()
                        //: console.log("yes")
                    );
                    setStartMonth(
                      //dateType === "alldata"
                         (moment().month() + 1)
                        //: console.log("yes")
                    );
                    setStartYear(
                      //dateType === "alldata"
                         (moment().year()-50)
                        //: console.log("yes")
                    );
                    setEndDay(
                      //dateType === "alldata"
                        moment().date()
                        //: console.log("yes")
                    );
                    setEndMonth(
                      //dateType === "alldata"
                        (moment().month() + 1)
                        //: console.log("yes")
                    );
                    setEndYear(
                      //dateType === "alldata"
                        (moment().year()+50)
                        //console.log("yes")
                    );
                    console.log("year is thiserrrr: ", endYear, startYear);
                  }}
                  //onBlur={e => mainForm.customHandleBlur(e, "lawyerName")}
                />
              </Col>
            )}
            {job && (
              <Col xs={24} lg={7}>
                <JobNameField
                  //title="Job Name"
                  initialValue={jobName && [jobName]}
                  noBorder
                  disabled={!job}
                  allowClear
                  hideDeleteBtn
                  withoutAddButton
                  addBtnText="Add Job Name"
                  placeholder="Select Property"
                  id={bankId}
                  isBankACC={!!bankId}
                  isCharts={true}
                  name="jobName"
                  onChange={(e, jobName) => {
                    setJobId(e);
                    setJobName(jobName);
                  }}
                  //onBlur={e => mainForm.customHandleBlur(e, "jobName")}
                />
              </Col>
            )}
            {account && (
              <Col xs={24} lg={9} onClick={()=>{
                if(!bankId && !bankName) {
                Info("warning", "Please select Bank Account First");
                }}}>
                <AccountNameField
                  //title="Account Name"
                  initialValue={accountName && [accountName]}
                  noBorder
                  disabled={!bankId && !bankName}
                  //disabled={!account}
                  allowClear
                  addBtnText="Add Account Name"
                  withoutAddButton
                  hideDeleteBtn
                  bankAccount={bankId}
                  isCharts={!!bankId}
                  placeholder="Select Account Name"
                  onMouseDown={(e) => e.preventDefault()}
                  name="accountId"
                  onChange={(e, type, accountName) => {
                    setAccountId(e);
                    setAccountName(accountName);
                  }}
                  
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col xs={24} lg={11}>
          <Row type="flex" gutter={[20, 10]} justify="end" align="middle">
            <Col xs={24} lg={6}>
              <Select
                //className="inputField"
                name="date_type"
                placeholder="Date Type"
                value={dateType}
                onChange={(dateType) => {
                  setDateType(dateType);
                  //( customer===false ? setDateType("yearly") : dateType )
                  setMonth(
                    dateType === "monthly" ? moment().month() + 1 : null
                  );
                  setYear(
                    dateType === "custom" ? null : year || moment().year()
                  );

                  setQuarter(
                    dateType === "quaterly"
                      ? "q" + moment().quarter()
                      : undefined
                  );
                  setStartDay(
                    dateType === "alldata"
                      ? moment().date()
                      :
                    dateType === "custom"
                      ? moment().date()
                      : dateType === "quaterly"
                      ? moment().startOf("quarter").date()
                      : null
                  );
                  setStartMonth(
                    dateType === "alldata"
                      ? (moment().month() + 1)
                      :
                    dateType === "custom"
                      ? moment().month() + 1
                      : dateType === "quaterly"
                      ? moment().startOf("quarter").month() + 1
                      : null
                  );
                  setStartYear(
                    dateType === "alldata"
                      ? (moment().year()-50)
                      :
                    dateType === "custom"
                      ? moment().year()
                      : dateType === "quaterly"
                      ? year || moment().year()
                      : null
                  );
                  setEndDay(
                    dateType === "alldata"
                      ? moment().date()
                      :
                    dateType === "quaterly"
                      ? moment().endOf("quarter").date()
                      : null
                  );
                  setEndMonth(
                    dateType === "alldata"
                      ? (moment().month() + 1)
                      :
                    dateType === "quaterly"
                      ? moment().endOf("quarter").month() + 1
                      : null
                  );
                  setEndYear(
                    dateType === "alldata"
                      ? (moment().year()+50)
                      :
                    dateType === "quaterly" ? year || moment().year() : null
                  );

                  setStartDate(dateType === "custom" ? moment() : null);
                  setEndDate(null);
                }}
                style={{ width: "100%" }}
                noBorder
              >
                <Option value="monthly">Monthly</Option>
                <Option value="yearly">Yearly</Option>
                <Option value="quaterly">Quarterly</Option>
                <Option value="custom">Custom</Option>
                <Option value="alldata">All Data</Option>
              </Select>
            </Col>
            <Col xs={24} lg={12}>
              <BankFieldName
                initialValue={bankName}
                noBorder
                //title="Bank Account Name"
                //allowClear
                placeholder="Select Bank Account Name"
                addBtnText="Add Bank Account"
                withoutAddButton
                // value={mainForm.values.bankName}
                name="bankId"
                onChange={(e, name) => {
                  console.log("ID IS: ", e, name);
                  setBankId(e);
                  setBankName(name);
                }}
                //onBlur={e => mainForm.customHandleBlur(e, "bankName")}
              />
            </Col>
            {/* <Col xs={24} lg={7}>
              <OptionsField
                initialValue={lawyerName}
                withClearOption
                noBorder
                //title="Lawyer Name"
                allowClear
                name="lawyerId"
                contactType="lawyer"
                placeholder="All Lawyers"
                withoutAddButton
                onChange={(e, name) => {
                  setLawyerId(e);
                  setLawyerName(name);
                }}
                //onBlur={e => mainForm.customHandleBlur(e, "lawyerName")}
              />
            </Col> */}
          </Row>
        </Col>
      </Row>
      {/* Charts Section */}
      <Row
        type="flex"
        justify="space-between"
        align="stretch"
        gutter={[25, 25]}
      >
        {props.charts.chartLoad ? (
          <Col span={24}>
            <Loader />
          </Col>
        ) : props.charts.totalAmount === null ? (
          <Col span={24}>
            <h4
              style={{
                // position: "absolute",
                // top: "50%",
                // left: "50%",
                // transform: "translate(-50%, -50%)",
                margin: "30vh 0",
                textAlign: "center",
                color: "#efad4e"
              }}
              //className="appColor"
            >
              Please select a bank and a type
            </h4>
          </Col>
        ) : !props.charts.transactions.length ||
          props.charts.transactions.length > 20 ? (
          <Col span={24}>
            <h4
              style={{
                // position: "absolute",
                // top: "50%",
                // left: "50%",
                // transform: "translate(-50%, -50%)",
                margin: "30vh 0",
                textAlign: "center",
              }}
              className="dangerColor"
            >
              {!props.charts.transactions.length
                ? "No Data Exists"
                : "Pie chart can only show upto 20 transactions, to see all transactions please view ledger report"}
            </h4>
          </Col>
        ) : (
          <PieCharts
            account={accountName}
            customer={customerName}
            job={jobName}
            filterText={[accountName, customerName, jobName]
              .filter((val) => val)
              .toString()}
            charts={props.charts}
            // allTotal={props.total_amount}
            // incomeTotal={props.total_income}
            // expenseTotal={props.total_expense}
          />
        )}
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (store) => {
  return {
    charts: store.charts,
  };
};
export default connect(mapStateToProps, {
  getChartsRequest,
  clearCharts,
})(Charts);
