import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { openModal } from "../../actions/Modal";
import { setHistory, redirect } from "../../actions/App";
import { ThemeContext } from "../../configurations/Theme";
import { Layout } from "antd";
import { menuShow } from "../../configurations/Config";
import HeaderWrapper from "../../components/header/index";
import faqs from "../../appcomponents/shares/landing/Faq";
import Contact from "./ContactUs";
import NavigationMenu from "../../components/navigationMenu";
import {
  LeftSideMenuWrapper,
  RightSideMenuWrapper,
} from "../../components/sidebars/index";
import ContentWrapper from "../../components/maincontent/index";
// import FooterWrapper from "../../components/footer/index";
import MainBankAccount from "./MainBankAccount";
import UpdateProfile from "./UpdateProfile";
import AddBankAccount from "./BankAccount";
import BankAccountDetails from "./BankAccountDetails";
import AddTransaction from "./AddTransaction";
// import Reports from "./reports/Reports";
// import SendReport from "./reports/SendReport";
// import PreviewChartReport from "./reports/PreviewChartReport";
// import PreviewReport from "./reports/PreviewReport";
import { Reports, PreviewChartReport, PreviewReport, SendReport } from "./reports"
import Contacts from "./Contacts";
import SearchTransactions from "../shell/SearchTransactions";
import TransferDetails from "../shell/TransferDetails";
import AddRecurring from "./addtranscation/addrecurring";
import EditTransaction from "./edittransaction/index";
import Charts from "./Charts";
import ContactDetails from "./ContactDetails";
import UploadBankStmt from "./UploadBankStatement";
import Settings from "./Settings";
import Reconcile from "./Reconcile";
import PrintChecks from "../shell/PrintChecks/index";
import PrintCheckSummary from "../shell/PrintChecks/PrintCheckSummary";
import subscription from "./Subscription";
import { SendImageReport } from './addtranscation';
class Shell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsedRight: true,
      collapsedLeft: false,
      ScreenWidth: window.innerWidth,
    };

    this.handleWidthChange = this.handleWidthChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.redirect(this.props.location.pathname);
    }
    window.addEventListener("resize", this.handleWidthChange);
  }

  componentDidMount() {
    this.props.setHistory(this.props.history);
    this.props.redirect(this.props.location.pathname);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWidthChange);
  }
  handleWidthChange = () => {
    this.setState({
      ScreenWidth: window.innerWidth,
    });
  };
  toggleCollapsed = (type) => {
    this.setState({
      [type]: !this.state[type],
    });
  };

  render() {
    //console.log("State shell",this.props);
    let size = this.state.ScreenWidth;
    let externalscreen = this.props.storeState.App.externalScreen ? 1 : 0; //this.props.location.state //props.App.externalScreen;
    return (
      <div className="App">
        <Layout>
          {!externalscreen && (
            <HeaderWrapper toggleCollapsed={this.toggleCollapsed} />
          )}
          <Layout>
            {menuShow.leftMenu === true &&
            !this.props.storeState.App.externalScreen ? (
              size >= 992 ? (
                <LeftSideMenuWrapper collapsedLeft={this.state.collapsedLeft} />
              ) : (
                <NavigationMenu />
              )
            ) : null}
            <ContentWrapper scroll={0} externalscreen={externalscreen}>
              <Switch>
                <Route exact path="/" component={MainBankAccount} />

                <Route exact path="/faqs" component={faqs} />
                <Route exact path="/contact-us" component={Contact} />
                <Route exact path="/upload-bank-statement" component={UploadBankStmt} />
                <Route exact path="/reconcile" component={Reconcile} />
                <Route exact path="/update-profile" component={UpdateProfile} />
                <Route exact path="/add-bank" component={AddBankAccount} />
                <Route exact path="/charts" component={Charts} />
                <Route
                  exact
                  path="/view-transaction/:id"
                  component={EditTransaction}
                />
                <Route
                  exact
                  path="/add-transaction"
                  component={AddTransaction}
                />
                <Route exact path="/add-recurring" component={AddRecurring} />
                <Route
                  exact
                  path="/edit-transaction/:id"
                  component={EditTransaction}
                />
                <Route
                  exact
                  path="/search-transaction"
                  component={SearchTransactions}
                />
                <Route
                  exact
                  path="/add-transaction"
                  component={AddTransaction}
                />
                <Route
                  exact
                  path="/bank-account-details/:id"
                  component={BankAccountDetails}
                />
                <Route exact path="/edit-bank/:id" component={AddBankAccount} />
                <Route
                  exact
                  path="/transfer-journal"
                  component={TransferDetails}
                />
                <Route exact path="/reports" component={Reports} />
                <Route
                  exact
                  path="/reports/send-report"
                  component={SendReport}
                />
                <Route
                  exact
                  path="/send-transactionimage"
                  component={SendImageReport}
                />
                <Route
                  exact
                  path="/reports/preview-chart-report"
                  component={PreviewChartReport}
                />
                <Route
                  exact
                  path="/reports/preview-chart-of-accounts"
                  component={PreviewReport}
                />
                <Route
                  exact
                  path="/reports/preview-bank-account-ledger"
                  component={PreviewReport}
                />
                <Route
                  exact
                  path="/reports/preview-trial-balance-summary"
                  component={PreviewReport}
                />
                <Route
                  exact
                  path="/reports/preview-profit-and-loss"
                  component={PreviewReport}
                />
                <Route
                  exact
                  path="/reports/preview-tenants-escrow-disclosure-statement"
                  component={PreviewReport}
                />
                <Route
                  exact
                  path="/reports/preview-reconciliation-for-landlords"
                  component={PreviewReport}
                />
                <Route exact path="/view-contact" component={ContactDetails} />

                <Route exact path="/print-cheques" component={PrintChecks} />
                <Route
                  exact
                  path="/print-cheques/details"
                  component={PrintCheckSummary}
                />

                <Route exact path="/add-contact" component={Contacts} />
                <Route exact path="/edit-contact/:id" component={Contacts} />
                <Route exact path="/settings" component={Settings} />
                <Route exact path="/subscriptions" component={subscription} />

                <Route exact path="*" render={() => <Redirect to="/" />} />
              </Switch>
            </ContentWrapper>
            {menuShow.rightMenu === true ? (
              <RightSideMenuWrapper state={this.state} />
            ) : null}
          </Layout>
          {/* <FooterWrapper /> */}
        </Layout>
      </div>
    );
  }
}

Shell.contextType = ThemeContext;

export default connect((state) => ({ storeState: state }), {
  openModal,
  setHistory,
  redirect,
})(Shell);
