import {
  GET_FIELD_OPTIONS_REQUEST,
  GET_ACCOUNTS_REQUEST,
  GET_JOBS_REQUEST,
  ADD_JOB_REQUEST,
  ADD_JOB_SUCCESS,
  ADD_JOB_ERROR,
  EDIT_JOB_REQUEST,
  EDIT_JOB_SUCCESS,
  EDIT_JOB_ERROR,
  ADD_ACCOUNT_NAME_REQUEST,
  ADD_ACCOUNT_NAME_SUCCESS,
  ADD_ACCOUNT_NAME_ERROR,
  EDIT_ACCOUNT_NAME_REQUEST,
  EDIT_ACCOUNT_NAME_SUCCESS,
  EDIT_ACCOUNT_NAME_ERROR
} from "../configurations/Types";

export const getFieldOptionsRequest = (contactType, id, cb) => {
  console.log("contactTypebob", contactType);
  return {
    type: GET_FIELD_OPTIONS_REQUEST,
    contactType,
    cb,
    id
  };
};
// FOR Custom Account Name get
export const getAccountsRequest = (accountType = "", bankAccount = "", cb) => {
  console.log("SAGA: action is firing hunain", accountType, bankAccount, cb);
  return {
    type: GET_ACCOUNTS_REQUEST,
    accountType,
    bankAccount,
    cb
  };
};
// FOR Custom Account Name Add
export const addAccountRequest = (body, cb) => {
  return {
    type: ADD_ACCOUNT_NAME_REQUEST,
    body,
    cb
  };
};
export const addAccountSuccess = cb => {
  return {
    type: ADD_ACCOUNT_NAME_SUCCESS,
    cb
  };
};
export const addAccountError = cb => {
  return {
    type: ADD_ACCOUNT_NAME_ERROR,
    cb
  };
};
export const editAccountRequest = (body, cb, id) => {
  return {
    type: EDIT_ACCOUNT_NAME_REQUEST,
    body,
    cb,
    id
  };
};
export const editAccountSuccess = cb => {
  return {
    type: EDIT_ACCOUNT_NAME_SUCCESS,
    cb
  };
};
export const editAccountError = cb => {
  return {
    type: EDIT_ACCOUNT_NAME_ERROR,
    cb
  };
};


export const getJobsRequest = (Jobs, bankAccount, cb) => {
  return {
    type: GET_JOBS_REQUEST,
    bankAccount,
    cb
  };
};

// add Jobs

export const addJobsRequest = (body, cb) => {
  console.log("jobssnjasj", body);
  return {
    type: ADD_JOB_REQUEST,
    body,
    cb
  };
};


export const addJobSuccess = () => {
  return {
    type: ADD_JOB_SUCCESS
  };
};

export const addJobError = () => {
  return {
    type: ADD_JOB_ERROR
  };
};
// edit Jobs
export const editJobsRequest = (body, cb, id) => {
  console.log("jobeditbody", body);
  return {
    type: EDIT_JOB_REQUEST,
    body,
    cb,
    id
  };
};

export const editJobSuccess = cb => {
  return {
    type: EDIT_JOB_SUCCESS,
    cb
  };
};
export const editJobError = cb => {
  return {
    type: EDIT_JOB_ERROR,
    cb
  };
};


// export const addJobSuccess = () => {
//   return {
//     type: ADD_JOB_SUCCESS
//   };
// };

// export const addJobError = () => {
//   return {
//     type: ADD_JOB_ERROR
//   };
// };
