import * as Yup from "yup";

export const transfer = Yup.object().shape({
  bankName: Yup.string()
    // .min(2, "Bank  Name is Too Short! it must be between 2 to 50 characters")
    // .max(50, "Bank  Name is Too Long! it must be between 2 to 50 characters")
    // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    .required("Please provide your bank account name"),

  tenantName: Yup.string()
    // .min(2, "lawyerName   is Too Short! it must be between 2 to 50 characters")
    // .max(50, "lawyerName   is Too Long! it must be between 2 to 50 characters")
    // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    .required("Please provide your tenant name"),
  
  accountName: Yup.string()
  // .min(
  //   2,
  //   "customerName   is Too Short! it must be between 2 to 50 characters"
  // )
  // .max(
  //   50,
  //   "customerName   is Too Long! it must be between 2 to 50 characters"
  // )
  // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
  .required("Please provide your account name"),



  amount: Yup.string()
    // .typeError("Please provide a valid amount")
    //.min(1, "Please input a valid balance")
    .matches(
      /(^\d*\.?\d*[1-9]+\d*$)|(^[1-9]+\d*\.\d*$)/,
      "Please provide a valid amount"
    )
    .required("Please provide an amount")
    .notOneOf(["0"], "Please provide an amount"),

  //fromAccountType: Yup.string(),
  
  // payee: Yup.string()
  // // .min(2, "payee is Too Short! it must be between 2 to 50 characters")
  // // .max(50, "payee is Too Long! it must be between 2 to 50 characters")
  // // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
  // //.required("Please provide your payee")
  // ,

  dateOfTransaction: Yup.date().required("Please provide transaction date").nullable()
  //.required("Date of Transaction Required"),

});
