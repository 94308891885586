import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../../components/button";
import { Row, Col } from "antd";

const ButtonWrapper = styled.div`
  & .fullWidth {
    border-radius: 20px;
  }
`;

const ChartFilters = props => {
  console.log("PROPERTY PROPS: ", props);
  return (
    <React.Fragment>
      <ButtonWrapper>
        <Row gutter={[20, 0]} type="flex" justify="center" align="middle">
        <Col span={8}>
            <Button className="fullWidth" block {...props.customerType}>
              Tenant
            </Button>
          </Col>
          <Col span={8}>
            <Button className="fullWidth" block {...props.jobType}>
              Property
            </Button>
          </Col>
          <Col span={8}>
            <Button className="fullWidth" block {...props.accountType}>
              Account
            </Button>
          </Col>
        </Row>
      </ButtonWrapper>
    </React.Fragment>
  );
};

export default ChartFilters;
