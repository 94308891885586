import * as Yup from "yup";

export const jobSchema = Yup.object().shape({
  property_name: Yup.string()
    .matches(/^[a-z0-9\s]+$/i, "Only alphabets and digits are allowed")
    .required("Please provide Property name"),
  // address: Yup.string()
  //   .matches(/^[a-z0-9\s]+$/i, "Only alphabets and digits are allowed")
  //   .required("Please provide Property address")
});
