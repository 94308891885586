import React, { useState, useEffect } from "react";
import useFormikWrapper from "../../../components/formikwrapper/FormikWrapper";
import {
  deposit,
  depositWithSplits,
  split
} from "../../../configurations/Schemas/index";

const useDepositForm = props => {
  const [validationSchema, setValidationSchema] = useState(
    props.initialValues.splits && props.initialValues.splits.length ? depositWithSplits : deposit
  );

  const[initialValues, setInitialValues] = useState(props.initialValues.splits && props.initialValues.splits.length ? {
    ...props.initialValues,
    is_split: true,
    noOfSplits: props.initialValues.splits.length,
    remainingAmount: (parseFloat(props.initialValues.amount) || 0) - props.initialValues.splits.reduce(
      (total, current) => total + (parseFloat(current.amount) || 0),
      0)
  } : props.initialValues)

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      cleared: props.initialValues.cleared
    })
  }, [props.initialValues.cleared])

  const formik = useFormikWrapper({
    ...props,
    initialValues,
    validationSchema,
    enableReinitialize: true
  });

  formik.splitTotal =
    formik.values.splits && formik.values.splits.length
      ? formik.values.splits.reduce(
          (total, current) => total + (parseFloat(current.amount) || 0),
          0
        )
      : 0;

  formik.handleTotalChange = (e, name) => {
    let amount = e.target ? e.target.value : e;

    formik.setValues({
      ...formik.values,
      amount,
      remainingAmount: (parseFloat(amount) || 0) - formik.splitTotal
    })
  }

  formik.addSplit = () => {
    let newSplit = split.cast();
    let newSplits;

    if (formik.values.splits) {
      newSplits = [...formik.values.splits];
      newSplits.push(newSplit);
    } else {
      newSplits = [newSplit];
    }

    setValidationSchema(depositWithSplits);
    //formik.customHandleChange(newSplits, "splits");
    formik.setValues({
      ...formik.values,
      splits: newSplits,
      is_split: true,
      remainingAmount:
        (parseFloat(formik.values.amount) || 0) - formik.splitTotal,
      noOfSplits: formik.values.noOfSplits ? formik.values.noOfSplits + 1 : 1,
      accountName: undefined,
      jobName: undefined
    });
  };

  formik.clearSplits = (additionalValues) => {
    let newSplits = [];

    setValidationSchema(deposit);
    //formik.customHandleChange(newSplits, "splits");
    formik.setValues({
      ...formik.values,
      splits: undefined,
      is_split: undefined,
      remainingAmount: undefined,
      noOfSplits: undefined,

      accountName: undefined,
      jobName: undefined,
      
      ...additionalValues
    });
    formik.setTouched({
      ...formik.touched,
      splits: undefined,
      is_split: undefined
    });
  };

  formik.handleSplitChange = (e, name, index) => {
    let currentArray = [...formik.values.splits];
    let newRemainingAmount = formik.values.remainingAmount;

    let newVal = {
      ...formik.values.splits[index],
      [e && e.target ? e.target.name : name]: e && e.target ? e.target.value : e
    };

    currentArray[index] = newVal;

    //Calculate new remaining amount if a split amount has changed
    if (name === "amount") {
      let splitTotal =
        formik.values.splits && formik.values.splits.length
          ? formik.values.splits.reduce(
              (total, current, ind) =>
                total +
                (ind === index
                  ? parseFloat(e && e.target ? e.target.value : e) || 0
                  : parseFloat(current.amount) || 0),
              0
            )
          : 0;

      let total = parseFloat(formik.values.amount) || 0;
      newRemainingAmount = total - splitTotal;
    }

    //formik.customHandleChange(currentArray, "splits");
    formik.setValues({
      ...formik.values,
      splits: currentArray,
      remainingAmount: newRemainingAmount
    });
  };

  formik.handleSplitBlur = (e, name, index) => {
    let newTouched;

    if (formik.touched.splits) {
      newTouched = [...formik.touched.splits];
      newTouched[index] = { ...newTouched[index], [name]: true };
    } else {
      newTouched = [];
      newTouched[index] = { [name]: true };
    }

    formik.setTouched({
      ...formik.touched,
      splits: newTouched,
      is_split: true
    });
  };

  return formik;
};

export default useDepositForm;
