import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import moment from "moment";
import useRecurringWithdrawForm from "../../../../appcomponents/EscrowTrakker/forms/useRecurringWithdrawForm";
import BankFieldName from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/BankName";
import OptionsField from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/OptionsField";
import AccountNameField from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/AccountName";
import Field from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/index";
import DatePicker from "../../../../components/datepickers/DatePicker";
import JobNameField from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/JobName";
import Image from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/uploadImageWithTitle";
import Switch from "../../../../components/switch/index";
import RecurringType from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/RecurringType";
//import Options from "../../../../components/input/SelectOptions"
import TextArea from "../../../../components/input/TextArea";
import Button from "../../../../components/button/index";
import Split from "../../../../appcomponents/EscrowTrakker/formcontrols/splits";
import { openModal, closeAllModal } from "../../../../actions/Modal";
import Job from "../Job";
import { recordRecurringRequest } from "../../../../actions/transaction/RecurringTransaction";
import AddAccountName from "../AddAccountName";

const DepositTranscation = (props) => {
  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  }
  let { bank } = props;
  const [formId, setFormId] = useState(0);

  const mainForm = useRecurringWithdrawForm({
    initialValues: {
      bankName: bank ? bank.bankId + "" : "",
      lawyerName: "",
      customerName: "",
      amount: "",
      accountName: "",
      jobName: "",
      payee: "",
      frequency: "",
      startDate: moment(),
      endDate: "",
      checkNumber: "",
      cleared: props.settings.is_cleared_default
        ? props.settings.is_cleared_default === "true"
        : "",
      additionalNote: "",
      type: "withdrawal",
    },
    //validationSchema: recurringWithdrawal,
    //apiCall: { addTransactionRequest },

    onSubmit: (values, { ...rest }) =>
      props.recordRecurringRequest(values, () => {
        rest.resetForm({
          values: {
            bankName: values.bankName,
            lawyerName: values.lawyerName,
            customerName: "",
            amount: "",
            accountName: "",
            jobName: "",
            payee: "",
            frequency: "",
            startDate: moment(),
            endDate: "",
            checkNumber: "",
            cleared: props.settings.is_cleared_default
              ? props.settings.is_cleared_default === "true"
              : "",
            additionalNote: "",
            type: "withdrawal",
          },
        });
        setFormId(formId + 1);
      }),
  });

  console.log("FORM", mainForm);
  return (
    <React.Fragment>
      <Row type="flex" gutter={[40, 30]}>
        <Col xs={24} sm={12}>
          <BankFieldName
            title="Bank Account Name"
            placeholder="Select Bank Account"
            addBtnText="Add Bank Account"
            initialValue={bank ? bank.bankName : null}
            // value={mainForm.values.bankName}
            name="bankName"
            link="/add-bank"
            onChange={(e) => mainForm.customHandleChange(e, "bankName")}
            onBlur={(e) => mainForm.customHandleBlur(e, "bankName")}
          />

          <span className="dangerColor caption textCenter">
            {mainForm.touched["bankName"] && mainForm.errors["bankName"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
          <OptionsField
            title="Lawyer Name"
            addBtnText="Add Lawyer"
            name="lawyerName"
            link="/add-contact"
            contactType="lawyer"
            placeholder="Select Lawyer"
            onChange={(e) => mainForm.customHandleChange(e, "lawyerName")}
            onBlur={(e) => mainForm.customHandleBlur(e, "lawyerName")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["lawyerName"] && mainForm.errors["lawyerName"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
          <OptionsField
            key={formId}
            title="Customer Name"
            addBtnText="Add Customer"
            link="/add-contact"
            contactType="customer"
            placeholder="Select Customer"
            name="customerName"
            onChange={(e, name) => {
              mainForm.customHandleChange(e, "customerName");
              if (!mainForm.values.payee) {
                mainForm.customHandleChange(e, "payee");
                mainForm.customHandleChange(name, "customer");
              }
            }}
            onBlur={(e) => mainForm.customHandleBlur(e, "customerName")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["customerName"] &&
              mainForm.errors["customerName"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
          <Field
            key={formId}
            title="Amount"
            placeholder={`${props.settings.currency || "$"}0.00`}
            value={mainForm.values.amount}
            //onChange={mainForm.handleTotalChange}
            //onBlur={mainForm.handleBlur}
            name="amount"
            onChange={(e) => mainForm.customHandleChange(e, "amount")}
            onBlur={(e) => mainForm.customHandleBlur(e, "amount")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["amount"] && mainForm.errors["amount"]}
          </span>
        </Col>

        {!(mainForm.values.splits && mainForm.values.splits.length) ? (
          <React.Fragment>
            <Col xs={24} sm={12}>
              <AccountNameField
                key={formId}
                title="Account Name"
                addBtnText="Add Account Name"
                accountType="expense"
                placeholder="Select Account Name"
                onMouseDown={(e) => e.preventDefault()}
                addBtnClick={() =>
                  props.openModal(
                    null,
                    <AddAccountName accType="expense" />,
                    null,
                    null,
                    { footer: null }
                  )
                }
                name="accountName"
                onChange={(e) => mainForm.customHandleChange(e, "accountName")}
                onBlur={(e) => mainForm.customHandleBlur(e, "accountName")}
              />
              <span className="dangerColor caption textCenter">
                {mainForm.touched["accountName"] &&
                  mainForm.errors["accountName"]}
              </span>
            </Col>

            <Col xs={24} sm={12}>
              <JobNameField
                key={formId}
                title="Job Name"
                addBtnText="Add Job Name"
                placeholder="Select Job"
                addBtnClick={() =>
                  props.openModal(null, <Job />, null, null, {
                    footer: null,
                  })
                }
                name="jobName"
                onChange={(e) => mainForm.customHandleChange(e, "jobName")}
                onBlur={(e) => mainForm.customHandleBlur(e, "jobName")}
              />
              <span className="dangerColor caption textCenter">
                {mainForm.touched["jobName"] && mainForm.errors["jobName"]}
              </span>
            </Col>
          </React.Fragment>
        ) : null}

        <Col xs={24} sm={12}>
          <OptionsField
            key={formId}
            title="Payee"
            addBtnText="Add Payee"
            link="/add-contact"
            contactType="payor"
            placeholder="Select Payee"
            customerValue={mainForm.values.customer}
            name="payee"
            onChange={(e) => mainForm.customHandleChange(e, "payee")}
            onBlur={(e) => mainForm.customHandleBlur(e, "payee")}
          />

          <span className="dangerColor caption textCenter">
            {mainForm.touched["payee"] && mainForm.errors["payee"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
          <RecurringType
            key={formId}
            title="Recurring"
            withoutAddButton
            placeholder="Select Recurring"
            name="frequency"
            onChange={(e) => mainForm.customHandleChange(e, "frequency")}
            onBlur={(e) => mainForm.customHandleBlur(e, "frequency")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["frequency"] && mainForm.errors["frequency"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
          <Field
            key={formId}
            title="Start Date"
            customInput={DatePicker}
            value={mainForm.values.startDate}
            placeholder="MM/DD/YY"
            format="MM-DD-YY"
            name="startDate"
            disabledDate={disabledDate}
            onChange={(e) => mainForm.customHandleChange(e, "startDate")}
            onBlur={(e) => mainForm.customHandleBlur(e, "startDate")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["startDate"] && mainForm.errors["startDate"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
          <Field
            key={formId}
            title="End Date"
            customInput={DatePicker}
            placeholder="MM/DD/YY"
            format="MM-DD-YY"
            name="endDate"
            onChange={(e) => mainForm.customHandleChange(e, "endDate")}
            onBlur={(e) => mainForm.customHandleBlur(e, "endDate")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["endDate"] && mainForm.errors["endDate"]}
          </span>
        </Col>

        {/* <Col xs={24} sm={12}>
          <Field
          key={formId}
            title="Check Number"
            placeholder="Check Number"
            name="checkNumber"
            onChange={e => mainForm.customHandleChange(e, "checkNumber")}
            onBlur={e => mainForm.customHandleBlur(e, "checkNumber")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["checkNumber"] && mainForm.errors["checkNumber"]}
          </span>
        </Col> */}

        <Col xs={24} sm={12}>
          <Field
            key={formId}
            title="Cleared"
            span
            noHeadingMargin
            customInput={Switch}
            checked={mainForm.values.cleared}
            name="cleared"
            onChange={(e) => mainForm.customHandleChange(e, "cleared")}
            onBlur={(e) => mainForm.customHandleBlur(e, "cleared")}
          />

          <span className="dangerColor caption textCenter">
            {mainForm.touched["cleared"] && mainForm.errors["cleared"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
          <Field
            key={formId}
            title="Additional Notes"
            //name="additionalNotex"
            placeholder="Note"
            style={{ height: "8.8rem" }}
            customInput={TextArea}
            name="additionalNote"
            onChange={(e) => mainForm.customHandleChange(e, "additionalNote")}
            onBlur={(e) => mainForm.customHandleBlur(e, "additionalNote")}
          />

          <span className="dangerColor caption textCenter">
            {mainForm.touched["additionalNote"] &&
              mainForm.errors["additionalNote"]}
          </span>
        </Col>

        <Col span={24}>
          <Row type="flex" justify="center" align="middle">
            <Col xl={8} lg={10} md={14} sm={18} xs={20}>
              <Button
                loading={props.loading}
                onClick={mainForm.handleSubmit}
                block
                className=""
              >
                Record
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  loading: state.transaction.recordRecurringLoading,
  settings: state.settings.settings,
});

export default withRouter(
  connect(mapStateToProps, {
    recordRecurringRequest,
    openModal,
    closeAllModal,
  })(DepositTranscation)
);
