import * as Yup from "yup";

export const addBankSchema = Yup.object().shape({
  landlord_name: Yup.string()
    // .min(
    //   2,
    //   "Law  Firm Name is Too Short! it must be between 2 to 50 characters"
    // )
    // .max(
    //   50,
    //   "Law  Firm Name is Too Long! it must be between 2 to 50 characters"
    // )
    //.matches(/^([A-Za-z0-9 _.,!"'-]*)$/i, "No special characters allowed")
    .required("Please provide landlord name"),

    // landlord_address: Yup.string()
    // .required("Please provide address"),
    // landlord_city: Yup.string()
    // .required("Please provide city"),
    // landlord_state: Yup.string()
    // .required("Please provide state"),
    // landlord_zip: Yup.number()
    // .required("Please provide zip"),

  bank_account_name: Yup.string()
    // .min(2, "Bank  Name is Too Short! it must be between 2 to 50 characters")
    // .max(50, "Bank  Name is Too Long! it must be between 2 to 50 characters")
    .matches(/^([A-Za-z0-9 _.,!"'-]*)$/i, "No special characters allowed")
    .required("Please provide your bank account name"),

  bank_name: Yup.string()
    // .min(2, "Bank  Name is Too Short! it must be between 2 to 50 characters")
    // .max(50, "Bank  Name is Too Long! it must be between 2 to 50 characters")
    //.matches(/^([A-Za-z0-9 _.,!"'-]*[^\s]$)$/i, "No special characters allowed")
    .matches(/^[a-z0-9.\s]+$/i, "Only alphabets digits and periods are allowed")
    .required("Please provide your bank name"),

    // bank_address: Yup.string()
    // .required("Please provide bank address"),
    // bank_city: Yup.string()
    // .required("Please provide bank city"),
    // bank_state: Yup.string()
    // .required("Please provide bank state"),
    // bank_zip: Yup.number()
    // .required("Please provide zip"),

  balance: Yup.string()
    // .typeError("Please provide a valid amount")
    //.min(1, "Please input a valid balance")
    .matches(
      /(^\d*\.?\d*[0-9]+\d*$)|(^[0-9]+\d*\.\d*$)/,
      "Please provide a valid amount"
    ),
  // .required("Please provide your bank balance")
  open_date: Yup.string().required("Please provide a date"),

  additional_note: Yup.string()
    .min(0, "Optional- 500 Characters with spaces allowed")
    .max(500, "Optional- 500 Characters with spaces allowed"),
});
