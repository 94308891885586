import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "antd";
import Button from "../../../../components/button";
import { openModal, closeModal } from "../../../../actions/Modal";
import { getUserSettingRequest } from "../../../../actions/Settings";
const InterestCalculatorResult = (props) => {
  console.log("props are: ", props.values.moveout_date);
  console.log("DATE IS: ", Date.parse(props.values.moveout_date));
  console.log("DATE IS TYPE: ", props.values.moveout_date-props.values.movein_date);
  var moveOutDate = new Date(props.values.moveout_date);
  var moveInDate = new Date(props.values.movein_date);
  console.log("NEW DATE IS: ", moveOutDate);
  console.log("NEW DATE IS TYPE: ", moveInDate);
  const getYearsDifference = () => {
    //return props.values.moveout_date.split("-")[0]-props.values.movein_date.split("-")[0];
    var diff = (moveOutDate.getTime()-moveInDate.getTime())/1000;
    console.log("this is year: ", diff);
    diff/=(60*60*24);
    console.log("this is year two: ", diff);
    return (diff/365).toFixed(2);
    // Math.abs(Math.round(diff / 365.25));
  }
  const getResult = () => {
    return ((props.values.amount)*(props.values.interestRate/100))*getYearsDifference();
  } 
  console.log("result is: ", getResult());
  console.log("result is also: ", ((props.values.amount)*(props.values.interestRate)));
  useEffect(() => {
    props.getUserSettingRequest();
  }, []);
  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      className="textCenter"
      gutter={[0, 30]}
    >
      <Col span={24} className="mr-t">
        <h3>Interest amount owed to {props.values.tenant_name} <br /> for holding {props.settings.currency  ? props.settings.currency : "$"}{props.values.amount} for {getYearsDifference()} { getYearsDifference()==="1.00" ? "Year" : "Years" } = {props.settings.currency ? props.settings.currency : "$"}{getResult()===Infinity ? "0.00" : getResult().toFixed(2)}</h3>
      </Col>
      <Col span={16} className="mr-t">
        <Button block outlined onClick={() => props.closeModal()}>Cancel</Button>
      </Col>
    </Row>
  )
}
export default withRouter(connect(state => ({ reports: state.Reports, settings: state.settings.settings }), { closeModal, openModal, getUserSettingRequest })(InterestCalculatorResult));
