import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import ContactForm from "../../appcomponents/shares/landing/ContactForm";
import Button from "../../components/button/index";
import Image from "../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/uploadImageWithTitle";
import HeaderWrapper from "../../components/header/index";
import useStatementForm from "./UseStatementForm";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Info from "../../components/message/index";
import {
  uploadStatementsRequest,
  getStatementsRequest,
} from "../../actions/Statements";
import { useDispatch } from "react-redux";
import { FiAlertOctagon, FiPlus } from "react-icons/fi";
import StatementTable from "./StatementTable";
const UploadBankStatement = (props) => {
  const dispatch = useDispatch();
  const [formId, setFormId] = useState(0);
  const [options, setOptions] = useState([]);
  const [fileName, setfileName] = useState("");
  const mainForm = useStatementForm({
    initialValues: {
      image: "",
    },
    //enableReinitialize: true,
    //validationSchema: deposit,
    //apiCall: { addTransactionRequest },

    onSubmit: (values, { ...rest }) => {
      dispatch(
        uploadStatementsRequest(values, () => {
          //true
          console.log("IN SUBMIT", values);
          rest.resetForm({
            values: {
              image: "",
            },
          });
          setFormId(formId + 1);
          setfileName("");
        })
      );
      props.getStatementsRequest((data) => {
        //(true
        if (data) {
          console.log("THIS IS DATA: ", data);
          setOptions(data.statements);
        } else {
          console.log("ERROR");
        }
      });
    },
  });
  useEffect(() => {
    props.getStatementsRequest((data) => {
      //(true
      if (data) {
        console.log("THIS IS DATA: ", data);
        //const arrayOfObj = Object.entries(data).map((e) => ( { [e[0]]: e[1] } ));
        setOptions(data.statements);
      } else {
        console.log("ERROR");
      }
    });
  }, []);
  console.log("OPTIONS ARE: ", props);
  let fileInputRef;
  //   fileInputRef.onchange = function() {
  //     if(this.files[0].size > 5200000){
  //       Info("warning", "End date must be after start date");
  //       return;
  //     };
  // };
  return (
    <Row type="flex" justify="start" gutter={[15, 30]}>
      <Col span={24}>
        <HeaderWrapper externalscreen={1} />
      </Col>
      <Col span={24}>
        <Row type="flex" justify="start" align="top" gutter={[15]}>
          <Col xs={24} sm={12} lg={6}>
            <input
              type="file"
              accept="application/pdf"
              name="image"
              onClick={(event)=> { 
                event.target.value = null
              }}
              style={{ display: "none" }}
              ref={(ref) => (fileInputRef = ref)}
              onChange={(e) => {
                console.log("E IS: ", e?.target?.files);
                var files = e?.target?.files;
                console.log("E IS TWO: ", files[0]);
                setfileName(files[0]?.name);
                if (files[0]?.size > 5200000) {
                  Info("warning", "File size must not be greater than 50MB");
                  return;
                }
                if (files[0]?.type!=="application/pdf") {
                  Info("warning", "File must be a PDF");
                  return;
                }
                mainForm.customHandleChange(files[0], "image");
              }}
            />
            <Button outlined block onClick={() => fileInputRef.click()}>
              Browse Bank Statements
            </Button>
            <p>{fileName}</p>
            {/* <Image
              key={formId}
              //title="Image"
              btnText="Upload Bank Statements"
              listType="picture-card"
              style={{ height: "2rem" }}
              className="avatar-uploader"
              name="image"
              isPDF={true}
              isHeight={true}
              onChange={(e) =>
                mainForm.customHandleChange(e.originFileObj, "image")
              }
              //  onBlur={e => mainForm.customHandleBlur(e, "image")}
            /> */}
            {/* <span className="dangerColor caption textCenter">
                {mainForm.touched["image"] && mainForm.errors["image"]}
              </span> */}
          </Col>
          <Col
            xs={24}
            sm={12}
            lg={3}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button disabled={!fileName} loading={props.UploadLoading} onClick={mainForm.handleSubmit} block>
              Upload
            </Button>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={12} lg={24}>
        <h6
        //className="appColor"
        >
          PDF ~ 50MB Max
        </h6>
      </Col>
      {/* <Col xs={24} sm={12} lg={6}>
          <Button>Upload</Button>
        </Col> */}
      {/* <Col xs={24} sm={12} lg={6} style={{display: "flex", alignItems: "center"}}>
        <Button onClick={mainForm.handleSubmit} block >
          Upload
        </Button>
      </Col> */}
      {/* <Col span={24} style={{  textAlign: "center" }}>
      <h2>Get In Touch With Us Now</h2>
      </Col>
      <Col span={8}>
        <ContactForm />
      </Col> */}
      <Row
        type="flex"
        className="mr-t"
        justify="center"
        style={{ height: "20vh", overFlow: "auto", width:'100%' }}
      >
        
          {!props?.statements && !props?.statements?.length ? (
            // <div className="textCenter" style={{ width: "100%" }}>
            //   <Icon type="loading" style={{ fontSize: "4rem" }} />
            // </div>
            <Col span={24}>
              <FiAlertOctagon />
              <h3 className="textCenter"> No Statements Exist</h3>
            </Col>
          ) : (
            <>
              {props?.statements?.map((bank) => (
                <Col xs={24} sm={12} lg={24}>
                <StatementTable
                  id={bank.id}
                  fileName={bank.file_name}
                  URL={bank.file_url}
                />
                </Col>
              ))}
            </>
          )}
        
      </Row>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  loading: state.Statements.addStatementLoading,
  settings: state.settings.settings,
  UploadLoading:state.Statements.uploadStatementLoading,
  statements: state.Statements.statements,
});

export default withRouter(
  connect(mapStateToProps, {
    getStatementsRequest,
  })(UploadBankStatement)
);
