import {
    UPLOAD_STATEMENTS_REQUEST,
    UPLOAD_STATEMENTS_SUCCESS,
    UPLOAD_STATEMENTS_ERROR,
    GET_STATEMENTS_REQUEST,
    GET_STATEMENTS_SUCCESS,
    GET_STATEMENTS_ERROR,
    UPDATE_STATEMENTS_REQUEST,
    UPDATE_STATEMENTS_SUCCESS,
    UPDATE_STATEMENTS_ERROR,
    DELETE_STATEMENTS_REQUEST,
    DELETE_STATEMENTS_SUCCESS,
    DELETE_STATEMENTS_ERROR
  } from "../configurations/Types";
  
  export const uploadStatementsRequest = (body, cb) => {
    console.log("IT IS WORKING", body);
    return {
      type: UPLOAD_STATEMENTS_REQUEST,
      body,
      cb
    };
  };
  
  export const uploadStatementsSuccess = () => {
    return {
      type: UPLOAD_STATEMENTS_SUCCESS,
    };
  };
  
  export const uploadStatementsError = () => {
    return {
      type: UPLOAD_STATEMENTS_ERROR
    };
  };
  
  export const updateStatementsRequest = (file_name, fileID, cb) => {
    return {
      type: UPDATE_STATEMENTS_REQUEST,
      file_name,
      fileID,
      cb
    };
  };
  
  export const updateStatementsSuccess = () => {
    return {
      type: UPDATE_STATEMENTS_SUCCESS
    };
  };
  
  export const updateStatementsError = () => {
    return {
      type: UPDATE_STATEMENTS_ERROR
    };
  };
  
  
  export const getStatementsRequest = (cb) => {
    console.log("IT IS WORKING");
    return {
      type: GET_STATEMENTS_REQUEST,
      cb
    };
  };
  
  export const getStatementsSuccess = (body, cb) => {
    return {
      type: GET_STATEMENTS_SUCCESS,
      body,
      cb
    };
  };
  
  export const getStatementsError = error => {
    return {
      type: GET_STATEMENTS_ERROR,
      error
    };
  };
  
  export const deleteStatementsRequest = (id) => {
    //console.log("updateUsersSettingsRequest", body);
    return {
      type: DELETE_STATEMENTS_REQUEST,
      id
    };
  };
  
  export const deleteStatementsSuccess = (cb) => {
    return {
      type: DELETE_STATEMENTS_SUCCESS,
      cb
    };
  };
  export const deleteStatementsError = () => {
    return {
      type:  DELETE_STATEMENTS_ERROR
    };
  };