import React, { useState, useRef, useEffect } from "react";
import General from "./General";
import { Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import SelectOptions from "../../../../../components/input/SelectOptions";
import Icon from "../../../../../components/Icon";
import { connect } from "react-redux";
import { debounce } from "debounce";
import { getFieldOptionsRequest } from "../../../../../actions/FieldOptions";
import EditButton from "../../EditButton";
import DeleteButton from "../../DeleteButton";
import { FiAlertTriangle } from "react-icons/fi";
import PopConfirm from "../../../../../components/pop-confirm";
import {
  deleteContactRequest
} from "../../../../../actions/Contact";
const OptionsFields = props => {
  // console.log("allBanks", props.allBanks);
  // console.log("props", props);
  const [value, setValue] = useState(props.initialValue ? { value: props.initialValue } : {});
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectLoading, setSelectLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const apiCall = useRef();
  const [isProperty, setIsProperty] = useState(false);
  
  // useEffect(() => {
  //   if(props.contactType==="Property"){
  //     setIsProperty(true);
  //   }
  // }, [])

  useEffect(() => {
    console.log("BEST PROPS", props);
    console.log("BEST PROPSERS", props.propertyValue);
    if(props.propertyValue) {
      setValue({ value: props.propertyValue })
    }
  }, [props.propertyValue])

  useEffect(() => {
    if(props.tenantValue) {
      setValue({ value: props.tenantValue })
    }
  }, [props.tenantValue])

  useEffect(() => {
    apiCall.current = 
    debounce(
      e => {
        console.log("api call", e);
        console.log("THIS IS PROPERTY: ", isProperty);
        console.log("THIS IS PROPERTY PROP: ", props);
        setLoading(!options.length);
        props.getFieldOptionsRequest(props.contactType, props.id,  (error, data) => {
          console.log("data is this: ", data);
          if (error) {
            setLoading(false);
            console.log("VERY NEW ERROR", error);
          } else {
            console.log("lawyer name", data);
            setLoading(false);
            let viewAll = props.withClearOption ? ["View All"] : [];
            setOptions([...viewAll, ...data]);
          }
        });
      },
      1000,
      true
    )
  }, [options, props.id])
  
  useEffect(() => {
    setOptions([]);
    apiCall.current();
  }, [props.id]);

  useEffect(() => {
    if(props.getFromBankLawfirm) {
      setSelectLoading(true);
      props.getFieldOptionsRequest(undefined, (error, data) => {
        if (error) {
          console.log("ERRORNEW", error);
          setSelectLoading(false);
        } else {
          setSelectLoading(false);

          console.log("NEW DATA: ", data);
          const fromLawfirm = data.find(val => val.contact_id == props.getFromBankLawfirm);
          if(fromLawfirm) {
            let id = fromLawfirm.contact_id, name = fromLawfirm.full_name;

            props.onChange(id, name);
            setValue({ value: name });
          }
        }
      });
    }
  }, [props.getFromBankLawfirm])

  //   const history = useHistory();
  console.log("Lawer props==>", props);
  //   let { ...props } = props;

  //
  const giveEmail = (text) => {
    let brokenString = '';
      for(let i = 0; i<text?.length;i++){
        if(text[i] === '@'){
          //count = 0;
          brokenString += '@\n';
      }
      else{
        //count++;
        brokenString += text[i];
     }
    } 
  return brokenString;
}
  return (
    <div>
      <General
        {...props}
        {...value}
        loading={selectLoading}
        onChange={e => {
          if (props.onChange) {
            if (e) {
              props.onChange(e.split("*")[0], e.split("*")[1]);
            } else {
              props.onChange(e);
            }
            setValue({ value: e })
          }
        }}
        addBtnClick={() =>
          props.history.push({
            pathname: props.link,
            state: { referrer: "/add-transaction",
            contactType:props.contactType === "payor" ? "other" : props.contactType
           }
          })
        }
        onSearch={Search => {
          // if (open) {
          //   // let filtered = options.filter(e=>e.law_firm_name.includes(Search))
          //   // setOptions(filtered);
          //   // console.log("options", options);
          //   // apiCall.current(e);
          // }
        }}
        onDropdownVisibleChange={open => {
          // setOpen()
          if (open) {
            setOpen(true);
            apiCall.current();
            //debounce(apiCall, 1000)();
          } else {
            setOpen(false);

            //apiCall.clear();
          }
        }}
        open={open}
      >
        {loading ? (
          <SelectOptions
            className="loadingOption"
            disabled
            value={1}
            style={{ cursor: "normal" }}
          >
            <div
              className="textCenter"
              style={{ width: "100%", textAlign: "center" }}
            >
              <Icon type="loading" style={{ fontSize: "1.5rem" }} />
            </div>
          </SelectOptions>
        ) : (
          options.map((item, index) => (
            console.log("EMAILS ARE: ", item.email, item.tenant_email),
            item === "View All" ? (
              <SelectOptions key={index} value="">
                All {props.contactType}s
              </SelectOptions>
            ) : (
            <SelectOptions
              key={props.isBankACC ? item.tenant_id : item.contact_id}
              value={props.isBankACC ? `${item.tenant_id}*${item.tenant_name}` : `${item.contact_id}*${item.full_name}`}
            >
              <Row
                  type="flex"
                  style={{ width: "100%"}}
              >
                <Col span={20}>
                  {props.isBankACC ?
                  <div>
                    {item.tenant_name}
                  </div>
                  : <div>{item.full_name}&nbsp;{ !props.isCharts ? <span className="hideMe" style={{ textTransform: "capitalize" }}>({item.contact_type})</span> : null}</div>}
                  { props.isEmail ? 
                  <div className="hideMe">
                    {giveEmail(item.tenant_email)}
                  </div>
                  : 
                  <div className="hideMe">
                    {giveEmail(item.email)}
                  </div> }
                </Col>
                {!props.isBankACC && !props.isCharts ?
                <Col
                     span={2}
                     className="hideMe"
                     onClick={(e) => e.stopPropagation()}
                   >
                     <EditButton
                       onClick={() =>
                        props.history.push({
                          pathname: `/edit-contact/${item.contact_id}`,
                          state: { referrer: "/add-transaction", contactDetails: item,
                          contactType:props.contactType === "payor" ? "other" : props.contactType
                        }
                        })
                       }
                     />
                   </Col>
                    : null}
                    {!props.isBankACC && !props.isCharts ? 
                   <Col
                     span={2}
                     className="hideMe"
                     // onMouseDown={e => {
                     //   e.stopPropagation();
                     //   e.preventDefault();
                     // }}
                     onClick={(e) => {
                       e.stopPropagation();
                       //e.preventDefault();
                     }}
                   >
                     <PopConfirm
                       title="Are you sure you want to delete this contact?"
                       icon={
                         <FiAlertTriangle
                           style={{
                             position: "absolute",
                             color: "#f44336",
                             left: 0,
                             top: 0,
                             marginTop: 6,
                           }}
                         />
                       }
                       cancelText="No"
                       okText="Yes"
                      //  onConfirm={function () {
                      //    props.deleteAccountHeadRequest(item.id, (res) => {
                      //      apiCall.current();

                      //      if (props.onDelete) props.onDelete(item.id);

                      //      // if(item.id == value.accountId) {
                      //      //   props.onChange();
                      //      //   setValue({ value: undefined });
                      //      // }
                      //    });
                      //  }}
                       onConfirm={function () {
                                 props.deleteContactRequest(item.contact_id, () => {
                                   apiCall.current();
      
                                   if (props.onDelete)
                                     props.onDelete(item.contact_id)
      
                                   if (item.contact_id == value.contact_id) {
                                    props.onChange();
                                    setValue({ value: undefined });
                                  }
                                });
                              }}
                      >
                       <DeleteButton />
                     </PopConfirm>
                   </Col>
                   : null}
              </Row>
            </SelectOptions>
            )
          ))
        )}
        {/* <SelectOptions value={2}>urturtu</SelectOptions> */}
      </General>
    </div>
  );
};

export default withRouter(
  connect(null, { getFieldOptionsRequest, deleteContactRequest })(OptionsFields)
);
