import {
  ADD_CONTACT_REQUEST,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_ERROR,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_ERROR,
  GET_CONTACTS_REQUEST,
  DELETE_CONTACT_REQUEST,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_ERROR,
  EDIT_CONTACT_REQUEST,
  EDIT_CONTACT_SUCCESS,
  EDIT_CONTACT_ERROR
} from "../configurations/Types";

// Add Account Request
export const addAccountRequest = (body, cb) => {
  return {
    type: ADD_CONTACT_REQUEST,
    body,
    cb
  };
};

export const addAccountSuccess = () => {
  return {
    type: ADD_CONTACT_SUCCESS
    // body,
    // cb
  };
};

export const addContactError = () => {
  return {
    type: ADD_CONTACT_ERROR
  };
};

export const getUserContactsRequset = (params, cb, clearFlag, ofPage) => {
  return {
    type: GET_CONTACTS_REQUEST,
    params,
    callback: cb,
    clearFlag,
    ofPage
  };
};

export const getUserContactsSuccess = (contactsResults, clearFlag, ofPage) => {
  console.log("getUserContactsSuccess", contactsResults.contacts);
  //JSON.stringify(localStorage.setItem("contactdata", contactsResults.contacts));
  return {
    type: GET_CONTACTS_SUCCESS,
    contactsResults,
    clearFlag,
    ofPage
  };
};

export const getUserContactsError = error => {
  return {
    type: GET_CONTACTS_ERROR,
    error
  };
};

//--  Delete Contacts
export const deleteContactRequest = (id, cb) => {
  console.log("deleted row id", id);
  return {
    type: DELETE_CONTACT_REQUEST,
    id,
    cb
  };
};

export const deleteContactSuccess = () => {
  return {
    type: DELETE_CONTACT_SUCCESS
  };
};

export const deleteContactError = error => {
  return {
    type: DELETE_CONTACT_ERROR,
    error
  };
};

// EDIT Account Request
export const editContactRequest = (body, cb) => {
  return {
    type: EDIT_CONTACT_REQUEST,
    body,
    cb
  };
};

export const editContactSuccess = () => {
  return {
    type: EDIT_CONTACT_SUCCESS
    // body,
    // cb
  };
};

export const editContactError = error => {
  return {
    type: EDIT_CONTACT_ERROR,
    error
  };
};
