import React from "react";
import { Row, Col, Layout } from "antd";
import Image from "../../components/image";
import { fullFooterLogo } from "../../configurations/Config";
import HeaderBar from "../../appcomponents/shares/landing/Headerbar";
import Banner from "../../appcomponents/shares/landing/Banner";
import { Link } from "react-router-dom";
import mainContent from "../../components/maincontent";
import styled from "styled-components";
import Sections from "../../appcomponents/shares/landing/SectionText";
import Faq from "../../appcomponents/shares/landing/Faq";
import ContactForm from "../../appcomponents/shares/landing/ContactForm";
import useScreenWidth from "../../components/hooks/useScreenWidth";
import PricingOption from "../../appcomponents/shares/landing/PricnigOption";
//escorw images links
import cashBack from "../../assets/images/cash-back.svg";
import cashBack1 from "../../assets/images/cash-back-1.svg";
import money from "../../assets/images/money.svg";
import purse from "../../assets/images/purse.svg";
import appleDownload from "../../assets/icons/apple-store-apple.svg";
import androidDownload from "../../assets/icons/google-play-badge.svg";
import bankAccounts from "../../assets/images/01-phone.png";
import bankAccountCard from "../../assets/images/01-Card.png";
import addTransaction from "../../assets/images/02-Phone.png";
import addTransactionCard from "../../assets/images/02-Cards.png";
import chartMobile from "../../assets/images/03-Phone.png";
import chartMobileCard from "../../assets/images/03-Cards.png";
import reports from "../../assets/images/04-Phone.png";
import reportsCard from "../../assets/images/04-Card.png";
import searchTransaction from "../../assets/images/05-Phone.png";
import searchTransactionCard from "../../assets/images/05-Card.png";
import { FiTwitter, FiFacebook, FiLinkedin, FiInstagram } from "react-icons/fi";

const contentBasic = [
  "Upto 30 tenants",
  "No Other Restrictions",
  "Multi-Platform",
  "Multi-Device Login"
];
const contentStandard = [
  "Upto 100 tenants",
  "No Other Restrictions",
  "Multi-Platform",
  "Multi-Device Login"
];
const contentProfessional = [
  "Upto 500 tenants",
  "No Other Restrictions",
  "Multi-Platform",
  "Multi-Device Login"
];
const contentEnterprise = [
  "Upto 25,000 Tenants",
  "No Other Restrictions",
  "Multi-Platform",
  "Multi-Device Login"
];

const ContentWrap = styled(mainContent)`
  counter-reset: my-sec-counter;
  margin: 0;
  padding: 0;
  min-height: auto;
  overflow: hidden;
  & .iconBtn {
    background-color: ${props => props.theme[props.theme.mode].secondary.main};
    display: inline-block;
    padding: 8px;
    border-radius: 5px;
  }

  .li-Option-mr-b {
    margin-bottom: 0.5rem;
  }
  .stepContainer {
    margin-bottom: 20em;
  }
  .landingPageSection {
    margin-top: 0rem !important;
  }
  .landingPageItem {
    margin-top: 2rem !important;
  }
  .footerSection {
    margin-top: 7.5rem !important;
  }
  .sectionContainer {
    margin: 12rem 0 0 0 !important;
  }
  .pseduoTextElement::before {
    counter-increment: my-sec-counter;
    content: "0" counter(my-sec-counter);
    font-size: 20rem;
    font-weight: 700;
    color: #58ba2733;
    position: absolute;
    top: -15rem;
    left: -6rem;
    z-index: 1;
  }
  .right .pseduoTextElement::before {
    left: auto;
    right: -6rem;
  }
  .liSection {
    margin-bottom: 0.5rem;
  }
  .faqSection {
    margin: 4rem 0 10rem 0;
  }
  .socialIcons {
    margin: 0 auto;
    border-radius: 70%;
    background-color: #efad4e;
    height: 2.2rem;
    width: 2.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .trackEscrowSection {
    font-weight: 600;
    font-size: 1.5rem;
    color: #ffff;
    margin-left: 20;
  }
  .sectionLayOutGreen {
    margin: 0rem 0rem 0rem 0rem !important;
  }
  .contentColumn {
    padding: 2rem;
  }
  .sectionGetTouch {
    padding: 7rem 0 7rem 0;
  }
  .footerSection {
    background-color: #ffff;
    margin: 7rem 0rem 1rem 0rem;
  }
  .paddingDeposit {
    padding-left: 5rem;
  }
  .escrowOffersSection-mr-pd-top {
    padding-top: 15rem;
    margin-left: 3rem;
  }
  .escrowOffersSection-pd-mr-bottom {
    padding-bottom: 5rem;
    margin-left: 3rem;
  }
  .mr-left {
    margin-left: 3rem;
  }
  .contentGap {
    margin-right: -2rem;
  }
  .bankSideImg {
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 41%;
    transform: translate(0, 32%);
  }
  .planSpace {
    padding: 1rem 7rem 4rem;
  }
  .colorForApp {
    color: #1e1e22;
  }
  .contactPanel {
    background-color: #c6954e;
    border-radius: 20px;
    padding: 3rem 6rem;
  }
  @media screen and (max-width: 991px) {
    .order1 {
      order: 1;
    }
    .landingPageSection {
      margin-top: 3rem !important;
    }
    .landingPageItem {
      margin-top: 3rem !important;
    }
    .sectionContainer {
      margin: 9rem 0 0 0 !important;
    }
    .pseduoTextElement::before,
    .right .pseduoTextElement::before {
      top: -8rem;
      font-size: 10rem;
    }
    .escrowOffersSection-mr-pd-top {
      padding-top: 5rem;
    }
    .escrowOffersSection-pd-mr-bottom {
      margin-left: 0;
      padding-left: 3rem;
    }
  }
  @media screen and (max-width: 767px) {
    .stepContainer {
      margin-bottom: 8em;
    }
    .pseduoTextElement::before {
      left: -5rem;
    }
    .right .pseduoTextElement::before {
      right: -2rem;
    }
    .contactPanel {
      padding: 2rem;
    }
    .planSpace {
      padding: 0;
    }
    .contentGap {
      margin: 0;
    }
  }
`;

const LayoutFlow = styled(Layout)`
  .mainHeader.ant-menu-light.ant-menu-root.ant-menu-horizontal {
    background: ${props => props.theme[props.theme.mode].common.white};
    color: ${props => props.theme[props.theme.mode].textColor.primary};
    .ant-menu-item-selected a,
    .ant-menu-item.landingNav a {
      color: ${props =>
        props.theme[props.theme.mode].textColor.primary} !important;
      font-weight: 700;
      :after {
        content: none;
      }
    }
  }
  background-color: ${props =>
    props.theme[props.theme.mode].background.primary};
  width: 85%;
  margin: 2.65vw auto;
  position: relative;
`;

const LayoutGreenFlow = styled(Layout)`
  background-color: ${props => props.theme[props.theme.mode].primary.main};
  &.spacing {
    margin: 5rem 0rem;
  }
  .wrapper {
    width: 85%;
    margin: 2.65vw auto;
  }
`;

const Land = props => {
  const size = useScreenWidth();
  // Main Return
  return (
    <ContentWrap>
      <LayoutFlow>
        <HeaderBar />
      </LayoutFlow>
      <LayoutFlow>
        <Banner size={size} />
      </LayoutFlow>
      <LayoutGreenFlow className="spacing">
        <div className="wrapper">
          <Row
            gutter={[40, 120]}
            type="flex"
            align="middle"
            justify="center"
            className="textCenter sectionLayOutGreen"
          >
            <Col
              xs={24}
              sm={24}
              md={14}
              lg={14}
              xl={14}
              style={{ textAlign: "left" }}
            >
              <h2
                style={{
                  color: "#FFFF",
                  textAlign: "left",
                  width: "100%"
                }}
                className="mr-b-md fontBold"
              >
                Track and manage escrow transactions more quickly, easily and accurately than ever before
              </h2>
              <ul
                className="textLeft"
                style={{ width: "70%", paddingInlineStart: " 20px" }}
              >
                <li
                  style={{
                    fontWeight: "400",
                    fontSize: "1rem",
                    color: "#FFFF",
                    marginBottom: "8px"
                  }}
                >
                  No more notes, calculations and spreadsheets
                </li>
                <li
                  style={{
                    fontWeight: "400",
                    fontSize: "1rem",
                    color: "#FFFF",
                    marginBottom: "40px"
                  }}
                >
                  EscrowTrakker lets you track and manage escrow transactions
                  with one touch ease
                </li>
              </ul>
              {/* <Link
                style={{
                  backgroundColor: "#FFFF",
                  borderRadius: 2,
                  padding: "10px 45px",
                  textDecoration: "none",
                  marginLeft: "24px"
                }}
                className="fontBold"
                to="/signup"
              >
                Get Started
              </Link> */}
            </Col>

            <Col
              className="paddingDeposit"
              xs={24}
              sm={24}
              md={10}
              lg={10}
              xl={10}
            >
              <Row
                type="flex"
                style={{ flexDirection: "column" }}
                gutter={[20, 40]}
              >
                <Col
                  style={{ alignSelf: "flex-end" }}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={20}
                  xl={20}
                >
                  <div
                    style={{
                      backgroundColor: "#C6954E",
                      height: "6rem",
                      padding: "0 2rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      borderRadius: "10px",
                      opacity: "1"
                    }}
                  >
                    <Image width={34} src={purse} alt="Deposit" />
                    &nbsp;&nbsp;
                    <span className="trackEscrowSection">Deposits</span>
                  </div>
                </Col>
                <Col
                  span={20}
                  style={{ alignSelf: "flex-start" }}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={20}
                  xl={20}
                >
                  <div
                    style={{
                      backgroundColor: "#C6954E",
                      height: "6rem",
                      padding: "0 2rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      borderRadius: "10px",
                      opacity: "1"
                    }}
                  >
                    <Image width={34} src={money} alt="Withdraw" />
                    &nbsp;&nbsp;
                    <span className="trackEscrowSection">Withdrawals</span>
                  </div>
                </Col>
                <Col
                  span={20}
                  style={{ alignSelf: "flex-start" }}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={20}
                  xl={20}
                >
                  <div
                    style={{
                      backgroundColor: "#C6954E",
                      height: "6rem",
                      padding: "0 2rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      borderRadius: "10px",
                      opacity: "1"
                    }}
                  >
                    <Image width={34} src={cashBack} alt="Interestdeposit" />
                    &nbsp;&nbsp;
                    <span className="trackEscrowSection">Interest Deposit Transactions</span>
                  </div>
                </Col>
                <Col
                  span={20}
                  style={{ alignSelf: "flex-end" }}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={20}
                  xl={20}
                >
                  <div
                    style={{
                      backgroundColor: "#C6954E",
                      height: "6rem",
                      padding: "0 2rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      borderRadius: "10px",
                      opacity: "1"
                    }}
                  >
                    <Image width={34} src={cashBack1} alt="InterestWithdrawal" />
                    &nbsp;&nbsp;
                    <span className="trackEscrowSection">Interest Withdrawal Transactions</span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </LayoutGreenFlow>
      {/* // Wahts Escrow Offers */}
      <LayoutFlow id="FEATURES">
        <div className="landingPageSection">
          <Row
            className="landingPageItem"
            gutter={[40, 40]}
            type="flex"
            align="middle"
            justify="center"
            id="FEATURES"
          >
            <Col xs={24} sm={24} md={24} lg={16}>
              <h1 style={{ textAlign: "center" }}>
                What&nbsp;
                <span style={{ color: "#58BA27", wordBreak: "normal" }}>
                  Escrow
                </span>
                &nbsp;
                <span style={{ color: "#FF494C", wordBreak: "normal" }}>
                  Trakker
                </span>
                &nbsp;
                <span style={{ wordBreak: "normal" }}>Offers</span>
              </h1>
            </Col>
          </Row>
        </div>
      </LayoutFlow>
      {/* // Wahts Escrow Offers */}
      <LayoutFlow>
        {/* step 01 */}
        <div id="WORKS" className="stepContainer">
          <Row
            className="escrowOffersSection-mr-pd-top"
            type="flex"
            align="middle"
            justify={size >= 991 ? "space-between" : "end"}
          >
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "right", position: "relative" }}
            >
              <div
                className="pseduoTextElement"
                style={{
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <Image
                  src={bankAccounts}
                  alt="Bank Account"
                  style={
                    size >= 991
                      ? { zIndex: "2", position: "relative", width: "50%" }
                      : { zIndex: "2", position: "relative", width: "70%" }
                  }
                />

                <Image
                  src={bankAccountCard}
                  alt="Bank Card"
                  className="bankSideImg"
                  style={
                    size >= 992
                      ? null
                      : { width: "70%", transform: "translate(15%,34%)" }
                  }
                />
              </div>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={size >= 991 ? {} : { marginRight: "auto" }}
            >
              <Sections
                // step="STEP 01"

                heading="Manage Bank Accounts"
                className="fontMedium"
                paragraph="You need to know what’s going on in your accounts. EscrowTrakker puts essential account functions in the palm of your hand:"
              />
              <ul
                style={{
                  width: "90%",
                  color: "#58BA27",
                  paddingInlineStart: 18
                }}
              >
                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    Track up to 200 IOLTA and trust accounts
                  </span>
                </li>
                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    Track tenant and property balances in escrow accounts
                  </span>
                </li>
                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    Maintain balance of firm funds for fees, bank charges and interest
                  </span>
                </li>
                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    Easily reconcile bank accounts
                  </span>
                </li>

                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    Sort by extensive pre-loaded on-board chart of accounts
                  </span>
                </li>
                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    Sort by landlord name, tenant, property and account
                  </span>
                </li>
                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    View, edit, print and e-mail bank account ledgers
                  </span>
                </li>
                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    Receive bank, tenant and property overdraft notifications
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
        {/* //Step 02 */}
        <div className="stepContainer">
          <Row
            type="flex"
            align="middle"
            justify={size >= 991 ? "space-between" : "start"}
          >
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className={
                size < 992 ? "escrowOffersSection-pd-mr-bottom order1" : null
              }
            >
              <Sections
                // step="STEP 02"
                heading="Manage Escrow Transactions"
                paragraph="It only takes seconds to enter transactions. You can quickly and easily:"
              />
              <ul
                style={{
                  width: "90%",
                  color: "#58BA27",
                  paddingInlineStart: 18
                }}
              >
                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    Create withdrawal, deposit, interest deposit and interest withdrawal
                    transactions
                  </span>
                </li>
                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    Select entries from existing lists of tenants, properties,
                    accounts etc.
                  </span>
                </li>
                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    Enter and search by multiple variables
                  </span>
                </li>
                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    Add new landlords, tenants and properties
                  </span>
                </li>
              </ul>
            </Col>
            <Col
              className="right"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "right", position: "relative" }}
            >
              <div
                className="pseduoTextElement"
                style={{
                  textAlign: "right",
                  display: "flex",

                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%"
                }}
              >
                <Image
                  src={addTransaction}
                  style={
                    size >= 991
                      ? { position: "relative", width: "50%", zIndex: "2" }
                      : { position: "relative", width: "70%", zIndex: "2" }
                  }
                  alt="Add Transaction"
                />
                <Image
                  src={addTransactionCard}
                  className="bankSideImg"
                  style={
                    size >= 992
                      ? { width: "56%", transform: "translate(-30%, 15%)" }
                      : { width: "70%", transform: "translate(-25%,34%)" }
                  }
                  alt="Add Transaction Card"
                />
              </div>
            </Col>
          </Row>
        </div>
        {/* Step 03 */}
        <div id="WORKS" className="stepContainer">
          <Row
            className="mr-left"
            type="flex"
            align="middle"
            justify={size >= 991 ? "space-between" : "end"}
          >
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "left", position: "relative" }}
            >
              <div
                className="pseduoTextElement"
                style={{
                  textAlign: "right",
                  display: "flex",

                  alignItems: "center",
                  justifyContent: "flex-start",
                  width: "100%"
                }}
              >
                <Image
                  src={chartMobile}
                  alt="Mobile Chart"
                  style={
                    size >= 991
                      ? { position: "relative", width: "50%", zIndex: "2" }
                      : { position: "relative", width: "70%", zIndex: "2" }
                  }
                />
                <Image
                  src={chartMobileCard}
                  alt="Mobile Chart card"
                  className="bankSideImg"
                  style={
                    size >= 992
                      ? { width: "62%", transform: "translate(25%, 4%)" }
                      : { width: "70%", transform: "translate(25%, 4%)" }
                  }
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={size >= 991 ? {} : { marginRight: "auto" }}
            >
              <Sections
                // step="STEP 03"
                heading="Generate Pie Charts"
                paragraph="Gain timely insight into your financial affairs and perform in-depth data mining at the touch of a button:"
              />

              <ul
                style={{
                  width: "90%",
                  color: "#58BA27",
                  paddingInlineStart: 18
                }}
              >
                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    Sort by bank account, landlord name, tenant, property and account
                  </span>
                </li>
                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    Enter search criteria to generate unlimited financial
                    ledgers
                  </span>
                </li>
                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    Display ledgers in three rotating pie charts and a histogram
                  </span>
                </li>
                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    View, print and email results
                  </span>
                </li>
                {/* <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    World class analytics in real time
                  </span>
                </li> */}
              </ul>
            </Col>
          </Row>
        </div>

        {/* //Step 03 */}

        {/* //step 04 */}

        <div className="stepContainer">
          <Row
            type="flex"
            align="middle"
            justify={size >= 991 ? "space-between" : "start"}
          >
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className={
                size < 992 ? "escrowOffersSection-pd-mr-bottom order1" : null
              }
            >
              <Sections
                // step="STEP 04"
                heading="View & Print Reports"
                paragraph="Get the information you need in the format you want:"
              />

              <ul
                style={{
                  width: "90%",
                  color: "#58BA27",
                  paddingInlineStart: 18
                }}
              >
                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    Generate extensive reports, sorted by bank account and
                    landlord name
                  </span>
                </li>
                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    Generate ABA and state bar compliant three-way
                    reconciliation reports
                  </span>
                </li>
                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    View individual landlord contributions to each escrow account
                  </span>
                </li>
                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    Generate profit and loss reports for individual landlords or
                    entire firm
                  </span>
                </li>
                {/* <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    Generate trial balance – summary reportfor individual lawyer
                    or entire firm
                  </span>
                </li> */}
              </ul>
            </Col>

            <Col
              className=" right"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "left", position: "relative" }}
            >
              <div
                className="pseduoTextElement"
                style={{
                  textAlign: "right",
                  display: "flex",

                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%"
                }}
              >
                <Image
                  src={reports}
                  alt="Reports"
                  style={
                    size >= 991
                      ? { position: "relative", width: "50%", zIndex: "2" }
                      : { position: "relative", width: "70%", zIndex: "2" }
                  }
                />

                <Image
                  src={reportsCard}
                  alt="Report Card"
                  className="bankSideImg"
                  style={
                    size >= 992
                      ? { width: "55%", transform: "translate(-23%, 20%)" }
                      : { width: "70%", transform: "translate(-45%, 20%)" }
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
        {/* //Step 04 */}

        {/* //step 05 */}
        <div id="WORKS" className="stepContainer">
          <Row
            type="flex"
            align="middle"
            justify={size >= 991 ? "space-between" : "end"}
            className="mr-left"
          >
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "left", position: "relative" }}
            >
              <div
                className="pseduoTextElement"
                style={{
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <Image
                  className=""
                  src={searchTransaction}
                  alt="Search Transaction"
                  style={
                    size >= 991
                      ? { position: "relative", width: "50%", zIndex: "2" }
                      : { position: "relative", width: "70%", zIndex: "2" }
                  }
                />

                <Image
                  src={searchTransactionCard}
                  alt="Search Transaction"
                  className="bankSideImg"
                  style={
                    size >= 992
                      ? { width: "31%", transform: "translate(22%, 14%)" }
                      : { width: "38%", transform: "translate(70%, 35%)" }
                  }
                />
              </div>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={size >= 991 ? {} : { marginRight: "auto" }}
            >
              <Sections
                // step="STEP 01"
                heading="Locate Transactions in Seconds"
                paragraph="EscrowTrakker’s powerful search function makes all your transactions easily accessible."
              />
              <ul
                style={{
                  width: "100%",
                  color: "#58BA27",
                  paddingInlineStart: 18
                }}
              >
                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    Quickly pull up current and historical transactions
                  </span>
                </li>
                <li className="liSection li-Option-mr-b">
                  <span className="colorForApp fontMedium">
                    Locate and edit any transaction
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
        {/* //step 05 */}
      </LayoutFlow>
      {/* Second Row */}
      {/* Join now Row */}
      {/* Transparent Simple Pricing Start */}
      <LayoutGreenFlow>
        <Row
          type="flex"
          align="middle"
          justify="center"
          className="textCenter"
          id="PRICING"
        >
          <Col span={24} style={{ textAlign: "center" }}>
            <h1
              style={{
                color: "#FFFF",
                width: "100%",
                paddingTop: "6rem",
                marginBottom: 0
              }}
              className="fontBold contentColumn"
            >
              Transparent, simple pricing
            </h1>
            <h3 className="whiteColor fontItalic font500">
              Try free for 30 days. No commitment. No credit card required
            </h3>

            <Row
              type="flex"
              align="middle"
              justify="center"
              className="planSpace"
            >
              <Col
                lg={6}
                md={20}
                sm={24}
                xs={24}
                className="contentColumn contentGap"
              >
                <PricingOption
                  content={contentBasic}
                  heading="Basic"
                  pricing="$4.99 / Year"
                />
              </Col>
              <Col
                lg={6}
                md={20}
                sm={24}
                xs={24}
                className="contentColumn contentGap"
              >
                <PricingOption
                  content={contentStandard}
                  heading="Standard"
                  pricing="$9.99 / Year"
                />
              </Col>
              <Col
                lg={6}
                md={20}
                sm={24}
                xs={24}
                className="contentColumn contentGap"
              >
                <PricingOption
                  content={contentProfessional}
                  recommended
                  heading="Professional"
                  pricing="$24.99 / Year"
                />
              </Col>
              <Col
                lg={6}
                md={20}
                sm={24}
                xs={24}
                className="contentColumn contentGap"
              >
                <PricingOption
                  content={contentEnterprise}
                  heading="Enterprise"
                  pricing="$69.99 / Year"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </LayoutGreenFlow>
      {/* Transparent Simple Pricing End */}
      {/* wahts escrow offers */}
      <LayoutFlow style={{ margin: "0 auto 0" }}>
        <Row
          id="FAQ"
          className="landingPageSection"
          type="flex"
          align="middle"
          justify="center"
        >
          <Col className="faqSection" xs={24} sm={24} md={24} lg={22} xl={22}>
            <Faq />
          </Col>
        </Row>
      </LayoutFlow>

      {/* //Lets Get in Touch With Escrow Start*/}
      <LayoutGreenFlow className="sectionGetTouch">
        <Row
          gutter={[40, 160]}
          type="flex"
          align="middle"
          justify="center"
          id="SUPPORT"
        >
          <Col
            span={24}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            style={{ textAlign: "center" }}
          >
            <Row gutter={[0, 60]} type="flex" justify="center" align="middle">
              <Col xs={24} sm={24} md={24} lg={24}>
                <h1
                  style={{ color: "#FFFF", width: "100%" }}
                  className="no-mr fontBold"
                >
                  Let's Get In Touch
                </h1>
              </Col>
              <Col xs={22} sm={22} md={18} lg={22}>
                <h3 className="whiteColor fontItalic font500">
                  Feel like contacting us? submit your queries here and we will
                  get back to you as soon as possible
                </h3>
              </Col>

              <Col xs={20} sm={20} md={20} lg={24} xl={24}>
                <Row
                  type="flex"
                  justify="center"
                  align="middle"
                  gutter={[0, 20]}
                  style={{ margin: "auto", float: "none" }}
                >
                  <Col
                    className="contactPanel"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={14}
                    xl={10}
                    style={{ paddingTop: "3rem", paddingBottom: "3rem" }}
                  >
                    <ContactForm size={size} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </LayoutGreenFlow>
      {/* //Lets Get in Touch With Escrow End*/}
      {/* Footer Start */}
      <div className="footerSection">
        <LayoutFlow
          style={{
            marginTop: 0
          }}
        >
          <Row
            gutter={[60, 20]}
            type="flex"
            align="middle"
            justify="center"
            className="textCenter"
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={6}
              xl={6}
              className={size >= 991 ? "textLeft" : "textCenter"}
            >
              {fullFooterLogo()}
            </Col>
            <Col span={24} xs={24} sm={24} md={24} lg={18} xl={18}>
              <Row
                type="flex"
                align="middle"
                gutter={[20, 20]}
                justify={size >= 992 ? "end" : "center"}
              >
                <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.escrowtrakker"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>
                      <Image src={androidDownload} alt="ANDROID" />
                      <img height="1" width="1" style={{display: "none"}} alt="" src="https://px.ads.linkedin.com/collect/?pid=2247842&conversionId=2580618&fmt=gif" />
                    </div>
                  </a>
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                  <a
                    href="https://apps.apple.com/us/app/id1495369177"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>
                      <Image src={appleDownload} alt="IOS" />
                      <img height="1" width="1" style={{display: "none"}} alt="" src="https://px.ads.linkedin.com/collect/?pid=2247842&conversionId=2580618&fmt=gif" />
                    </div>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* //try */}
          <Row
            gutter={[20, 40]}
            type="flex"
            align="middle"
            justify={size >= 991 ? "start" : "space-around"}
            className="textCenter caption"
            style={{ color: "black", marginTop: "2rem", marginBottom: "4rem" }}
          >
            <Col xs={24} md={12} lg={8} style={{ textAlign: "left" }}>
              <p className="fontMedium">
                &copy; Copyright EscrowTrakker, all rights reserved
              </p>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Row type="flex" justify="center">
                <Col span={10}>
                  <a
                    href="https://app.termly.io/document/terms-and-conditions/d24fde6f-9139-47e2-9de7-b587f9e240cb"
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ color: "#1E1E22", textDecoration: "underline" }}
                    className="fontMedium"
                  >
                    Privacy Policy
                  </a>
                </Col>
                <Col span={10}>
                  <a
                    href="https://app.termly.io/document/terms-and-conditions/d24fde6f-9139-47e2-9de7-b587f9e240cb"
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ color: "#1E1E22", textDecoration: "underline" }}
                    className="fontMedium"
                  >
                    Terms & Condition
                  </a>
                </Col>
              </Row>
            </Col>

            <Col xs={24} md={24} lg={8}>
              <Row
                type="flex"
                justify={size >= 991 ? "end" : "center"}
                gutter={10}
              >
                <Col span={4}>
                  <a
                    href="https://www.facebook.com/Escrow-Trakker-Engineering-100216021626881/?modal=admin_todo_tour"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="socialIcons"
                  >
                    <FiFacebook className="whiteColor normal" />
                    <img height="1" width="1" style={{display:"none"}} alt="" src="https://px.ads.linkedin.com/collect/?pid=2247842&conversionId=2580626&fmt=gif" />
                  </a>
                </Col>

                {/* <Col span={4}>
                  <a
                    href="https://www.instagram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="socialIcons"
                  >
                    <FiInstagram className="whiteColor normal" />
                  </a>
                </Col> */}

                <Col span={4}>
                  <a
                    href="https://twitter.com/EscrowTrakker"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="socialIcons"
                  >
                    <FiTwitter className="whiteColor normal" />
                    <img height="1" width="1" style={{display:"none"}} alt="" src="https://px.ads.linkedin.com/collect/?pid=2247842&conversionId=2580626&fmt=gif" />
                  </a>
                </Col>

                <Col span={4}>
                  <a
                    href="https://www.linkedin.com/company/65563708/admin/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="socialIcons"
                  >
                    <FiLinkedin className="whiteColor normal" />
                    <img height="1" width="1" style={{display:"none"}} alt="" src="https://px.ads.linkedin.com/collect/?pid=2247842&conversionId=2580626&fmt=gif" />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* try */}
        </LayoutFlow>
      </div>
      {/* Footer End */}
    </ContentWrap>
  );
};
export default Land;
