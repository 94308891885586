import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import moment from "moment";
import useDepositForm from "../../../appcomponents/EscrowTrakker/forms/useDepositForm";
import BankFieldName from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/BankName";
import OptionsField from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/OptionsField";
import AccountNameField from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/AccountName";
import Field from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/index";
import DatePicker from "../../../components/datepickers/DatePicker";
import JobNameField from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/JobName";
import Image from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/uploadImageWithTitle";
import Switch from "../../../components/switch/index";
import TextArea from "../../../components/input/TextArea";
import Button from "../../../components/button/index";
import Split from "../../../appcomponents/EscrowTrakker/formcontrols/splits";
import { openModal, closeAllModal } from "../../../actions/Modal";
import Job from "./Job";
import { addTransactionRequest } from "../../../actions/transaction/Deposit&WithDrawlTransaction";
import AddAccountName from "./AddAccountName";
import { SendImageReport } from "./index";
import printJS from 'print-js';
import SendImage from "./SendImageDeposit";
import { getSingleBankRequest } from "../../../actions/BankAccount";
const DepositTranscation = props => {
  console.log("PROPS OF DEPOSIT: ", props);
  let { bank, isRedirect, referrerState } = props;
  const [formId, setFormId] = useState(0);
  console.log("bank props: ", props);
  console.log("LOCALSTORAGE: ", localStorage.getItem("propertyName"));
  const [isTrue, setIsTrue] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [ isPayor, setIsPayor ] = useState("");
  const [accountID, setAccountID] = useState("");
  const [accountName, setAccountName] = useState(undefined);
  console.log("Payor NEW NEW: ", isPayor);
  const setLandlordName = (id) => {
    props.getSingleBankRequest(id, (error, data) => {
          if (error) {
            console.log("I AM HERE too for tenant");
          } else {
            console.log("this is dataish for tenant: ", data);  
            localStorage.setItem("landlordName", data?.bank_account?.landlord_name);
          }
        });
  }
  const mainForm = useDepositForm({
    initialValues: {
      bankName: bank ? bank.bankId + "" : localStorage.getItem("bankID") ? localStorage.getItem("bankID") : "",
      tenantName: localStorage.getItem("tenantID") ? localStorage.getItem("tenantID") : "",
      propertyName: localStorage.getItem("propertyID") ? localStorage.getItem("propertyID") : "",
      amount: "",
      accountName: "",
      //jobName: "",
      payor: localStorage.getItem("payorID") ? localStorage.getItem("payorID") : "",
      dateOfTransaction: moment(),
      checkNumber: "",
      cleared: props.settings.is_cleared_default ? props.settings.is_cleared_default === true : "",
      additionalNote: "",
      image: "",
      type: "deposit",
      // ...(props.location.state ? {
      //   ...props.location.state.initialValues,
      //   //checkNumber: props.location.state.initialValues.checkNumber.toString(),
      //   dateOfTransaction: moment(props.location.state.initialValues.dateOfTransaction)
      // } : {})
    },
    //enableReinitialize: true,
    //validationSchema: deposit,
    //apiCall: { addTransactionRequest },
    onSubmit: (values, { ...rest }) => {
      //storeValues(values);
      console.log("values are : ", values);
      props.addTransactionRequest(values, () => {
        //true
        console.log("IN SUBMIT NEW VAL", values);
        rest.resetForm({ 
          values: {
            bankName: values.bankName,
            tenantName: values.tenantName,
            propertyName: "",
            amount: "",
            accountName: "",
            //jobName: "",
            payor: "",
            dateOfTransaction: moment(),
            checkNumber: "",
            cleared: props.settings.is_cleared_default ? props.settings.is_cleared_default === true : "",
            additionalNote: "",
            image: "",
            type: "deposit"
          }
         });
        setFormId(formId + 1);
        setLandlordName(mainForm.values.bankName);
        isRedirect
          ? props.history.push({
              pathname: `/reconcile`,
              //pathname: `/send-transactionimage`,
              //state: props.location.state,
              state: {
                referrer: props.location.pathname,
                referrerState: referrerState
              },
              //   image: localStorage.getItem("imageURL")
              //   //accname: localStorage.getItem("accname")
              // }
              //image: localStorage.getItem("imageFile")
              //format: "pdf"
            })
          : props.history.push({
              pathname: `/bank-account-details/${mainForm.values.bankName}`,
              //pathname: `/send-transactionimage`,
              //state: props.location.state,
              state: {
                referrer: props.location.pathname,
                // referrerState: {
                //   initialValues: values
                // },
              },
              //   image: localStorage.getItem("imageURL")
              //   //accname: localStorage.getItem("accname")
              // }
              //image: localStorage.getItem("imageFile")
              //format: "pdf"
            });
      }
      );
    }
  });
//   const getPayorValue = (id) => {
//     console.log("yemssetter");
//     console.log("yemssetter");
//     mainForm.setFieldValue("payor", id);
//     setIsTrue(true);
//     //setFieldValue();
// }
console.log("ABCER: ", props.settings.currency);
console.log("Initial Values: ", mainForm.values);
console.log("IMAGEER: ", localStorage.getItem("imageFile"));
  console.log("FORM", mainForm, props);
  
  return (
    <React.Fragment>
      <Row type="flex" gutter={[40, 30]}>
        <Col xs={24} sm={12}>
          <BankFieldName
            title="Bank Account Name"
            placeholder="Select Bank Account"
            addBtnText="Add Bank Account"
            initialValue={bank ? bank.bankName : localStorage.getItem("bankName") ? localStorage.getItem("bankName") : null}
            //initialValue={JSON.parse(localStorage.getItem("bankName"))}
            // value={mainForm.values.bankName}
            name="bankName"
            link="/add-bank"
            //onChange={e => mainForm.customHandleChange(e, "bankName")}
            onChange={(id,name) => {mainForm.customHandleChange(id, "bankName");
            localStorage.setItem("bankID", id);
            localStorage.setItem("bankName", name);  
            }}
            //initialValue={localStorage.getItem("bankName")}
            onBlur={e => mainForm.customHandleBlur(e, "bankName")}
          />

          <span className="dangerColor caption textCenter">
            {mainForm.touched["bankName"] && mainForm.errors["bankName"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
          <OptionsField
            title="Tenant Name"
            addBtnText="Add Tenant"
            name="tenantName"
            link="/add-contact"
            contactType="tenant"
            placeholder="Select Tenant"
            //initialValue={JSON.parse(localStorage.getItem("tenantName"))}
            onChange={(id,name) => {
              mainForm.customHandleChange(id, "tenantName");
              mainForm.setFieldValue("payorName", name);
              mainForm.setFieldValue("payor", id);
              localStorage.setItem("payorName", name);             
              localStorage.setItem("tenantID", id);
              localStorage.setItem("tenantName", name);
            }}
            initialValue={localStorage.getItem("tenantName") ? localStorage.getItem("tenantName") : null}
            onBlur={e => mainForm.customHandleBlur(e, "tenantName")}

          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["tenantName"] && mainForm.errors["tenantName"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
              <JobNameField
              key={formId}
                title="Property Name"
                addBtnText="Add Property"
                placeholder="Select Property"
                //initialValue={JSON.parse(localStorage.getItem("propertyName"))}
                addBtnClick={() =>
                  props.openModal(null, <Job />, null, null, {
                    footer: null
                  })
                }
                name="propertyName"
                onChange={(id,name) => {mainForm.customHandleChange(id, "propertyName");
                localStorage.setItem("propertyID", id);
                localStorage.setItem("propertyName", name);}}
                initialValue={localStorage.getItem("propertyName") ? localStorage.getItem("propertyName") : null}
                onBlur={e => mainForm.customHandleBlur(e, "propertyName")}
              />
              <span className="dangerColor caption textCenter">
                {mainForm.touched["propertyName"] && mainForm.errors["propertyName"]}
              </span>
        </Col>

        <Col xs={24} sm={12}>
          <Field
          key={formId}
            title="Amount"
            placeholder={`${props.settings.currency || "$"}0.00`}
            value={mainForm.values.amount}
            //onChange={mainForm.handleTotalChange}
            //onBlur={mainForm.handleBlur}
            name="amount"
            onChange={e => mainForm.customHandleChange(e, "amount")}
            onBlur={e => mainForm.customHandleBlur(e, "amount")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["amount"] && mainForm.errors["amount"]}
          </span>
        </Col>

        <Col xs={24}>
          <Split
            splits={mainForm.values.splits}
            amount={mainForm.values.amount}
            // accountID={mainForm.values.accountName ? mainForm.values.accountName : console.log("abc") }
            // accountName={localStorage.getItem("accname") ? localStorage.getItem("accname") : console.log("abc")}
            remainingAmount={
              (parseFloat(mainForm.values.amount) || 0) - mainForm.splitTotal
            }
            errors={mainForm.errors.splits}
            touched={mainForm.touched.splits}
            onSplitChange={mainForm.handleSplitChange}
            onSplitBlur={mainForm.handleSplitBlur}
            onAddSplit={mainForm.addSplit}
            onClearSplits={() => {
              mainForm.clearSplits({ accountName: accountID, accountNameString: accountName });

              // To await new state
              // setTimeout(() => {
              //   console.log("FORM", mainForm.values)
              //   mainForm.setValues({
              //   ...mainForm.values,
              //   accountName: accountID,
              //   accountNameString: accountName
              //   });
              // }, 1000)
            }}
            accountType="income"
          />
          <div className="dangerColor caption textCenter">
            {mainForm.touched["is_split"] && mainForm.errors["noOfSplits"]}
          </div>
          <div className="dangerColor caption textCenter">
            {mainForm.touched["is_split"] && mainForm.errors["remainingAmount"]}
          </div>
        </Col>

        {!(mainForm.values.splits && mainForm.values.splits.length) ? (
          <React.Fragment>
            <Col xs={24} sm={12}>
              <AccountNameField
                key={formId}
                title="Account Name"
                addBtnText="Add Account Name"
                accountType="income"
                placeholder="Select Account Name"
                accValue={[mainForm.values.accountNameString, mainForm.values.accountName]}
                //accValue={ localStorage.getItem("accname") ? localStorage.getItem("accname") : console.log("abc")}
                //accountID={mainForm.values.accountName ? mainForm.values.accountName : console.log("abc") }
                onMouseDown={e => e.preventDefault()}
                addBtnClick={() =>
                  props.openModal(
                    null,
                    <AddAccountName accType="income" />,
                    null,
                    null,
                    { footer: null }
                  )
                }
                name="accountName"
                onChange={(e, v, name) => { 
                  mainForm.customHandleChange(e, "accountName");
                  mainForm.customHandleChange(name, "accountNameString");
                  console.log("ABVD", name); 
                  localStorage.setItem("accname", name);
                  setAccountName(name);
                  setAccountID(e);
                }}
                // mainForm.customHandleChange(name, "accname");
                // console.log("name is this: ", name);
                // //mainForm.customHandleChange(name, "accountNameer");
                // //localStorage.setItem("accname", name);
                onBlur={e => mainForm.customHandleBlur(e, "accountName")}
              />
              <span className="dangerColor caption textCenter">
                {mainForm.touched["accountName"] &&
                  mainForm.errors["accountName"]}
              </span>
            </Col>

            {/* <Col xs={24} sm={12}>
              <JobNameField
              key={formId}
                title="Property Name"
                addBtnText="Add Property Name"
                placeholder="Select Property"
                addBtnClick={() =>
                  props.openModal(null, <Job />, null, null, {
                    footer: null
                  })
                }
                name="propertyName"
                onChange={e => mainForm.customHandleChange(e, "propertyName")}
                onBlur={e => mainForm.customHandleBlur(e, "propertyName")}
              />
              <span className="dangerColor caption textCenter">
                {mainForm.touched["propertyName"] && mainForm.errors["propertyName"]}
              </span>
            </Col> */}
          </React.Fragment>
        ) : null}

        <Col xs={24} sm={12}>
          <OptionsField
          key={formId}
            title="Payor"
            addBtnText="Add Payors"
            link="/add-contact"
            contactType="payor"
            placeholder="Select Payor"
            propertyValue={mainForm.values.payorName}
            name="payor"
            onChange={(id, name) => {
              mainForm.customHandleChange(id, "payor");
              mainForm.customHandleChange(name, "payorName");
              localStorage.setItem("payorID", id);
              localStorage.setItem("payorName", name);
            }}
            initialValue={localStorage.getItem("payorName") ? localStorage.getItem("payorName") : null}
            //Value={mainForm.values.payor ? mainForm.values.payor : null}
            onBlur={e => mainForm.customHandleBlur(e, "payor")}
          />
          {/* //isPayor ? mainForm.customHandleChange(isPayor, "payor") :  */}
          <span className="dangerColor caption textCenter">
            {mainForm.touched["payor"] && mainForm.errors["payor"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
          <Field
          key={formId}
            title="Date Of Transaction"
            customInput={DatePicker}
            value={mainForm.values.dateOfTransaction}
            placeholder="MM/DD/YY"
            format="MM-DD-YY"
            name="dateOfTransaction"
            onChange={e => mainForm.customHandleChange(e, "dateOfTransaction")}
            onBlur={e => mainForm.customHandleBlur(e, "dateOfTransaction")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["dateOfTransaction"] &&
              mainForm.errors["dateOfTransaction"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
          <Field
          key={formId}
            title="Check Number"
            placeholder="Check Number"
            name="checkNumber"
            value={mainForm.values.checkNumber}
            //checkValue={props?.location?.state?.initialValues?.checkNumber ? props.location.state.initialValues.checkNumber : null}
            onChange={e => mainForm.customHandleChange(e, "checkNumber")}
            onBlur={e => mainForm.customHandleBlur(e, "checkNumber")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["checkNumber"] && mainForm.errors["checkNumber"]}
          </span>
        </Col>

        {/* <Col xs={24} sm={12}>
          <Field
          key={formId}
            title="Cleared"
            span
            noHeadingMargin
            customInput={Switch}
            name="cleared"
            checked={mainForm.values.cleared}
            onChange={e => mainForm.customHandleChange(e, "cleared")}
            onBlur={e => mainForm.customHandleBlur(e, "cleared")}
          />

          <span className="dangerColor caption textCenter">
            {mainForm.touched["cleared"] && mainForm.errors["cleared"]}
          </span>
        </Col> */}

        <Col xs={24} sm={12}>
          <Field
          key={formId}
            title="Additional Notes"
            //name="additionalNotex"
            placeholder="Note"
            style={{ height: "8.8rem" }}
            customInput={TextArea}
            name="additionalNote"
            onChange={e => mainForm.customHandleChange(e, "additionalNote")}
            onBlur={e => mainForm.customHandleBlur(e, "additionalNote")}
          />

          <span className="dangerColor caption textCenter">
            {mainForm.touched["additionalNote"] &&
              mainForm.errors["additionalNote"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
        <Row type="flex" gutter={[10, 10]} justify="center">
          <Col span={24}>
          <Image
          key={formId}
            title="Image"
            btnText="Upload Image"
            listType="picture-card"
            //value={mainForm.values.image} maybe usable
            // style={{ height: "9rem" }}
            className="avatar-uploader"
            name="image"
            onChange={e => {
              mainForm.customHandleChange(e.originFileObj, "image");
            console.log("img: ", typeof e.originFileObj)}}
            //  onBlur={e => mainForm.customHandleBlur(e, "image")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["image"] && mainForm.errors["image"]}
          </span>
          </Col>
          <Col span={8}>
                    <Button
                    block
                      disabled={!mainForm.values.image}
                      //loading={props.loading}
                      onClick={ () =>
                        props.openModal(
                          null,
                          <SendImage initialState={{image: localStorage.getItem("imageURL")}} isinternal={true}/>,
                          null,
                          null,
                          { footer: null }
                        )
                      //   (e) => {e.preventDefault();
                      //   //<SendImageReport image={localStorage.getItem("imageFile")}/>
                      //   const { dateOfTransaction, ...values } = mainForm.values;
                      //   values.dateOfTransaction = moment(dateOfTransaction).format("YYYY-MM-DD HH:mm:ss");
                      //    props.history.push({
                      //     //pathname: `/reports/send-report/${props.match.params.id}`,
                      //     pathname: `/send-transactionimage`,
                      //     //state: props.location.state,
                      //     state: {
                      //       referrer: props.location.pathname,
                      //       referrerState: {
                      //         initialValues: values
                      //       },
                      //       image: localStorage.getItem("imageURL")
                      //       //accname: localStorage.getItem("accname")
                      //     }
                      //     //image: localStorage.getItem("imageFile")
                      //     //format: "pdf"
                      //     })
                      // }
                    }
                      >
                      Export Image
                    </Button>
                  </Col>
                  <Col span={8}>
                    <Button
                    block
                    disabled={!mainForm.values.image}  
                      //loading={props.loading}
                      onClick={(e)=>{e.preventDefault(); printJS(localStorage.getItem("imageURL"), "image");}}
                      >
                        Print Image
                    </Button>
                  </Col>
        </Row>
        </Col>

        <Col xs={24} sm={12}>
          <Field
          key={formId}
            title="Cleared"
            span
            noHeadingMargin
            customInput={Switch}
            name="cleared"
            checked={mainForm.values.cleared}
            onChange={e => mainForm.customHandleChange(e, "cleared")}
            onBlur={e => mainForm.customHandleBlur(e, "cleared")}
          />

          <span className="dangerColor caption textCenter">
            {mainForm.touched["cleared"] && mainForm.errors["cleared"]}
          </span>
        </Col>

        <Col span={24}>
          <Row type="flex" justify="center" align="middle">
            <Col xl={8} lg={10} md={14} sm={18} xs={20}>
              <Button loading={props.loading} onClick={mainForm.handleSubmit} block className="">
                Record
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};
const mapStateToProps = state => ({loading: state.transaction.addTransactionLoading, settings: state.settings.settings});

export default withRouter(
  connect(mapStateToProps, { addTransactionRequest, openModal, closeAllModal, getSingleBankRequest })(
    DepositTranscation
  )
);
