import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Select from "../../../../components/input/Select";
import Option from "../../../../components/input/SelectOptions";
import BankName from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/BankName";
import JobName from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/JobName";
import OptionsField from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/OptionsField";
import MonthPicker from "../../../../appcomponents/EscrowTrakker/detailbanks/FiltersTransaction";
import DatePicker from "../../../../components/datepickers/DatePicker";
//import Select from "../../../../components/input/Select";
//import Option from "../../../../components/input/SelectOptions";
import useBankAccountLedgerForm from "../../forms/reports/useBankAccountLedgerForm";
import { connect } from "react-redux";
import RootForm from "../../../../components/form/index";
import Button from "../../../../components/button";
import { changePassword } from "../../../../configurations/Schemas/index";
import { Row, Col } from "antd";
import { changePasswordRequest } from "../../../../actions/App";
import { bankAccountLedgerRequest } from "../../../../actions/Reports";
import { closeAllModal } from "../../../../actions/Modal";

import moment from "moment";

const BankAccountLedger = props => {
  const [yearPickerOpen, setYearPickerOpen] = useState(false);
  const [jobValue, setJobValue] = useState(props.initialValues ? props.initialValues.property_name : undefined);
  const [yearVal, setYearVal ] = useState("");
  const mainForm = useBankAccountLedgerForm({
    initialValues: props.initialValues ? {
      ...props.initialValues,
      ...(props.initialValues.date_type === "custom" && {
        start_date: moment(new Date(props.initialValues.start_year, props.initialValues.start_month - 1, props.initialValues.start_day)),
        end_date: moment(new Date(props.initialValues.end_year, props.initialValues.end_month - 1, props.initialValues.end_day))
      })
    } : {
      bank_account_id: "",
      tenant_id: "",
      property_id: "",
      date_type: "monthly",
      month: moment().month() + 1,
      year: moment().year()
    },

    onSubmit: values => props.bankAccountLedgerRequest({ ...values, format: props.format }, report => {
      props.closeAllModal();
      console.log("form values: ", values);
      let { start_date, end_date, ...formValues } = values;
      props.history.push({ pathname: "/reports/preview-bank-account-ledger", state: { 
          referrer: "/reports", 
          referrerState: {
            ...props.location.state,
            bankAccountLedgerValues: {
              formValues,
              //: values,
              format: props.format
            }
          },
          report, 
          format: props.format 
        } 
      })
    })
  })

  console.log("FORM", mainForm);
  console.log("asdassda: ", moment().year());
  console.log("BANK ID: ", mainForm.values.bank_account_id);
  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      className="textCenter"
      gutter={[0, 30]}
    >
      <Col span={24} style={{ padding: 0 }}>
        <h4 className="mr-b mr-t-md" style={{ marginBottom: "2.5rem" }}>
          Bank Account Ledger
        </h4>
      </Col>

      <Col xs={24} sm={20} md={22} lg={16}>
        <Row type="flex" justify="center">
          <Col span={24} style={{ textAlign: "left" }}>
            <BankName
            className="inputField"
            name="bank_account_id"
            placeholder="All Bank Accounts"
            withoutAddButton
            hideDeleteBtn
            withAllOption
            isReport={true}
            initialValue={mainForm.values.bank_name}
            onChange={(id, name) => {
              mainForm.customHandleChange(id, "bank_account_id");
              mainForm.customHandleChange(name, "bank_name");
              mainForm.setFieldValue("tenant_name", "All Tenants");
              mainForm.setFieldValue("property_name", "All Properties");
              setJobValue("All Properties");
            }}
            onBlur={e => mainForm.customHandleBlur(e, "bank_account_id")}
            />
            {mainForm.touched["bank_account_id"] && mainForm.errors["bank_account_id"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["bank_account_id"] && mainForm.errors["bank_account_id"]}
            </div> : null}
          </Col>

          <Col span={24} style={{ textAlign: "left" }}>
            <OptionsField
              className="inputField"
              //title="Lawyer Name"
              //addBtnText="Add Lawyer"
              name="lawyer_id"
              //link="/add-contact"
              tenantValue={mainForm.values.tenant_name}
              contactType="tenant"
              placeholder="All Tenants"
              withoutAddButton
              withClearOption
              id={mainForm.values.bank_account_id}
              isBankACC={!!mainForm.values.bank_account_id}
              isCharts={true}
              isEmail={!!mainForm.values.bank_account_id}
              initialValue={mainForm.values.lawyer_name}
              onChange={(id, name) => {
                mainForm.customHandleChange(id, "tenant_id");
                mainForm.customHandleChange(name, "tenant_name");
                mainForm.setFieldValue("date_type", "alldata");
                mainForm.setFieldValue("year", "") ;
                mainForm.setFieldValue("start_date", "");
                mainForm.setFieldValue("start_day", new Date().getDate().toString());
                mainForm.setFieldValue("start_month", ((new Date().getMonth()+1).toString()));
                mainForm.setFieldValue("start_year", (((new Date().getFullYear())-50).toString()));
                mainForm.setFieldValue("end_date", "");
                mainForm.setFieldValue("end_day", new Date().getDate().toString());
                mainForm.setFieldValue("end_month", ((new Date().getMonth()+1).toString()));
                mainForm.setFieldValue("end_year", (((new Date().getFullYear())+50).toString())); 
              }}
              onBlur={e => mainForm.customHandleBlur(e, "lawyer_id")}
            />
            {mainForm.touched["lawyer_id"] && mainForm.errors["lawyer_id"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["lawyer_id"] && mainForm.errors["lawyer_id"]}
            </div> : null}
          </Col>

          <Col span={24} style={{ textAlign: "left" }}>
            <JobName
            className="inputField"
            name="job_id"
            jobValue={jobValue}
            withJobValue
            placeholder="All Properties"
            withoutAddButton
            hideDeleteBtn
            withClearOption
            id={mainForm.values.bank_account_id}
            isBankACC={!!mainForm.values.bank_account_id}
            isCharts={true}
            //withAllOption={mainForm.values.report_type !== "detail"}
            onChange={(id, name) => {
              mainForm.customHandleChange(id, "property_id");
              mainForm.customHandleChange(name, "property_name");
              setJobValue(name || id);
            }}
            onBlur={e => mainForm.customHandleBlur(e, "job_id")}
            />
            {mainForm.touched["job_id"] && mainForm.errors["job_id"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["job_id"] && mainForm.errors["job_id"]}
            </div> : null}
          </Col>

          <Col span={24} style={{ textAlign: "left" }}>
            <Select
            className="inputField"
            name="date_type"
            placeholder="Date Type"
            value={mainForm.values.date_type}
            onChange={e => { 
              console.log("EEEEE: ", e);
              mainForm.onChangeDateType(e, "date_type");
              e==="alldata" ? mainForm.setFieldValue("year", "") : console.log("yes it will work");
              e==="alldata" ? mainForm.setFieldValue("start_date", "") : console.log("yes date1");
              e==="alldata" ? mainForm.setFieldValue("start_day", new Date().getDate().toString()) : console.log("yes date1");
              e==="alldata" ? mainForm.setFieldValue("start_month", ((new Date().getMonth()+1).toString())) : console.log("yes date1");
              e==="alldata" ? mainForm.setFieldValue("start_year", (((new Date().getFullYear())-50).toString())) : console.log("yes date1");
              e==="alldata" ? mainForm.setFieldValue("end_date", "") : console.log("yes date2");
              e==="alldata" ? mainForm.setFieldValue("end_day", new Date().getDate().toString()) : console.log("yes date2");
              e==="alldata" ? mainForm.setFieldValue("end_month", ((new Date().getMonth()+1).toString())) : console.log("yes date2");
              e==="alldata" ? mainForm.setFieldValue("end_year", (((new Date().getFullYear())+50).toString())) : console.log("yes date2");
              //e==="alldata" ? mainForm.setFieldValue("date_type", "custom") : console.log("yes date2");
            }}
            onBlur={e => mainForm.customHandleBlur(e, "date_type")}
            style={{ width: "100%" }}
            >
              <Option value="monthly">Monthly</Option>
              <Option value="yearly">Yearly</Option>
              <Option value="quaterly">Quarterly</Option>
              <Option value="custom">Custom</Option>
              <Option value="alldata">All Data</Option>
            </Select>
            {mainForm.touched["date_type"] && mainForm.errors["date_type"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["date_type"] && mainForm.errors["date_type"]}
            </div> : null}
          </Col>

          {mainForm.values.date_type === "monthly" && 
          <Col span={24} style={{ textAlign: "left" }}>
            <MonthPicker
            singleMonth
            outlined
            className="inputField"
            name="month"
            value={mainForm.values.month}
            placeholder="Month"
            onChange={e => mainForm.customHandleChange(e, "month")}
            onBlur={e => mainForm.customHandleBlur(e, "month")}
            />
            {mainForm.touched["month"] && mainForm.errors["month"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["month"] && mainForm.errors["month"]}
            </div> : null}
          </Col>}

          {(mainForm.values.date_type === "yearly" || mainForm.values.date_type === "monthly" || mainForm.values.date_type === "quaterly") &&
          <Col span={24} style={{ textAlign: "left" }}>
            <DatePicker
            allowClear={false}
            open={yearPickerOpen}
            onOpenChange={open => setYearPickerOpen(open)}
            modalField
            className="inputField"
            name="year"
            value={mainForm.values.year ? moment().year(mainForm.values.year) : undefined}
            placeholder="Month"
            //onChange={e => mainForm.customHandleChange(e, "year")}
            onFocus={() => setYearPickerOpen(true)}
            onBlur={e => {
              mainForm.customHandleBlur(e, "year");
              //setYearPickerOpen(false);
            }}
            style={{ width: "100%" }}
            //picker="year"
            format="YYYY"
            mode="year"
            placeholder="Year"
            onPanelChange={e => {
              if(!mainForm.values.date_type === "quaterly") 
                mainForm.customHandleChange(e.year(), "year")
              else
                mainForm.onChangeQuarterYear(e, "year")

              setYearPickerOpen(false);
            }}
            />
            {mainForm.touched["year"] && mainForm.errors["year"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["year"] && mainForm.errors["year"]}
            </div> : null}
          </Col>}

          {mainForm.values.date_type === "quaterly" && 
          <Col span={24} style={{ textAlign: "left" }}>
            <Select
            className="inputField"
            name="quarter"
            placeholder="Quarter"
            value={mainForm.values.quarter}
            onChange={e => mainForm.onChangeQuarter(e, "quarter")}
            onBlur={e => mainForm.customHandleBlur(e, "quarter")}
            style={{ width: "100%" }}
            >
              <Option value="q1">Q1</Option>
              <Option value="q2">Q2</Option>
              <Option value="q3">Q3</Option>
              <Option value="q4">Q4</Option>
            </Select>
            {mainForm.touched["quarter"] && mainForm.errors["quarter"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["quarter"] && mainForm.errors["quarter"]}
            </div> : null}
          </Col>}

          {mainForm.values.date_type === "custom" && (
          <React.Fragment>
            <Col span={24} style={{ textAlign: "left" }}>
            <DatePicker
            allowClear={false}
            //open={yearPickerOpen}
            //onOpenChange={open => setYearPickerOpen(open)}
            modalField
            className="inputField"
            name="start_date"
            value={mainForm.values.start_date}
            //onChange={e => mainForm.customHandleChange(e, "year")}
            //onFocus={() => setYearPickerOpen(true)}
            onBlur={e => {
              mainForm.customHandleBlur(e, "start_date");
              //setYearPickerOpen(false);
            }}
            style={{ width: "100%" }}
            //picker="year"
            format="MM-DD-YYYY"
            //mode="year"
            placeholder="Start Date"
            onChange={e => {
              // if(!mainForm.values.date_type === "quaterly") 
              //   mainForm.customHandleChange(e.year(), "year")
              // else
              //   mainForm.onChangeQuarterYear(e, "year")
              mainForm.onStartDateChange(e, "start_date")

              //setYearPickerOpen(false);
            }}
            />
            {mainForm.touched["start_date"] && mainForm.errors["start_date"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["start_date"] && mainForm.errors["start_date"]}
            </div> : null}
            </Col>

            <Col span={24} style={{ textAlign: "left" }}>
            <DatePicker
            allowClear={false}
            //open={yearPickerOpen}
            //onOpenChange={open => setYearPickerOpen(open)}
            modalField
            className="inputField"
            name="end_date"
            value={mainForm.values.end_date}
            //onChange={e => mainForm.customHandleChange(e, "year")}
            //onFocus={() => setYearPickerOpen(true)}
            onBlur={e => {
              mainForm.customHandleBlur(e, "end_date");
              //setYearPickerOpen(false);
            }}
            style={{ width: "100%" }}
            //picker="year"
            format="MM-DD-YYYY"
            //mode="year"
            placeholder="End Date"
            onChange={e => {
              // if(!mainForm.values.date_type === "quaterly") 
              //   mainForm.customHandleChange(e.year(), "year")
              // else
              //   mainForm.onChangeQuarterYear(e, "year")
              mainForm.onEndDateChange(e, "end_date")

              //setYearPickerOpen(false);
            }}
            />
            {mainForm.touched["end_date"] && mainForm.errors["end_date"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["end_date"] && mainForm.errors["end_date"]}
            </div> : null}
            </Col>
          </React.Fragment>
          )
          }

          <Col span={16} className="mr-t-md">
            <Button loading={props.reports.bankAccountLedgerLoading} block onClick={mainForm.handleSubmit}>Preview Report</Button>
          </Col>

          <Col span={16} className="mr-t">
            <Button block outlined onClick={props.closeAllModal}>Cancel</Button>
          </Col>
          
        </Row>
          {/* // controls={[
          //   {
          //     span: 24,
          //     className: "inputField",
          //     name: "bankAccount",
          //     //value: this.state.bankAccount,
          //     placeholder: "All Bank Accounts",
          //     props: {
          //       withoutAddButton: true,
          //       hideDeleteBtn: true
          //     },
          //     Type: AccountName
          //   },
          //   {
          //     span: 24,
          //     className: "inputField",
          //     name: "new_password",
          //     value: this.state.new_password,
          //     placeholder: "New Password",
          //     Type: Select
          //   },
          //   {
          //     span: 24,
          //     className: "inputField",
          //     name: "confirm_password",
          //     value: this.state.confirm_password,
          //     placeholder: "Confirm Password",
          //     Type: Select
          //   } */}
      </Col>
    </Row>
  );
};

export default withRouter(connect(state => ({ reports: state.Reports }), { bankAccountLedgerRequest, closeAllModal })(BankAccountLedger));
