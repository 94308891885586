import React, { Fragment } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Input from "../../../../components/input/index";
import Tabs from "../../../../components/tabs";
import RootForm from "../../../../components/form/index";
import { addBank } from "../../../../configurations/Schemas/index";
import DatePicker from "../../../../components/datepickers/DatePicker";
import TextArea from "../../../../components/input/TextArea";
import CardWrapper from "../../../../components/card";
import HeaderWrapper from "../../../../components/header/index";
import { Row, Col } from "antd";
import moment from "moment";
import {
  addBankAccountRequest,
  updateBankAccountRequest,
  getBankAccountsRequest
} from "../../../../actions/BankAccount";
import { getUserSettingRequest } from "../../../../actions/Settings";

import Deposit from "./Deposit";
import Withdraw from "./Withdraw";

import "../../../auth/auth.css";
let current_date = moment();

class BankAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "deposit",
      bank: props.location.state && props.location.state.bank ? props.location.state.bank : null
      // law_firm_name: "",
      // bank_account_name: "",
      // bank_name: "",
      // balance: "",
      // additional_note: "",
      // // current_date,
      // edit: this.props.match.params.id,
      // ...props.location.state,
      // open_date: props.location.state
      //   ? moment(props.location.state.open_date)
      //   : current_date
    };
  }
  //will uncomment when implemented
  componentDidMount() {
    // let { edit } = this.state;
    // if (!edit) {
    //   this.props.getBankAccountsRequest();
    // }
    this.props.getUserSettingRequest();
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps !== this.props) {
  //     if (this.props.AddBankAccount.bankLists) {
  //       if (this.props.AddBankAccount.bankLists.bank_accounts.length) {

  //         let {
  //           law_firm_name,
  //           bank_account_name,
  //           bank_name,
  //           open_date
  //         } = this.props.AddBankAccount.bankLists.bank_accounts[0];
  //         this.setState({
  //           law_firm_name,
  //           bank_account_name,
  //           bank_name,
  //           open_date: moment(open_date)
  //         });
  //       }
  //     }
  //   }
  // }
  //will uncomment when implemented

  handleChanged = e => {
    const { name, value } = e.target;
    console.log("value", value);
    this.setState({
      [name]: value
    });
  };

  render() {
    let {
      law_firm_name,
      bank_account_name,
      bank_name,
      balance,
      open_date,
      additional_note,
      edit
    } = this.state;

    let { edit: extracted, ...initialValues } = this.state;
    // console.log(
    //   "Bank Account received: ",
    //   this.props.AddBankAccount.bankLists
    //     ? this.props.AddBankAccount.bankLists.bank_accounts
    //       ? true
    //       : false
    //     : false
    // );
    return (
      <Row type="flex" justify="center" align="middle" gutter={[0, 40]}>
        <Col span={24}>
          <HeaderWrapper
          middleAlign={[
            <Tabs
              tabWrap={{
                onChange: e => this.setState({ tab: e }),
                activeKey: this.state.tab,
                className: "alternateColors"
              }}
              tabItem={[
                {
                  tab: "Deposit",
                  key: "deposit"
                },
                {
                  tab: "Withdrawal",
                  key: "withdrawal",
                  // attr: {
                  //   className: "secondary"
                  // }
                },
              ]}
            />
          ]}
           externalscreen={1} 
           />
        </Col>
        <Col xs={24} md={22} lg={12}>
          {/* <CardWrapper> */}
          {this.state.tab === "deposit" ? (
            <Deposit bank={this.state.bank} />
          ) : (
            <Withdraw bank={this.state.bank}/>
          )}
          {/* </CardWrapper> */}
        </Col>
      </Row>
    );
  }
}

export default withRouter(
  connect(
    storeState => ({
      AddBankAccount: storeState.AddBankAccount
    }),
    {
      // getBankAccountsRequest
      getUserSettingRequest
    }
  )(BankAccount)
);
