import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Input from "../../../components/input/index";
import RootForm from "../../../components/form/index";
import { jobSchema } from "../../../configurations/Schemas/index";
import CardWrapper from "../../../components/card";
import HeaderWrapper from "../../../components/header/index";
import { Row, Col } from "antd";
//import { addAccountRequest, editAccountRequest } from "../../../actions/FieldOptions";
import { addJobsRequest, editJobsRequest } from "../../../actions/FieldOptions";
import { closeAllModal } from "../../../actions/Modal";

import { useState } from "react";
//import { connect } from "react-redux";
//import { Row, Col } from "antd";
import Form from "../../../components/form";
//import Input from "../../../components/input";
import { addAccount } from "../../../configurations/Schemas";
import { addAccountRequest, editAccountRequest } from "../../../actions/FieldOptions";
//import { closeAllModal } from "../../../actions/Modal";
class Job extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      property_name: this.props.initialValues ? this.props.initialValues.property_name : "",
      address: this.props.initialValues ? this.props.initialValues.address : "",
      city: this.props.initialValues ? this.props.initialValues.city : "",
      state: this.props.initialValues ? this.props.initialValues.state : "",
      zip: this.props.initialValues ? this.props.initialValues.zip : "",
      //property_name: ""
    };
  }

  handleChanged = e => {
    const { name, value } = e.target;
    console.log("value", value);
    this.setState({
      [name]: value
    });
  };
  
  render() {
    console.log("storeState", this.props.initialValues);
    return (
      <Row type="flex" justify="center" align="middle">
        <Col xs={24} sm={20} md={22} lg={18}>
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="textCenter"
          >
            <Col xs={24} sm={20} md={22} lg={24}>
            <h4 className="mr-b mr-t-md">{this.props.initialValues ? "Edit Property" : "Add new Property"}</h4>
            {/* <h4 className="mr-b mr-t-md">Add new Property</h4> */}
              <RootForm
                schemaName={jobSchema}
                initialValues={this.state}
                apiCall={this.props.initialValues ? editJobsRequest : addJobsRequest}
                //apiCall={addJobsRequest}
                loading={this.props.Jobs.jobLoading}
                resetAfterSubmit
                cb={this.props.closeAllModal}
                extras={this.props.initialValues ? this.props.initialValues.property_id : undefined}
                //   cb={() =>
                //     this.props.history.push(
                //       this.props.location.state
                //         ? this.props.location.state.referrer
                //         : "/"
                //     )
                //   }
              //   loading={props.initialValues ? props.FieldOption.editAccNameLoading : props.FieldOption.addAccNameLoading}
              // resetAfterSubmit
              // cb={props.closeAllModal}
              // extras={props.initialValues ? props.initialValues.id : null}
                controls={[
                  {
                    span: 24,
                    name: "property_name",
                    value: this.state.property_name,
                    handleChanged: this.handleChanged,
                    placeholder: "Property Name",
                    Type: Input
                  },
                  {
                    span: 24,
                    name: "address",
                    value: this.state.address,
                    handleChanged: this.handleChanged,
                    placeholder: "Address",
                    Type: Input
                  },
                  {
                    span: 24,
                    name: "city",
                    value: this.state.city,
                    handleChanged: this.handleChanged,
                    placeholder: "City",
                    Type: Input
                  },
                  {
                    span: 24,
                    name: "state",
                    value: this.state.state,
                    handleChanged: this.handleChanged,
                    placeholder: "State",
                    Type: Input
                  },
                  {
                    span: 24,
                    name: "zip",
                    value: this.state.zip,
                    handleChanged: this.handleChanged,
                    placeholder: "Zip",
                    Type: Input
                  }
                ]}
                submitButton={{
                  span: 12,
                  buttonText: "Save"
                  // className: "mr-t-md"
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

// export default withRouter(
//   connect(
//     storeState => {
//       Job: storeState.FieldOption;
//     },
//     { addJobsRequest }
//   )
// );

export default withRouter(
  connect(storeState => ({ Jobs: storeState.FieldOption }), {
    addJobsRequest,
    editJobsRequest,
    closeAllModal
  })(Job)
);
