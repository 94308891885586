import React, { useState, useEffect, useRef, createRef } from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import FreeTrial from "../../appcomponents/EscrowTrakker/subscription/cards/FreeTrialCrad";
import UpgradePlans from "../../appcomponents/EscrowTrakker/subscription/cards/UpgradePlansCard";
import Card from "../../components/card/index";
import Carousel from "../../components/carousel";
import Icon from "../../components/Icon";
import StripeForm from "../../appcomponents/EscrowTrakker/subscription/cards/StripeForm";
import { getSubscriptionStatus } from "../../actions/Subscription";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  //"pk_test_S2E6uXnKIokjMtcVZB82irPC00sSP3jyqF"
  //"sk_test_WZbwbodkjvkZOS8M52bc2m5q00BtYnMgOa"
  //"pk_test_AouDxSs1rCcVw5mlisWA35Jr00aLXQPbeJ"
  //"pk_live_BqHo55uMykIvciyZEIWcbFwr006ss84EKA"
  //"pk_test_51ItAuSGOpjLv6D5IPbb5fw7Pyaqa9BWthn0t9XdShVHC69UoUn5eaGRm5If3DT20CxZg0ynefTn4w7e57ebIrAa000g3vtkirV"
  "pk_live_51JA9t1HXJb8HVyu6z8ytIBfNTIZEBdfQxsWuAY7Hv5LK0pmYyJ8VdwEdLVxvSemZijn7e2x2sQbzfCANiW8SnYa600WT83M9ID"
  );

const packages = ["Landlord Level One", "Landlord Level Two", "Landlord Professional", "Landlord Enterprise"];

const Subscription = props => {
  const [selectedPlan, setSelectedPlan] = useState(null);

  // const [highlightedPackage, setHighlightedPackage] = useState(0);
  // const hightlightedRefs = useRef(new Array(packages.length).fill().map(_ => createRef()));

  useEffect(() => {
    props.getSubscriptionStatus();
  }, []);

  let currentSubscription = props.auth.profileDetails.subscription;
  console.log("current plan: ", props);
  //currentSubscription.plan === "basic annum" ? currentSubscription.plan = "Landlord Level One" :
  currentSubscription.plan === "trial" ? currentSubscription.plan = "Free Trial" :
  currentSubscription.plan === "basic annum" ? currentSubscription.plan = "Landlord Level One" :
  //( currentSubscription.plan = "basic annum" ? currentSubscription.plan = "Landlord Level One" : console.log("abc")); have to correct after rename in backend
  currentSubscription.plan === "standard annum" ? currentSubscription.plan = "Landlord Level Two" : 
  currentSubscription.plan === "professional annum" ? currentSubscription.plan = "Landlord Professional" :
  currentSubscription.plan === "enterprise annum" ? currentSubscription.plan = "Landlord Enterprise" : console.log("abc");
  let validSubscription = currentSubscription && currentSubscription.status !== "cancelled";
  let loading = props.subscription.getSubscriptionLoading;
  console.log("this is current plan: ", props.auth.profileDetails.subscription);
  return (
    <Row gutter={[20, 20]} type={selectedPlan ? undefined : "flex"}>
      <Col xs={24} sm={24} md={24} lg={10}>
        <Row gutter={[20, 20]}>
          <Col>
            {loading ? (
              
              <div
              style={{
              height: "100%",
              width: "100%",
              minHeight: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              }}
              >
                <Icon type="loading" style={{ fontSize: "2rem" }} />
              </div>
            ) : (
              (!currentSubscription || (currentSubscription && currentSubscription.plan === "none")) ? (
                <div
                style={{
                height: "100%",
                width: "100%",
                minHeight: "30vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                }}
                >
                  <h4 className="appColor">
                    No current plan
                  </h4>
                </div>
              ) : (
              <FreeTrial
              plan={currentSubscription.plan}
              remainingDays={currentSubscription.remaining_days}
              status={currentSubscription.status}
              />
              )
            )}
          </Col>
          <Col>
            <Card className="mini-padding">
              <h3 className="textCenter">Upgrade Plans</h3>
              <Carousel 
              //style={{ height: hightlightedRefs.current[highlightedPackage].current && `calc(${hightlightedRefs.current[highlightedPackage].current.offsetHeight}px + 2rem)` }} 
              //afterChange={current => setHighlightedPackage(current)}
              >
                {/* {(!currentSubscription || (currentSubscription && currentSubscription.plan !== "basic")) && (
                  <UpgradePlans
                    plan="basic"
                    disabled={loading}
                    onSelect={() => setSelectedPlan("basic")}
                  />
                )} */}
                {/* {(!currentSubscription || (currentSubscription && currentSubscription.plan !== "professional")) && (
                  <UpgradePlans
                    plan="professional"
                    disabled={loading}
                    ref={refs}
                    onSelect={() => setSelectedPlan("professional")}
                  />
                )}
                {(!currentSubscription || (currentSubscription && currentSubscription.plan !== "enterprise")) && (
                  <UpgradePlans
                    plan="enterprise"
                    disabled={loading}
                    onSelect={() => setSelectedPlan("enterprise")}
                  />
                )}
                {(!currentSubscription || (currentSubscription && currentSubscription.plan !== "professional annum")) && (
                  <UpgradePlans
                    plan="professional annum"
                    disabled={loading}
                    onSelect={() => setSelectedPlan("professional annum")}
                  />
                )}
                {(!currentSubscription || (currentSubscription && currentSubscription.plan !== "enterprise annum")) && (
                  <UpgradePlans
                    plan="enterprise annum"
                    disabled={loading}
                    onSelect={() => setSelectedPlan("enterprise annum")}
                  />
                )} */}
                {packages.map((_package, index) => (
                  !currentSubscription || (!validSubscription ? true : (currentSubscription && currentSubscription.plan !== _package))) ? (
                  <UpgradePlans
                    plan={_package}
                    disabled={loading}
                    //ref={hightlightedRefs.current[index]}
                    onSelect={() => setSelectedPlan(_package)}
                  />) : null
                )}
              </Carousel>
            </Card>
          </Col>
        </Row>
      </Col>

      <Col xs={24} lg={selectedPlan ? 12 : 14}>
        <Elements
          stripe={stripePromise}
          options={{
            fonts: [
              {
                cssSrc:
                  "https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap"
              }
            ]
          }}
        >
          {selectedPlan ? (
            <StripeForm plan={selectedPlan} callback={() => setSelectedPlan(null)} />
          ) : (
            <div
            style={{
              height: "100%",
              width: "100%",
              minHeight: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            >
              {/* <h4 className="appColor">color: "#efad4e" */}
              <h4 style={{color: "#efad4e"}}>
                Select a plan to purchase
              </h4>
            </div>
          )}
        </Elements>
      </Col>
    </Row>
  );
};

export default connect(state => ({ auth: state.Auth, subscription: state.Subscription }), { getSubscriptionStatus })(Subscription);
