import { resetHeaders, privateAgent} from "../configurations/AxiosAgent";
import { store } from "../ConfigureStore";
import { loginSuccess, logoutRequest } from "../actions/Auth";

const login = (auth, cb) => {
    //let auth = res.data.data;
    window.localStorage.setItem("escrowauth", JSON.stringify(auth))
    privateAgent.defaults.headers = { 
        ...privateAgent.defaults.headers,
        "access-token": auth.token,
        "user-id":auth.user_id
    }
    // console.log("privateAgent.defaults.headers::",privateAgent.defaults.headers)
    if (cb)
        cb();
    store.dispatch(loginSuccess(auth));
}

const logout = () => {
    localStorage.removeItem("escrowauth");
    console.log("I AM hit, ");
    resetHeaders(privateAgent);
    store.dispatch(logoutRequest());
    window.location.href = 'https://escrowtrakkerlandlord.com/';
}

const isLogin = () => {
    if (localStorage.getItem("escrowauth")) {
        //private agent headers set
        return true;
    }

    return false;
}

const redirect = (...params) => {
    //let history = store.getState().App.history;
    //console.log("HISTORY", history)
    //history.push(...params);
    // store.dispatch(redirect(url))
}

export {login, logout, isLogin, redirect}; 