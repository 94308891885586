import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Input from "../../components/input/index";
import RootForm from "../../components/form/index";
import { addBank } from "../../configurations/Schemas/index";
import DatePicker from "../../components/datepickers/DatePicker";
import TextArea from "../../components/input/TextArea";
import CardWrapper from "../../components/card";
import HeaderWrapper from "../../components/header/index";
import { Row, Col } from "antd";
import moment from "moment";
import Loader from "../../components/loading";
import {
  addBankAccountRequest,
  updateBankAccountRequest,
  getSingleBankRequest,
  clearSingleBank,
} from "../../actions/BankAccount";
import info from "../../components/message/index";

import "../auth/auth.css";
let current_date = moment();

class BankAccount extends React.Component {
  constructor(props) {
    console.log("these are props: ", props);
    super(props);
    this.state = {
      landlord_id:null,
      // landlord_name: props.AddBankAccount.bankAccounts[0]?.landlord_name ? props.AddBankAccount.bankAccounts[0].landlord_name : "",
      // landlord_address:props.AddBankAccount.bankAccounts[0]?.landlord_address ? props.AddBankAccount.bankAccounts[0].landlord_address : "",
      // landlord_city:props.AddBankAccount.bankAccounts[0]?.landlord_city ? props.AddBankAccount.bankAccounts[0].landlord_city : "",
      // landlord_state:props.AddBankAccount.bankAccounts[0]?.landlord_state ? props.AddBankAccount.bankAccounts[0].landlord_state : "",
      // landlord_zip:props.AddBankAccount.bankAccounts[0]?.landlord_zip ? props.AddBankAccount.bankAccounts[0].landlord_zip : "",
      // bank_account_name:props.AddBankAccount.bankAccounts[0]?.bank_account_name ? props.AddBankAccount.bankAccounts[0].bank_account_name : "",
      // bank_name:props.AddBankAccount.bankAccounts[0]?.bank_name ? props.AddBankAccount.bankAccounts[0].bank_name : "",
      // landlord_name: props.AddBankAccount.bankAccounts[0]?.landlord_name ? props.AddBankAccount.bankAccounts[0].landlord_name : localStorage.getItem("landname") ? localStorage.getItem("landname") : "",
      // landlord_address: props.AddBankAccount.bankAccounts[0]?.landlord_address ? props.AddBankAccount.bankAccounts[0].landlord_address : localStorage.getItem("landaddress") ? localStorage.getItem("landaddress") : "",
      // landlord_city: props.AddBankAccount.bankAccounts[0]?.landlord_city ? props.AddBankAccount.bankAccounts[0].landlord_city : localStorage.getItem("landcity") ? localStorage.getItem("landcity") : "",
      // landlord_state: props.AddBankAccount.bankAccounts[0]?.landlord_state ? props.AddBankAccount.bankAccounts[0].landlord_state : localStorage.getItem("landstate") ? localStorage.getItem("landstate") : "",
      // landlord_zip: props.AddBankAccount.bankAccounts[0]?.landlord_zip ? props.AddBankAccount.bankAccounts[0].landlord_zip : localStorage.getItem("landzip") ? localStorage.getItem("landzip") : "",
      // bank_account_name: props.AddBankAccount.bankAccounts[0]?.bank_account_name ? props.AddBankAccount.bankAccounts[0].bank_account_name : localStorage.getItem("bankAccountName") ? localStorage.getItem("bankAccountName") : "",
      // bank_name: props.AddBankAccount.bankAccounts[0]?.bank_name ? props.AddBankAccount.bankAccounts[0].bank_name : localStorage.getItem("bankName") ? localStorage.getItem("bankName") : "",
      landlord_name: localStorage.getItem("landname") ? localStorage.getItem("landname") : "",
      landlord_address:localStorage.getItem("landaddress") ? localStorage.getItem("landaddress") : "",
      landlord_city:localStorage.getItem("landcity") ? localStorage.getItem("landcity") : "",
      landlord_state:localStorage.getItem("landstate") ? localStorage.getItem("landstate") : "",
      landlord_zip:localStorage.getItem("landzip") ? localStorage.getItem("landzip") : "",
      bank_account_name:localStorage.getItem("bankAccountName") ? localStorage.getItem("bankAccountName") : "",
      bank_name:localStorage.getItem("bankName") ? localStorage.getItem("bankName") : "",
      bank_address:localStorage.getItem("bankAddress") ? localStorage.getItem("bankAddress") : "",
      bank_city:localStorage.getItem("bankCity") ? localStorage.getItem("bankCity") : "",
      bank_state:localStorage.getItem("bankState") ? localStorage.getItem("bankState") : "",
      bank_zip:localStorage.getItem("bankZip") ? localStorage.getItem("bankZip") : "",
      balance: "0.00",
      additional_note: "",
      // current_date,
      edit: this.props.match.params.id,
      ...props.location.state,
      open_date: props.location.state
        ? moment(props.location.state.open_date)
        : current_date,
      enableRes: true,
    };
  }
  //will uncomment when implemented
  componentDidMount() {
    if (this.props.match.path === "/edit-bank/:id") {
      this.props.getSingleBankRequest(this.props.match.params.id);
      console.log("id is: ", this.props.getSingleBankRequest(this.props.match.params.id));
      console.log("these are new props: ", this.props);
    } else {
      this.props.clearSingleBank();
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (this.props.AddBankAccount.bankDetail !== null) {
        const detail = this.props.AddBankAccount.bankDetail.bank_account;
        console.log("details are: ", detail);
        this.setState({
          id:this.props.match.params.id,
          landlord_id: detail.landlord_id,
          landlord_name: detail.landlord_name,
          landlord_address: detail.landlord_address,
          landlord_city: detail.landlord_city,
          landlord_state: detail.landlord_state,
          landlord_zip: detail.landlord_zip,
          law_firm_name: detail.law_firm_name,
          bank_name: detail.bank_name,
          bank_account_name: detail.bank_account_name,
          bank_address: detail.address,
          bank_city: detail.city,
          bank_state: detail.state,
          bank_zip: detail.zip,
          // balance: detail.opening_balance,
          balance: "0.00",
          cleared_balance: detail.cleared_balance,
          open_date: moment(detail.open_date),
        });
      }
    }
  }

  handleChanged = (e) => {
    console.log("Ehandlechange: ", e);
    console.log("Ehandlechangenew: ", e.target);
    const { name, value } = e.target;
    console.log("Ehandlechangenewewest: ", name);
    console.log("Ehandlechangelatest: ", value);
    console.log("newname: ", value);
    console.log("newname is trimmed: ", value.trim());
    ( name==="landlord_name" ? localStorage.setItem("landname", value) : console.log("yep") );
    ( name==="landlord_address" ? localStorage.setItem("landaddress", value) : console.log("yep") );
    ( name==="landlord_city" ? localStorage.setItem("landcity", value) : console.log("yep") );
    ( name==="landlord_state" ? localStorage.setItem("landstate", value) : console.log("yep") );
    ( name==="landlord_zip" ? localStorage.setItem("landzip", value) : console.log("yep") );
    ( name==="bank_account_name" ? localStorage.setItem("bankAccountName", value) : console.log("yep") );
    ( name==="bank_name" ? localStorage.setItem("bankName", value) : console.log("yep") );
    ( name==="bank_address" ? localStorage.setItem("bankAddress", value) : console.log("yep") );
    ( name==="bank_city" ? localStorage.setItem("bankCity", value) : console.log("newyep") );
    ( name==="bank_state" ? localStorage.setItem("bankState", value) : console.log("oldyep") );
    ( name==="bank_zip" ? localStorage.setItem("bankZip", value) : console.log("yesyep") );
    this.setState({
      [name]: value,
    });
  };

  render() {
    console.log("BANK ACCOUNT", this.props);
    let {
      landlord_name,
      landlord_address,
      landlord_city,
      landlord_state,
      landlord_zip,
      bank_account_name,
      bank_address,
      bank_city,
      bank_state,
      bank_zip,
      bank_name,
      balance,
      open_date,
      additional_note,
      edit,
    } = this.state;

    let { edit: extracted, ...initialValues } = this.state;
    // console.log(
    //   "Bank Account received: ",
    //   this.props.AddBankAccount.bankLists
    //     ? this.props.AddBankAccount.bankLists.bank_accounts
    //       ? true
    //       : false
    //     : false
    // );
    return (
     
      <Row type="flex" justify="center" align="middle">
        <Col span={24}>
          <HeaderWrapper externalscreen={1} />
        </Col>
        <Col xs={24} sm={20} md={22} lg={12}>
          {this.props.match.path === "/edit-bank/:id" &&
          this.props.AddBankAccount.bankDetail === null ? (
            <Loader />
          ) : (
            <CardWrapper>
              <Row
                type="flex"
                justify="center"
                align="middle"
                className="textCenter"
              >
                <Col xs={24} sm={20} md={22} lg={14}>
                  <h4 className="mr-b-md mr-t-md">
                    {edit ? "Edit Bank Account" : "Create Bank Account"}
                  </h4>
                  {/* <h4 className="mr-b-md mr-t-md">LandLord Details</h4> */}
                  <RootForm
                    schemaName={addBank}
                    initialValues={initialValues}
                    enableReinitialize={this.state.enableRes}
                    // enableReinitialize={
                    //   this.props.AddBankAccount.bankLists
                    //     ? this.props.AddBankAccount.bankLists.bank_accounts
                    //       ? true
                    //       : false
                    //     : false
                    // }
                    apiCall={
                      edit ? updateBankAccountRequest : addBankAccountRequest
                    }
                    loading={this.props.AddBankAccount.loading}
                    // resetAfterSubmit
                    cb={() =>
                      this.props.history.push(
                        this.props.location.state
                          ? this.props.location.state.referrer
                          : "/"
                      )
                    }
                    controls={[
                      {
                        span: 24,
                        name: "landlord_name",
                        value: landlord_name,
                        handleChanged: this.handleChanged,
                        placeholder: "LandLord Name",
                        Type: Input,
                      },
                      {
                        span: 24,
                        name: "landlord_address",
                        value: landlord_address,
                        handleChanged: this.handleChanged,
                        placeholder: "Address",
                        Type: Input,
                      },
                      {
                        span: 24,
                        name: "landlord_city",
                        value: landlord_city,
                        handleChanged: this.handleChanged,
                        placeholder: "City",
                        Type: Input,
                      },
                      {
                        span: 24,
                        name: "landlord_state",
                        value: landlord_state,
                        handleChanged: this.handleChanged,
                        placeholder: "State",
                        Type: Input,
                      },
                      {
                        span: 24,
                        name: "landlord_zip",
                        value: landlord_zip,
                        handleChanged: this.handleChanged,
                        placeholder: "Zip",
                        Type: Input,
                      },
                      
                      {
                        span: 24,
                        name: "bank_account_name",
                        value: bank_account_name,
                        handleChanged: this.handleChanged,
                        placeholder: "Bank Account Name",
                        Type: Input,
                      },
                      {
                        span: 24,
                        name: "bank_name",
                        value: bank_name,
                        handleChanged: this.handleChanged,
                        placeholder: "Bank Name",
                        Type: Input,
                      },
                      {
                        span: 24,
                        name: "bank_address",
                        value: bank_address,
                        handleChanged: this.handleChanged,
                        placeholder: "Address",
                        Type: Input,
                      },
                      {
                        span: 24,
                        name: "bank_city",
                        value: bank_city,
                        handleChanged: this.handleChanged,
                        placeholder: "City",
                        Type: Input,
                      },
                      {
                        span: 24,
                        name: "bank_state",
                        value: bank_state,
                        handleChanged: this.handleChanged,
                        placeholder: "State",
                        Type: Input,
                      },
                      {
                        span: 24,
                        name: "bank_zip",
                        value: bank_zip,
                        handleChanged: this.handleChanged,
                        placeholder: "Zip",
                        Type: Input,
                      },

                      {
                        span: 24,
                        name: "balance",
                        value: balance,
                        handleChanged: this.handleChanged,
                        placeholder: "Opening Balance",
                        Type: Input,

                        props: {
                          readOnly: true,
                          onClick: () =>
                            info(
                              "warning",
                              `Opening balances must be entered as a deposit transaction`
                            ),
                        },
                      },

                      {
                        span: 24,
                        name: "open_date",
                        handleChanged: this.handleChanged,
                        placeholder: "Pick Date",

                        props: {
                          format: "MM-DD-YY",
                        },
                        block: true,
                        allowClear: false,
                        //disabledDate: e => e && !moment().isAfter(e),
                        showToday: false,
                        Type: DatePicker,
                      },
                      {
                        span: 24,
                        name: "additional_note",
                        value: additional_note,
                        handleChanged: this.handleChanged,
                        placeholder: "Note",
                        Type: TextArea,
                        rows: 4,
                      },
                    ]}
                    submitButton={{
                      span: 18,
                      buttonText: edit ? "Save Changes" : "Create Bank Account",
                      className: "mr-t-md mr-b-lg",
                    }}
                  />
                </Col>
              </Row>
            </CardWrapper>
          )}
        </Col>
      </Row>
    );
  }


}



export default withRouter(
  connect(
    (storeState) => ({
      AddBankAccount: storeState.AddBankAccount,
    }),
    {
      // getBankAccountsRequest
      getSingleBankRequest,
      clearSingleBank,
    }
  )(BankAccount)
);
