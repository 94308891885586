import React from "react";
import { connect } from "react-redux";

import ReportTypeCard from "./General";
import TransferJournalLedger from "../forms/TransferJournalLedger";

import { openModal, closeAllModal } from "../../../../actions/Modal"


const JournalTransferLedger = props => (
  <ReportTypeCard 
  initialValue={props.initialType || "pdf"}
  heading="Tenants Escrow Disclosure Statement"
  onSubmit={format => {
    props.openModal(
      null,
      <TransferJournalLedger initialValues={props.initialFormValues} format={format} />,
      null,
      null,
      {
        footer: null
      }
    );
  }} 
  />
)

export default connect(null, { openModal, closeAllModal })(JournalTransferLedger);
