import React from "react";
import { Row, Col } from "antd";
import Card from ".././../../components/card";
import Tag from ".././../../components/tag";

let map = {
  Deposit: "primary",
  Withdrawal: "secondary",
  "interest deposit": "primary",
  "interest withdrawal": "secondary"
  //Banned: "fourth
};

const TransactionTypeCard = props => {
  return (
    <Card>
      <Row type="flex" justify="center" gutter={[10, 35]}>
        <Col span={24} style={{ textAlign: "center" }}>
          <h3>Transaction Type</h3>
        </Col>
        {props.recurring ? (
          <React.Fragment>
            <Col xs={12} md={24} lg={12} style={{ textAlign: "center" }}>
              <Tag type={map[props.type]} style={{ width: "100%", textTransform: "capitalize" }}>{props.type}</Tag>
          </Col>
          <Col xs={12} md={24} lg={12} style={{ textAlign: "center" }}>
            <Tag type="third" style={{ width: "100%", textTransform: "capitalize" }}>Recurring</Tag>
          </Col>
          </React.Fragment>
        ) : (
        <Col xs={12} md={24} style={{ textAlign: "center" }}>
          <Tag type={map[props.type]} style={{ textTransform: "capitalize" }}>{props.type}</Tag>
        </Col>
        )}
      </Row>
    </Card>
  );
};

export default TransactionTypeCard;
