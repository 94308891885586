import React from "react";
import { Card } from "antd";
import styled from "styled-components";


const StyledCard = styled(Card)`
  color: ${props => !props.color ? props.theme[props.theme.mode].textColor.primary : props.theme[props.theme.mode].textColor.inverted};
  background-color: ${props => props.theme[props.theme.mode].background.primary};
  height: 100%;
  font-size: inherit;
  border: none;
  border-radius: ${props => props.theme[props.theme.mode].card.borderRadius};
  box-shadow: 0px 5px 20px #1E1E220C;
  &.a4Size {
    width: 60vw;
    height: calc(60vw * 1.414);
  }
  & > .ant-card-body {
    padding: ${props => props.smallPadding? "1.2rem":"4rem"};
    height: 100%;
    background: ${props => !props.color ? props.theme[props.theme.mode].background.primary: props.theme[props.theme.mode].card.colors[props.color]};
    border-radius: ${props => props.theme[props.theme.mode].card.borderRadius};
    
    overflow: hidden;
  }
  &.extraPadding >  .ant-card-body {
    padding: ${props => props.theme[props.theme.mode].spacing.inner + 4}px;
  }
  &.noPadding > .ant-card-body {
    padding: 0;
  }
  &.mini-padding > .ant-card-body{
    padding:1.2rem 0.5rem 1.2rem 0.5rem;
  }
  &.secondaryCard > .ant-card-body{
    background-color: #efad4e;
  }
  & .innerContentSpacing {
    margin-top: ${props => props.theme[props.theme.mode].spacing.outer}px;
  }
  & .ant-card-head {
    background: ${props => props.theme[props.theme.mode].background.tertiary};
    border-bottom: 2px solid ${props => props.theme[props.theme.mode].border};
    border-radius : 0;
  }
  .ant-card-actions {
    background: ${props => props.theme[props.theme.mode].background.tertiary};
    border-top: 1px solid ${props => props.theme[props.theme.mode].border};
  }
  .ant-card-actions > li:not(:last-child) {
    border-right: 1px solid ${props => props.theme[props.theme.mode].border};
  }

  @media screen and (max-width: 767px) {
    &.a4Size {
      width: 100%;
      height: auto;
      padding-top: 141%;
    }
    &.a4Size > .ant-card-body {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
`;

const CardWrapper = props => {
  return (
    <StyledCard {...props}>
    {props.children}
    </StyledCard>
  );
};
export default CardWrapper;
