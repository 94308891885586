import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Input from "../../components/input/index";
import Select from "../../components/input/Select";
import SelectOptions from "../../components/input/SelectOptions";
import TextArea from "../../components/input/TextArea";
import RootForm from "../../components/form/index";
import { addContactSchema } from "../../configurations/Schemas/index";
import CardWrapper from "../../components/card";
import HeaderWrapper from "../../components/header/index";
import { Row, Col } from "antd";

import { addAccountRequest, editContactRequest } from "../../actions/Contact";

class Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      full_name:
        props.location.state && props.location.state.contactDetails
          ? props.location.state.contactDetails.full_name
          : "",
      address:
        props.location.state && props.location.state.contactDetails
          ? props.location.state.contactDetails.address
          : "",
      city:
        props.location.state && props.location.state.contactDetails
          ? props.location.state.contactDetails.city
          : "",
      state:
        props.location.state && props.location.state.contactDetails
          ? props.location.state.contactDetails.state
          : "",
      zip:
        props.location.state && props.location.state.contactDetails
          ? props.location.state.contactDetails.zip
          : "",
      phone:
        props.location.state && props.location.state.contactDetails
          ? props.location.state.contactDetails.phone
          : "",
      email:
        props.location.state && props.location.state.contactDetails
          ? props.location.state.contactDetails.email
          : "",
      comment:
        props.location.state && props.location.state.contactDetails
          ? props.location.state.contactDetails.comment
          : "",
      contact_type:
        props.location.state && props.location.state.contactDetails
          ? props.location.state.contactDetails.contact_type
          : props.location.state && props.location.state.contactType
          ? props.location.state.contactType
          : undefined,
      edit: this.props.match.params.id ? true : false,
      id: this.props.match.params.id,
      // ...props.location.state,
      // enableRes: true
    };
  }

  handleChanged = (e) => {
    const { name, value } = e.target;
    console.log("value", value);
    this.setState({
      [name]: value,
    });
  };

  render() {
    console.log("conatct State", this.state);
    console.log(this.props.match.params.id, "Contacts");
    let {
      full_name,
      address,
      city,
      state,
      zip,
      phone,
      email,
      comment,
      contact_type,
      edit,
    } = this.state;
    let routeState = this.props.location.state;
    console.log("routeState", routeState);
    console.log("storeState", this.props);
    return (
      <Row type="flex" justify="center" align="middle">
        <Col span={24}>
          <HeaderWrapper externalscreen={1} />
        </Col>
        <Col xs={24} sm={20} md={22} lg={12}>
          <CardWrapper>
            <Row
              type="flex"
              justify="center"
              align="middle"
              className="textCenter"
            >
              <Col xs={24} sm={20} md={22} lg={14}>
                <h4 className="mr-b-md mr-t-md">
                  {edit
                    ? "Edit Contact"
                    : routeState && routeState.contactType
                    ? `Add new ${routeState.contactType === "other" ? "payor" : routeState.contactType}`
                    : "Add new contact"}
                </h4>
                <RootForm
                  schemaName={addContactSchema}
                  initialValues={this.state}
                  // enableReinitialize={this.state.enableRes}
                  apiCall={edit ? editContactRequest : addAccountRequest}
                  loading={this.props.Contacts.loading}
                  resetAfterSubmit
                  cb={() =>
                    this.props.history.push(
                      this.props.location.state
                        ? this.props.location.state.referrer
                        : "/"
                    )
                  }
                  controls={[
                    {
                      span: 24,
                      name: "full_name",
                      value: full_name,
                      handleChanged: this.handleChanged,
                      placeholder: "Full Name",
                      Type: Input,
                    },
                    {
                      span: 24,
                      name: "address",
                      value: address,
                      handleChanged: this.handleChanged,
                      placeholder: "Address",
                      Type: Input,
                    },
                    {
                      span: 24,
                      name: "city",
                      value: city,
                      handleChanged: this.handleChanged,
                      placeholder: "City",
                      Type: Input,
                    },
                    {
                      span: 24,
                      name: "state",
                      value: state,
                      handleChanged: this.handleChanged,
                      placeholder: "State",
                      Type: Input,
                    },
                    {
                      span: 24,
                      name: "zip",
                      value: zip,
                      handleChanged: this.handleChanged,
                      placeholder: "Zip",
                      Type: Input,
                    },
                    {
                      span: 24,
                      name: "phone",
                      value: phone,
                      handleChanged: this.handleChanged,
                      placeholder: "Phone",
                      Type: Input,
                    },
                    {
                      span: 24,
                      name: "email",
                      value: email,
                      handleChanged: this.handleChanged,
                      placeholder: "Email",
                      // disabled: edit?true:false,
                      Type: Input,
                    },
                    {
                      span: 24,
                      name: "comment",
                      value: comment,
                      handleChanged: this.handleChanged,
                      placeholder: "Comment",
                      Type: TextArea,
                      rows: 4,
                    },

                    {
                      span: 24,
                      name: "contact_type",
                      value: contact_type,
                      handleChanged: this.handleChanged,
                      placeholder: "Type",
                      className: "inputField",
                      Type: Select,
                      options: () => [
                        <SelectOptions value="personal">
                          Personal
                        </SelectOptions>,
                        <SelectOptions value="vendor">Vendor</SelectOptions>,
                        <SelectOptions value="tenant">
                          Tenant
                        </SelectOptions>,
                        <SelectOptions value="other">Other</SelectOptions>,
                      ],
                      //disabled: routeState && routeState.contactType,
                    },
                  ]}
                  submitButton={{
                    span: 18,
                    buttonText: edit ? "Save Changes" : "Add Contact",
                    className: "mr-t-md mr-b-lg",
                  }}
                />
              </Col>
            </Row>
          </CardWrapper>
        </Col>
      </Row>
    );
  }
}

export default withRouter(
  connect((storeState) => ({ Contacts: storeState.Contact }), {
    addAccountRequest,
    editContactRequest,
  })(Contacts)
);
