import React, { useState, useRef, useEffect, Fragment } from "react";
import General from "./General";
import SelectOptions from "../../../../../components/input/SelectOptions";
import Icon from "../../../../../components/Icon";
import { FiAlertTriangle } from "react-icons/fi";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import { debounce } from "debounce";
import { getJobsRequest } from "../../../../../actions/FieldOptions";
import { deleteJobRequest } from "../../../../../actions/transaction/Delete";
import PopConfirm from "../../../../../components/pop-confirm";
import DeleteButton from "../../DeleteButton";
import EditButton from "../../EditButton";
import Job from "../../../../../views/shell/addtranscation/Job";
import { openModal } from "../../../../../actions/Modal";
const JobName = (props) => {
  // console.log("allBanks", props.allBanks);
  // console.log("props", props);
  const [value, setValue] = useState(
    props.initialValue
      ? { value: props.initialValue, jobId: props.initialValue[1] }
      : {}
  );
  //const [value, setValue] = useState(props.initialValue ? { value: props.initialValue } : {});
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectLoading, setSelectLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const apiCall = useRef();
    //console.log("init value: ", props.initialValue[0]);
  useEffect(() => {
    if(props.deletedJob && props.deletedJob === value.jobId) {
      props.onChange();
      setValue({ value: undefined });
    }
  }, [props.deletedJob])

  useEffect(() => {
    if(props.withJobValue) {
      setValue({ value: props.jobValue })
    }
  }, [props.jobValue])

  useEffect(() => {
    apiCall.current = debounce(
      (e) => {
        console.log("api call", e);
        setLoading(!options.length);
        props.getJobsRequest(null, props.id, (error, data) => {
          if (error) {
            setLoading(false);
          } else {
            console.log("PROPERTIES DATA", data);
            setLoading(false);
            let viewAll = props.withClearOption ? ["View All"] : [];
            setOptions([...viewAll, ...data]);
          }
        });
      },
      1000,
      true
    );
  }, [options, props.id]);

  useEffect(() => {
    setOptions([]);
    apiCall.current();
  }, [props.id]);
  useEffect(() => {
    if(props.getFromBankLawfirm) {
      console.log("NEWESTTAS APROPS: ", props.getFromBankLawfirm);
      setSelectLoading(true);
      props.getJobsRequest(null, (error, data) => {
        if (error) {
          setSelectLoading(false);
        } else {
          setSelectLoading(false);

          console.log("YERT", data);

          const fromLawfirm = data.find(val => val.job_id == props.getFromBankLawfirm);
          if(fromLawfirm) {
            let id = fromLawfirm.job_id, name = fromLawfirm.job_name;

            props.onChange(id, name);
            setValue({ value: name, jobId: id });
          }
        }
      });
    }
  }, [props.getFromBankLawfirm])

  //   const history = useHistory();
  console.log("getJobsRequest props==>", props);
  //   let { ...props } = props;
  options.map((item, index) => {
    console.log("items are: ", item.property_address);
  });
  return (
    <div>
      <General
        {...props}
        {...value}
        loading={selectLoading}
        onChange={(e) => {
          if (props.onChange) {
            if (e) {
              props.onChange(e.split("*")[0], e.split("*")[1]);
              setValue({ value: e.split("*")[1], jobId: e.split("*")[0] });
            } else {
              props.onChange(e);
              setValue({ value: e });
            }
          }
        }}
        onSearch={(Search) => {
          // if (open) {
          //   // let filtered = options.filter(e=>e.law_firm_name.includes(Search))
          //   // setOptions(filtered);
          //   // console.log("options", options);
          //   // apiCall.current(e);
          // }
        }}
        onDropdownVisibleChange={(open) => {
          // setOpen()
          if (open) {
            setOpen(true);
            apiCall.current();
            //debounce(apiCall, 1000)();
          } else {
            setOpen(false);

            //apiCall.clear();
          }
        }}
        open={open}
      >
        {loading ? (
          <SelectOptions
            className="loadingOption"
            disabled
            value={1}
            style={{ cursor: "normal" }}
          >
            <div
              className="textCenter"
              style={{ width: "100%", textAlign: "center" }}
            >
              <Icon type="loading" style={{ fontSize: "1.5rem" }} />
            </div>
          </SelectOptions>
        ) : (
          options.map((item, index) =>
            item === "View All" ? (
              <SelectOptions key={index} value="">
                All properties
              </SelectOptions>
            ) : (
              <SelectOptions
                key={index}
                value={`${item.property_id}*${item.property_name}`}
              >
                {!props.hideDeleteBtn ? (
                   <Fragment>
                   <Col span={18} className="expandMe">
                     <div>{item.property_name}</div>
                     {/* <div className="hideMe">
                       {item.type}
                     </div> */}
                     { props.isBankACC ?
                     <div className="hideMe">
                       {item.property_address}
                     </div>
                     : <div className="hideMe">
                        {item.address}
                      </div>
                      }
                     {/* <Col span={21} className="expandMe">
                  //     <div>{item.property_name}</div>
                  //   </Col>
                  //   <Col span={21} className="expandMe">
                  //     <div>{item.address}</div>
                  //   </Col> */}
                   </Col>
                   <Col
                     span={3}
                     className="hideMe"
                     onClick={(e) => e.stopPropagation()}
                   >
                     <EditButton
                       onClick={() =>
                         props.openModal(
                           null,
                           <Job
                             initialValues={item}
                           />,
                           null,
                           null,
                           { footer: null }
                         )
                       }
                     />
                   </Col>
                   <Col
                     span={3}
                     className="hideMe"
                     // onMouseDown={e => {
                     //   e.stopPropagation();
                     //   e.preventDefault();
                     // }}
                     onClick={(e) => {
                       e.stopPropagation();
                       //e.preventDefault();
                     }}
                   >
                     <PopConfirm
                       title="Are you sure you want to delete this account?"
                       icon={
                         <FiAlertTriangle
                           style={{
                             position: "absolute",
                             color: "#f44336",
                             left: 0,
                             top: 0,
                             marginTop: 6,
                           }}
                         />
                       }
                       cancelText="No"
                       okText="Yes"
                      //  onConfirm={function () {
                      //    props.deleteAccountHeadRequest(item.id, (res) => {
                      //      apiCall.current();

                      //      if (props.onDelete) props.onDelete(item.id);

                      //      // if(item.id == value.accountId) {
                      //      //   props.onChange();
                      //      //   setValue({ value: undefined });
                      //      // }
                      //    });
                      //  }}
                       onConfirm={function () {
                                 props.deleteJobRequest(item.property_id, (res) => {
                                   apiCall.current();
      
                                   if (props.onDelete)
                                     props.onDelete(item.property_id)
      
                                   if (item.property_id == value.jobId) {
                                    props.onChange();
                                    setValue({ value: undefined });
                                  }
                                });
                              }}
                      >
                       <DeleteButton />
                     </PopConfirm>
                   </Col>
                 </Fragment>
                  // <Row
                  //   type="flex"
                  //   align="middle"
                  //   justify="space-between"
                  //   style={{ width: "100%" }}
                  // >
                  //   <Col span={21} className="expandMe">
                  //     <div>{item.property_name}</div>
                  //   </Col>
                  //   <Col span={21} className="expandMe">
                  //     <div>{item.address}</div>
                  //   </Col>
                  //   {/* <Col>
                  //   <EditButton
                  //         onClick={() =>
                  //           props.openModal(
                  //             null,
                  //             <Job
                  //               initialValues={item}
                  //               //accType={item.property_name}
                  //             />,
                  //             null,
                  //             null,
                  //             { footer: null }
                  //           )
                  //         }
                  //       />
                  //   </Col> */}
                  //   <Col
                  //       span={3}
                  //       className="hideMe"
                  //       onClick={(e) => e.stopPropagation()}
                  //     >
                  //       <EditButton
                  //         onClick={() =>
                  //           props.openModal(
                  //             null,
                  //             <Job
                  //               initialValues={item}
                  //               //accType={item.property_name}
                  //             />,
                  //             null,
                  //             null,
                  //             { footer: null }
                  //           )
                  //         }
                  //       />
                  //     </Col>
                  //   <Col
                  //     span={3}
                  //     className="hideMe"
                  //     // onMouseDown={e => {
                  //     //   e.stopPropagation();
                  //     //   e.preventDefault();
                  //     // }}
                  //     onClick={(e) => {
                  //       e.stopPropagation();
                  //       //e.preventDefault();
                  //     }}
                  //   >
                  //     <PopConfirm
                  //       title="Are you sure you want to delete this Property?"
                  //       icon={
                  //         <FiAlertTriangle
                  //           style={{
                  //             position: "absolute",
                  //             color: "#f44336",
                  //             left: 0,
                  //             top: 0,
                  //             marginTop: 6,
                  //           }}
                  //         />
                  //       }
                  //       cancelText="No"
                  //       okText="Yes"
                  //       onConfirm={function () {
                  //         props.deleteJobRequest(item.property_id, (res) => {
                  //           apiCall.current();

                  //           if (props.onDelete)
                  //             props.onDelete(item.property_id)

                  //           // if (item.property_id == value.jobId) {
                  //           //   props.onChange();
                  //           //   setValue({ value: undefined });
                  //           // }
                  //         });
                  //       }}
                  //     >
                  //       <DeleteButton style={{ minWidth: "auto" }} />
                  //     </PopConfirm>
                  //   </Col>
                  // </Row>
                ) : (
                  <Row
                    type="flex"
                    align="middle"
                    justify="space-between"
                    style={{ width: "100%" }}
                  >
                    <Col span={24} className="expandMe">
                      <div>{item.property_name}</div>
                    </Col>
                    <Col span={24} className="expandMe">
                    { props.isBankACC ?
                     <div className="hideMe">
                       {item.property_address}
                     </div>
                     : <div className="hideMe">
                        {item.address}
                      </div>
                      }
                    </Col>
                  </Row>
                )}
              </SelectOptions>
            )
          )
        )}
        {/* <SelectOptions value={2}>urturtu</SelectOptions> */}
      </General>
    </div>
  );
};

export default connect(null, { getJobsRequest, deleteJobRequest, openModal })(JobName);
