import { takeLatest,put } from "redux-saga/effects";
import moment from "moment";
import info from "../components/message/index";
import { privateAgent, CancelToken } from "../configurations/AxiosAgent";
import {
  GET_FIELD_OPTIONS_REQUEST,
  GET_ACCOUNTS_REQUEST,
  GET_JOBS_REQUEST,
  ADD_JOB_REQUEST,
  EDIT_JOB_REQUEST,
  ADD_ACCOUNT_NAME_REQUEST,
  EDIT_ACCOUNT_NAME_REQUEST
} from "../configurations/Types";
import { addAccountSuccess, addAccountError, editAccountSuccess, editAccountError, editJobError, editJobsRequest } from "../actions/FieldOptions";
import {
  // MOVE CONTACT ENDPOINT TO A NEW SAGA.
  CONTACT_USER_ENDPOINT,
  TRANSACTION_ENDPOINT,
  ACCOUNTS_ENDPOINT,
  GET_JOBS_ENDPOINT,
  ADD_JOB_ENDPOINT,
  ACCOUNT_NAME_ENDPOINT,
  EDIT_ACCOUNT_NAME_ENDPOINT,
  NEW_EDIT_ACCOUNT_NAME_ENDPOINT,
  EDIT_JOB_ENDPOINT,
  CHARTS_ENDPOINT,
  GET_CHARTS
} from "../configurations/Constants";

import { addJobSuccess, addJobError, editJobSuccess } from "../actions/FieldOptions";

let current_time = () => moment().format("YYYY-MM-DD HH:mm:ss");
//get Field Options
const getFieldOptionsApi = (contact_type, id) => {
  
  console.log("this is contact type: ", contact_type);
  if(id){
    return privateAgent.get(`${CHARTS_ENDPOINT}${GET_CHARTS}?bank_account_id=${id}`
     ,{
        params: {
          limit: 10,
          offset: 0
        }
     });
  }
  else{
  return privateAgent.get(`${CONTACT_USER_ENDPOINT}`, {
    params: {
      contact_type,
      limit: 10000
    }
  });
  }
};
// let bodyAPI = {
//   bank_account_id: parseInt(id),
// }
// let formData = new FormData();
// for (const key in bodyAPI) {
//   console.log(`Form Data: ${key} = ${bodyAPI[key]}`);
//   formData.append(key, bodyAPI[key]);
// }
// console.log("BODY API", bodyAPI);
// console.log("this is contact type: ", contact_type);
// if(id){
//   return privateAgent.get(`${CHARTS_ENDPOINT}${GET_CHARTS}${id}`, 
//   formData, 
//   { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
//   );
// }
function* getFieldOptions(action) {
  let res;

  try {
    if(action.id){
      res = yield getFieldOptionsApi(action.contactType, action.id);
    //   var a = res.data.data.tenants;
    //   console.log("RES: ", res);
    //   console.log("AAAA: ", a);
    //   var x = a.map((t)=> {
    //    return ( t.tenant_name )
    // }
      //)
      //console.log("ABCD: ", {x});
      if (action.cb) {
        //let data = Object.values(res.data.data.tenants);
        let data = Object.values(res.data.data.tenants);
        action.cb(null, data);
    }
    }
    else{
      res = yield getFieldOptionsApi(action.contactType);
      console.log("RES: ", res);
      if (action.cb) {
        let data = Object.values(res.data.data.contacts);
        action.cb(null, data);
    }
    }
    // res = yield getFieldOptionsApi(action.contactType);
    // console.log("RES: ", res);
    // if (action.cb) {
    //   let data = Object.values(res.data.data.contacts);
    //   action.cb(null, data);
    // }
  } catch (e) {
    console.log("EEEEEE", e);
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    if (action.cb) {
      action.cb(e);
    }
  }
}
//get Field Options

// get Accounts
const getAccountsApi = (accountType, bankAccount) => {
  console.log("here");
  if(bankAccount)
  {
    return privateAgent.get(`${CHARTS_ENDPOINT}${GET_CHARTS}?bank_account_id=${bankAccount}`, {
      params: {
                limit: 10,
                offset: 0
              }
    });
  }
  else{
    return privateAgent.get(`${TRANSACTION_ENDPOINT}${ACCOUNTS_ENDPOINT}`, {
      params: {
        account_type: accountType,
        bank_account_id: bankAccount
      }
    });
  }
};

function* getAccountsRequest(action) {
  let res;
  console.log("SAGA: ", action);
  try {
    if(action.bankAccount) {
      res = yield getAccountsApi(action.accountType, action.bankAccount);
    console.log("THIS IS RES charts: ", res);
    if (action.cb) {
      action.cb(null, res.data.data.accounts);
    }
    }
    else {
      res = yield getAccountsApi(action.accountType, action.bankAccount);
    console.log("THIS IS RES: ", res);
    if (action.cb) {
      action.cb(null, res.data.data);
    }

    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    if (action.cb) {
      action.cb(e);
    }
  }
}

// Add Account Name
let addAccountToken;
const addAccountNameApi = (body, token) => {
  return privateAgent.post(`${TRANSACTION_ENDPOINT}${ACCOUNT_NAME_ENDPOINT}`, {
    ...body,
    current_time: current_time()
  }, {
    cancelToken: token.token
  });
};
function* addAccountNameRequest(action) {
  let res;
  try {
    if (addAccountToken) {
      addAccountToken.cancel();
    }
    addAccountToken = CancelToken.source();
    console.log("Account name for action body: ", action.body);
    action.body["account_name"] = action.body["account_name"].trim();
    res = yield addAccountNameApi(action.body, addAccountToken);
    info("success", `${res.data.data.message}`);
    yield put(addAccountSuccess());
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
      yield put(addAccountError());
      
    }
  }
}
// Edit Account Name
let editAccountToken;
const editAccountNameApi = (body, id, token) => {
  return privateAgent.put(`${TRANSACTION_ENDPOINT}${NEW_EDIT_ACCOUNT_NAME_ENDPOINT}/${id}`, {
    ...body,
    current_time: current_time()
  }, {
    cancelToken: token.token
  });
};
function* editAccountNameRequest(action) {
  let res;
  try {
    if (editAccountToken) {
      editAccountToken.cancel();
    }
    editAccountToken = CancelToken.source();
    res = yield editAccountNameApi(action.body, action.id, editAccountToken);
    info("success", `${res.data.data.message}`);
    yield put(editAccountSuccess());
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
      yield put(editAccountError());
      
    }
  }
}
//get Jobs
const getJobsApi = (bankAccount) => {
  if(bankAccount)
  {
    return privateAgent.get(`${CHARTS_ENDPOINT}${GET_CHARTS}?bank_account_id=${bankAccount}`, {
      params: {
                limit: 10,
                offset: 0
              }
    });
  }
  else {
    return privateAgent.get(`${TRANSACTION_ENDPOINT}${GET_JOBS_ENDPOINT}`);
  }
};

function* getJobsRequest(action, bankAccount) {
  let res;

  try {
    if(action.bankAccount) {
      res = yield getJobsApi(action.bankAccount);
      if (action.cb) {
        console.log("NEWEST RESPONSE SAGA: ", res.data.data.properties);
        action.cb(null, res.data.data.properties);
      }
    }
    else {
      res = yield getJobsApi();
      if (action.cb) {
        console.log("NEWEST RESPONSE: ", res.data.data.property);
        action.cb(null, res.data.data.property);
      }
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    if (action.cb) {
      action.cb(e);
    }
  }
}
//get Jobs

//add Jobs
let addJobToken;
const addJobApi = (body, token) => {
  // let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
  console.log("addContactApi", body);
  return privateAgent.post(`${TRANSACTION_ENDPOINT}${ADD_JOB_ENDPOINT}`, {
    ...body,
    current_time: current_time()
  }, {
    cancelToken: token.token
  });
};

function* addJobsRequest(action) {
  console.log("FORM ADD", action);
  let res;
  try {
    if (addJobToken) {
      addJobToken.cancel();
    }
    addJobToken = CancelToken.source();
    action.body["property_name"] = action.body["property_name"].trim();
    console.log("PROPERTYNAME: ", action.body);
    res = yield addJobApi(action.body, addJobToken);
    info("success", `${res.data.data.message}`);
    yield put(addJobSuccess());
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }

    yield put(addJobError());
  }
}

//add Jobs

//edit jobs
let editJobToken;
const editJobApi = (body, id, token) => {
  return privateAgent.put(`${TRANSACTION_ENDPOINT}${EDIT_JOB_ENDPOINT}/${id}`, {
    ...body,
    current_time: current_time()
  }, {
    cancelToken: token.token
  });
};
function* editJobRequest(action) {
  console.log("FORM EDIT", action)
  let res;
  try {
    if (editJobToken) {
      editJobToken.cancel();
    }
    editJobToken = CancelToken.source();
    res = yield editJobApi(action.body, action.id, editJobToken);
    info("success", `${res.data.data.message}`);
    yield put(editJobSuccess());
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
      yield put(editJobError());
      
    }
  }
}
//edit jobs
// Our watcher Saga: spawn all Saga request for Field Options
export function* watchFieldOptions() {
  yield takeLatest(GET_FIELD_OPTIONS_REQUEST, getFieldOptions);
  yield takeLatest(GET_ACCOUNTS_REQUEST, getAccountsRequest);
  yield takeLatest(GET_JOBS_REQUEST, getJobsRequest);
  yield takeLatest(ADD_JOB_REQUEST, addJobsRequest);
  yield takeLatest(ADD_ACCOUNT_NAME_REQUEST, addAccountNameRequest);
  yield takeLatest(EDIT_ACCOUNT_NAME_REQUEST, editAccountNameRequest);
  yield takeLatest(EDIT_JOB_REQUEST, editJobRequest);
}
