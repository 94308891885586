import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Button from "../../../components/button";
import MonthPicker from "../../../components/datepickers/MonthPicker";
import DatePicker from "../../../components/datepickers/DatePicker";
import MonthDropdown from "../../../appcomponents/EscrowTrakker/detailbanks/FiltersTransaction";
import Select from "../../../components/input/Select";
import Option from "../../../components/input/SelectOptions";
import Info from "../../../components/message";
import { Row, Col } from "antd";
import Charts from "../../../views/shell/Charts";
const FilterWrapper = styled.div`
  & .monthPicker input {
    background: ${props => props.theme[props.theme.mode].background.primary};
    border: none;
    border-radius: 5px;
    box-shadow: 0.25rem 0 1rem
      ${props => props.theme[props.theme.mode].textColor.hint}0C;
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.captions};
    padding: calc(0.7vw + 1px) 0.8vw;
  }
  @media screen and (max-width: 991px) {
    & .monthPicker input {
      font-size: inherit;
      padding: calc(0.5rem + 1px);
    }
  }
`;

const ChartFilters = props => {
  let { dateType } = props;
  //const [date, setDate] = useState(moment());
  const history = useHistory();
  // const handleChange = d => {
  //   setDate(d);
  // };
  const [yearPickerOpen, setYearPickerOpen] = useState(false);
  return (
    <React.Fragment>
      <FilterWrapper>
        <Row gutter={[20, 0]} type="flex" justify="end" align="middle">
          {dateType === "monthly" ? (
            <Col xs={14} lg={8}>
              <MonthPicker
                style={{ width: "100%" }}
                allowClear={false}
                onChange={e => {
                  //handleChange(e);
                  props.getDate(e.year(), e.month() + 1);
                }}
                placeholder={"Select Month"}
                value={moment().month(props.month - 1).year(props.year)}
                className="no-mr monthPicker"
              />
            </Col>
          ) : dateType === "yearly" ? (
            <React.Fragment>
              {/* <Col span={7}>
                <MonthDropdown
                  singleMonth
                  //outlined

                  className="no-mr"
                  name="month"
                  //value={mainForm.values.month}
                  placeholder="Month"
                  //onChange={e => mainForm.customHandleChange(e, "month")}
                  //onBlur={e => mainForm.customHandleBlur(e, "month")}
                />
              </Col> */}
              <Col xs={14} lg={8}>
                <DatePicker
                  noBorder
                  allowClear={false}
                  open={yearPickerOpen}
                  onOpenChange={open => setYearPickerOpen(open)}
                  modalField
                  className="no-mr"
                  name="year"
                  value={
                    props.year
                      ? moment().year(props.year)
                      : undefined
                  }
                  //onChange={e => mainForm.customHandleChange(e, "year")}
                  // onFocus={() => setYearPickerOpen(true)}
                  // onBlur={e => {
                  //   //mainForm.customHandleBlur(e, "year");
                  //   setYearPickerOpen(false);
                  // }}
                  style={{ width: "100%" }}
                  //picker="year"
                  format="YYYY"
                  mode="year"
                  placeholder="Year"
                  onPanelChange={e => {
                    // if (!mainForm.values.date_type === "quaterly")
                    //   mainForm.customHandleChange(e.year(), "year");
                    // else mainForm.onChangeQuarterYear(e, "year");
                    props.onYearChange(e.year());

                    setYearPickerOpen(false);
                  }}
                />
              </Col>
            </React.Fragment>
          ) : dateType === "quaterly" ? (
            <React.Fragment>
              <Col span={7}>
                <DatePicker
                  noBorder
                  allowClear={false}
                  open={yearPickerOpen}
                  onOpenChange={open => setYearPickerOpen(open)}
                  modalField
                  className="no-mr"
                  name="year"
                  value={
                    props.year
                      ? moment().year(props.year)
                      : undefined
                  }
                  placeholder="Month"
                  //onChange={e => mainForm.customHandleChange(e, "year")}
                  //onFocus={() => setYearPickerOpen(true)}
                  // onBlur={e => {
                  //   mainForm.customHandleBlur(e, "year");
                  //   setYearPickerOpen(false);
                  // }}
                  style={{ width: "100%" }}
                  //picker="year"
                  format="YYYY"
                  mode="year"
                  placeholder="Year"
                  onPanelChange={e => {
                    // if (!mainForm.values.date_type === "quaterly")
                    //   mainForm.customHandleChange(e.year(), "year");
                    // else mainForm.onChangeQuarterYear(e, "year");
                    props.onQuarterYearChange(e);

                    setYearPickerOpen(false);
                  }}
                />
              </Col>
              <Col span={7}>
                <Select
                  className="no-mr"
                  name="quarter"
                  placeholder="Quarter"
                  noBorder
                  value={props.quarter}
                  onChange={e => props.onQuarterChange(e)}
                  //onBlur={e => mainForm.customHandleBlur(e, "quarter")}
                  style={{ width: "100%" }}
                >
                  <Option value="q1">Q1</Option>
                  <Option value="q2">Q2</Option>
                  <Option value="q3">Q3</Option>
                  <Option value="q4">Q4</Option>
                </Select>
              </Col>
            </React.Fragment>
          ) : dateType === "custom" ? (
            <React.Fragment>
              <Col span={7}>
                <DatePicker
                  noBorder
                  allowClear={false}
                  //open={yearPickerOpen}
                  //onOpenChange={open => setYearPickerOpen(open)}
                  modalField
                  className="no-mr"
                  name="start_date"
                  value={props.startDate}
                  //onChange={e => mainForm.customHandleChange(e, "year")}
                  //onFocus={() => setYearPickerOpen(true)}
                  // onBlur={e => {
                  //   mainForm.customHandleBlur(e, "start_date");
                  //   //setYearPickerOpen(false);
                  // }}
                  style={{ width: "100%" }}
                  //picker="year"
                  format="MM-DD-YYYY"
                  //mode="year"
                  placeholder="Start Date"
                  onChange={e => {
                    // if(!mainForm.values.date_type === "quaterly")
                    //   mainForm.customHandleChange(e.year(), "year")
                    // else
                    //   mainForm.onChangeQuarterYear(e, "year")
                    //mainForm.onStartDateChange(e, "start_date");
                    props.onStartDateChange(e);

                    //setYearPickerOpen(false);
                  }}
                />
              </Col>
              <Col span={7}>
                <DatePicker
                  noBorder
                  allowClear={false}
                  //open={yearPickerOpen}
                  //onOpenChange={open => setYearPickerOpen(open)}
                  modalField
                  className="no-mr"
                  name="end_date"
                  value={props.endDate}
                  //onChange={e => mainForm.customHandleChange(e, "year")}
                  //onFocus={() => setYearPickerOpen(true)}
                  // onBlur={e => {
                  //   mainForm.customHandleBlur(e, "start_date");
                  //   //setYearPickerOpen(false);
                  // }}
                  style={{ width: "100%" }}
                  //picker="year"
                  format="MM-DD-YYYY"
                  //mode="year"
                  placeholder="End Date"
                  onChange={e => {
                    // if(!mainForm.values.date_type === "quaterly")
                    //   mainForm.customHandleChange(e.year(), "year")
                    // else
                    //   mainForm.onChangeQuarterYear(e, "year")
                    //mainForm.onStartDateChange(e, "start_date");
                    // console.log("eonchange:", e);
                    // let date = new Date(e);
                    // let compareDate = new Date(props.startDate);
                    // console.log("eonchange:", date, compareDate);
                    if(e.isBefore(props.startDate) || (e.isSame(props.startDate,'year') && e.isSame(props.startDate,'month') && e.isSame(props.startDate,'day'))) {
                      Info("warning", "End date must be after start date");
                      return;
                    }
                    props.onEndDateChange(e);

                    //setYearPickerOpen(false);
                  }}
                />
              </Col>
            </React.Fragment>
          )
          // ) : dateType === "alldata" ? (
          //   <React.Fragment>
          //     <Col span={7}>
          //       <DatePicker
          //         noBorder
          //         allowClear={false}
          //         //open={yearPickerOpen}
          //         //onOpenChange={open => setYearPickerOpen(open)}
          //         modalField
          //         className="no-mr"
          //         name="start_date"
          //         value={props.startDate}
          //         //onChange={e => mainForm.customHandleChange(e, "year")}
          //         //onFocus={() => setYearPickerOpen(true)}
          //         // onBlur={e => {
          //         //   mainForm.customHandleBlur(e, "start_date");
          //         //   //setYearPickerOpen(false);
          //         // }}
          //         style={{ width: "100%" }}
          //         //picker="year"
          //         format="MM-DD-YYYY"
          //         //mode="year"
          //         placeholder="Start Date"
          //         onChange={e => {
          //           // if(!mainForm.values.date_type === "quaterly")
          //           //   mainForm.customHandleChange(e.year(), "year")
          //           // else
          //           //   mainForm.onChangeQuarterYear(e, "year")
          //           //mainForm.onStartDateChange(e, "start_date");
          //           props.onStartDateChange(e);

          //           //setYearPickerOpen(false);
          //         }}
          //       />
          //     </Col>
          //     <Col span={7}>
          //       <DatePicker
          //         noBorder
          //         allowClear={false}
          //         //open={yearPickerOpen}
          //         //onOpenChange={open => setYearPickerOpen(open)}
          //         modalField
          //         className="no-mr"
          //         name="end_date"
          //         value={props.endDate}
          //         //onChange={e => mainForm.customHandleChange(e, "year")}
          //         //onFocus={() => setYearPickerOpen(true)}
          //         // onBlur={e => {
          //         //   mainForm.customHandleBlur(e, "start_date");
          //         //   //setYearPickerOpen(false);
          //         // }}
          //         style={{ width: "100%" }}
          //         //picker="year"
          //         format="MM-DD-YYYY"
          //         //mode="year"
          //         placeholder="End Date"
          //         onChange={e => {
          //           // if(!mainForm.values.date_type === "quaterly")
          //           //   mainForm.customHandleChange(e.year(), "year")
          //           // else
          //           //   mainForm.onChangeQuarterYear(e, "year")
          //           //mainForm.onStartDateChange(e, "start_date");
          //           if(e.isBefore(props.startDate)) {
          //             Info("warning", "End date must be after start date");
          //             return;
          //           }
          //           props.onEndDateChange(e);

          //           //setYearPickerOpen(false);
          //         }}
          //       />
          //     </Col>
          //   </React.Fragment>
          // )
          : null}
          {/* <Col span={5}>
          <Button
          className="fullWidth"
          // onClick={()=> {
            
          //     console.log("IS CLICKED");
            
          //   <Charts isClicked={true}/>
          // }}
          >
            View Chart
          </Button>
          </Col> */}
          <Col span={10}>
            <Button
              block
              className="fullWidth"
              disabled={
                !(
                  props.bankId &&
                  (props.accountId || props.customerId || props.jobId)
                ) || !props.dataAvailable
              }
              onClick={() => {
                history.push({
                  pathname: "/reports/preview-chart-report",
                  state: {
                    referrer: "/charts",
                    referrerState: {
                      bankId: props.bankId,
                      lawyerId: props.lawyerId,
                      accountId: props.accountId,
                      propertyId: props.jobId,
                      tenantId: props.customerId,
                      bankName: props.bankName,
                      lawyerName: props.lawyerName,
                      accountName: props.accountName,
                      tenantName: props.customerName,
                      propertyName: props.jobName,
                      month: props.month,
                      year: props.year,
                      quarter: props.quarter,
                      startDay: props.startDay,
                      startMonth: props.startMonth,
                      startYear: props.startYear,
                      endDay: props.endDay,
                      endMonth: props.endMonth,
                      endYear: props.endYear,
                      dateType: props.dateType
                    },
                    params: {
                      bank_account_id: props.bankId,
                      lawyer_id: props.lawyerId,
                      account_head_id: props.accountId,
                      property_id: props.jobId,
                      tenant_id: props.customerId,
                      month: props.month,
                      year: props.year,
                      start_day: props.startDay,
                      start_month: props.startMonth,
                      start_year: props.startYear,
                      end_day: props.endDay,
                      end_month: props.endMonth,
                      end_year: props.endYear,
                      // start_date: props.startDate,
                      // end_date: props.endDate,
                      date_type: props.dateType
                    }
                    //report,
                    //format
                  }
                });
              }}
            >
              Export Data
            </Button>
          </Col>
        </Row>
      </FilterWrapper>
    </React.Fragment>
  );
};

export default ChartFilters;
