import React from "react";
import styled from "styled-components";
import { Upload, Modal, message } from "antd";
import Icon from "../Icon";
import { FiUpload } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";

const UploadWrapper = styled(Upload)`
  & .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    border: 1px dashed ${props => props.theme[props.theme.mode].primary.main};
    border-radius: 5px;
    opacity: 1;
    background: #ffffff 0% 0% no-repeat;
  }
  & .ant-upload.ant-upload-select-picture-card > .ant-upload {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .styleIconText {
    color: ${props => props.theme[props.theme.mode].primary.main};
  }

  .styleIconText svg {
  }

  .deleteButton {
    color: white;
    width: 25px;
    height: 25px;
    background-color: red;
    border-radius: 5px;
    padding: 5px;
  }

  & .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    height: 9rem;
    margin: 0;
  }
  /* 
& .ant-upload.ant-upload-select-picture-card:hover {
    color:"black"
  } */
`;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  let a = reader.readAsDataURL(img);
  console.log("image", a);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

class ImgaeUpload extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      imageUrl: props.initialPreview,
      
      previewVisible: false,
      previewImage: '',

      loading: false
    };
  }

  handleCancel = () => this.setState({ previewVisible: false });
  handleExport = () => {

  }
  handlePrint = () => {
    
  }
  handlePreview = url => {
    // if (!file.url && !file.preview) {
    //   file.preview = await getBase64(file.originFileObj);
    // }

    this.setState({
      previewImage: url,
      previewVisible: true,
    });
  };

  handleChange = info => {
    console.log("handleChange", info);
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>{
        this.setState({
          imageUrl,
          loading: false
        })
        console.log("URLIS: ", imageUrl);
        localStorage.setItem("imageURL", imageUrl);
      });
    }
  };

  render() {
    console.log("props upload Image", this.props);
    const uploadButton = (
      <div className="styleIconText">
        {this.state.loading ? (
          <Icon type="loading" style={{ fontSize: "1.5rem" }} />
        ) : (
          <FiUpload />
        )}
        <span className="ant-upload-text" style={{ marginLeft: 10 }}>
          {this.props.btnText}
        </span>
      </div>
    );
    const { previewVisible, previewImage, imageUrl } = this.state;
    return (
      <div>
        <UploadWrapper
          showUploadList={false}
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          beforeUpload={beforeUpload}
          //onPreview={this.handlePreview}
          {...this.props}
          onChange={e => {
            this.handleChange(e);
            if (this.props.onChange) {
              console.log("ghx", e.file);
              this.props.onChange(e.file);
            }
          }}
        >
          {imageUrl ? (
            <div
              style={{ position: "relative", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
              onClick={e => {
                e.stopPropagation(e);
                this.handlePreview(imageUrl);
              }}
            >
              <div
                style={{
                  position: "absolute",
                  //top: 0,
                  bottom: 0,
                  right: 0,
                  //left: 0,
                  transform: "translate(-12.5px, -12.5px)",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {!this.props.disabled && <AiOutlineDelete
                  className="deleteButton"
                  style={{ position: "absolute" }}
                  onClick={(e) => {
                    e.stopPropagation(e);
                    this.setState({ imageUrl: "" });
                    if (this.props.onChange) {
                      this.props.onChange("");
                    }
                  }}
                />}
              </div>
              <img src={imageUrl} alt="avatar" style={{ width: 100 }} />
            </div>
          ) : (
            uploadButton
          )}
        </UploadWrapper>
        <Modal className="imagePreviewModal" visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

export default ImgaeUpload;
