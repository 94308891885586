import * as Yup from "yup";

export const signUpShema = Yup.object().shape({
  full_name: Yup.string()
    // .min(2, "Full Name is Too Short! it must be between 2 to 50 characters")
    // .max(50, "Full Name is Too Long! it must be between 2 to 50 characters")
    // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    // .required("Full Name Required"),
    .min(2, "Full Name is Too Short! it must be between 2 to 60 characters")
    .max(60, "Full Name is Too Long! it must be between 2 to 60 characters")
    .matches(/^[a-z0-9.\s]+$/i, "Only alphabets digits and periods are allowed")
    .required("Full Name Required"),
  email: Yup.string()
    .email("Email is not valid!")
    .matches(
      /^([0-9a-zA-Z]+[.-_]{0,1})+@([0-9a-zA-Z][0-9a-zA-Z-]+\.)+[a-zA-Z]{2,}$/i,
      "Email is not valid!"
    )
    .required("Email Required!"),
  password: Yup.string()
    .min(6, "Password is Too Short! it must be between 6 and 16 characters")
    .max(16, "Password is Too Long! it must be between 6 and 16 characters")
    // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z0-9\W\S]{8,16}$/, 'Must contain one uppercase and one lowercase.')
    .matches(/^[a-zA-Z0-9\W\S]{6,16}$/, "atleast contain six charachters.")
    .required("Password Required"),

  confirm_password: Yup.mixed()
    .oneOf([Yup.ref("password")], "Password does not match!")
    .required("Confirm Password Required"),
  termsAndConditions: Yup.boolean().oneOf(
    [true],
    "Must Accept Terms and Conditions"
  ),
});
