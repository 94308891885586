// import React from "react";
// import { DatePicker } from "antd";
// import styled, { css } from "styled-components";

// // const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

// const DatePickerStyles =
//   //styled(MonthPicker).attrs(() => ({ size: "large" }))
//   css`
// &.ant-calendar-picker-container {
// z-index:99;
// }
// //   color: palevioletred;
// //   font-weight: bold;
// //   box-shadow: 0 0 0 2px rgba(0, 0, 0, 1);


// &.ant-calendar-picker {
//   ${props => (props.block ? `width: 100%;` : null)}
// }

// &:hover input {
//   border: 1px solid ${props => props.theme[props.theme.mode].primary.main};
//   border-color: ${props =>
//     props.theme[props.theme.mode].primary.light} !important;
// }
// input {
//     background-color: ${props =>
//       props.theme[props.theme.mode].background.primary};
//     color: ${props => props.theme[props.theme.mode].textColor.primary};
//     border: 1px solid ${props => props.theme[props.theme.mode].primary.main}66;
//     //${props => (props.appStyles ? props.appStyles : null)};
//     // padding-top: 18px;
//     // padding-bottom: 18px;
// }

// input:hover {
//   border: 1px solid ${props => props.theme[props.theme.mode].primary.light};
//   border-color: ${props =>
//     props.theme[props.theme.mode].primary.main} !important;
// }

// input:focus {
//   border: 1px solid ${props => props.theme[props.theme.mode].primary.main};
//   border-color: ${props =>
//     props.theme[props.theme.mode].primary.main} !important;
//   box-shadow: 0 0 0 2px ${props =>
//     props.theme[props.theme.mode].primary.main}66;
// }

// .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
//   color: ${props =>
//     props.theme[props.theme.mode].textColor.secondary} !important;
//   background: ${props => props.theme[props.theme.mode].primary.main} !important;
// }

// .ant-calendar-month-panel-cell[class="ant-calendar-month-panel-cell"] .ant-calendar-month-panel-month:hover,
// .ant-calendar-month-panel-current-cell:not(.ant-calendar-month-panel-selected-cell) .ant-calendar-month-panel-month:hover {
//   color: ${props => props.theme[props.theme.mode].primary.light} !important;
//   background: ${props =>
//     props.theme[props.theme.mode].background.primary} !important;
// }

// .ant-calendar-month-panel-year-select:hover {
//   color: ${props => props.theme[props.theme.mode].primary.light} !important;
// }


// .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
//   color: ${props =>
//     props.theme[props.theme.mode].textColor.secondary} !important;
//   background: ${props => props.theme[props.theme.mode].primary.main} !important;
// }

// .ant-calendar-year-panel-cell:not(.ant-calendar-year-panel-selected-cell) .ant-calendar-year-panel-year:hover {
//   color: ${props => props.theme[props.theme.mode].primary.main} !important;
//   background: ${props => props.theme[props.theme.mode].primary.main} !important;
// }

// .ant-calendar-year-panel-decade-select:hover {
//   color: ${props => props.theme[props.theme.mode].primary.main} !important;
// }


// .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
//   color: ${props =>
//     props.theme[props.theme.mode].textColor.secondary} !important;
//   background: ${props => props.theme[props.theme.mode].primary.main} !important;
// }


// .ant-calendar-decade-panel-cell:not(.ant-calendar-decade-panel-selected-cell) .ant-calendar-decade-panel-decade:hover {
//   color: ${props => props.theme[props.theme.mode].primary.main} !important;
//   background: ${props => props.theme[props.theme.mode].primary.main} !important;
// }

// /* .ant-calendar-header a:hover {
//   color: ${props => props.theme[props.theme.mode].primary.main} !important;
// } */












// `;

// const DatePickerWrapper = props => {
//   return <DatePicker dropdownClassName={props.className} {...props} />;
// };
// export default styled(DatePickerWrapper).attrs({ className: "inputField" })`
//   ${DatePickerStyles}
// `;

// // cb funtion recieverd from props to get the selected date
// // function onChange(date, dateString) {
// //   console.log(date, dateString);
// // }












import React from "react";
import { DatePicker } from 'antd';
import styled, { css } from "styled-components";
// const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const DatePickerStyles = 
//styled(MonthPicker).attrs(() => ({ size: "large" }))
css`
&.ant-calendar-picker-container {
z-index:${props => props.modalField ? 2000 : 99};
}
//   color: palevioletred;
//   font-weight: bold;
//   box-shadow: 0 0 0 2px rgba(0, 0, 0, 1);


&.ant-calendar-picker {
  ${props => (props.block ? `width: 100%;` : null)}
}

&:hover input {
  border: 1px solid ${props => props.theme[props.theme.mode].primary.main};
  border-color: ${props =>
    props.theme[props.theme.mode].primary.light} !important;
}
&& input {
    background-color: ${props =>
      props.theme[props.theme.mode].background.primary};
    color: ${props => props.theme[props.theme.mode].textColor.primary};
    border: 1px solid ${props => props.theme[props.theme.mode].primary.main}66;
    padding: calc(0.7vw) 0.8vw;
    //${props => (props.appStyles ? props.appStyles : null)};
    // padding-top: 18px;
    // padding-bottom: 18px;
}

input:hover {
  border: 1px solid ${props => props.theme[props.theme.mode].primary.light};
  border-color: ${props =>
    props.theme[props.theme.mode].primary.main} !important;
}

input:focus {
  border: 1px solid ${props => props.theme[props.theme.mode].primary.main};
  border-color: ${props =>
    props.theme[props.theme.mode].primary.main} !important;
  box-shadow: 0 0 0 2px ${props =>
    props.theme[props.theme.mode].primary.main}66;
}

${props => props.noBorder ? css`
  &&& input {
    padding: calc(0.7vw + 1px) 0.8vw;
  }

  &&& input,
  &&& input:hover,
  &&& input:active,
  &&& input:focus,
  &&&:hover input {
    border: none;
  box-shadow: ${props=>props.noShadow? "0 0 0":"0 0.3rem 1rem"}
      ${props => props.theme[props.theme.mode].textColor.hint}0C;
    border-radius: 0.25rem;
    
  }

  @media screen and (max-width: 991px) {
    &&& input {
      padding: calc(.5rem + 1px);
    }
  }
` : null}

/* .ant-calendar-header a:hover {
  color: ${props => props.theme[props.theme.mode].primary.main} !important;
} */
.ant-calendar-my-select a:hover {
  color: ${props => props.theme[props.theme.mode].primary.main};
}
.ant-calendar-date:hover {
  background-color: #efad4e;
} 
.ant-calendar-date:active {
  background-color: ${props => props.theme[props.theme.mode].primary.main};
  color: ${props => props.theme[props.theme.mode].common.white};
} 
.ant-calendar-selected-date .ant-calendar-date, .ant-calendar-selected-day .ant-calendar-date {
  background-color: ${props => props.theme[props.theme.mode].primary.main};
  color: ${props => props.theme[props.theme.mode].common.white};
}
.ant-calendar-today:not(.ant-calendar-selected-day) .ant-calendar-date {
  color: ${props => props.theme[props.theme.mode].primary.main};
  border-color: ${props => props.theme[props.theme.mode].primary.main};
}
.ant-calendar-today.ant-calendar-selected-day .ant-calendar-date {
  border: 1px solid transparent;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  color: ${props => props.theme[props.theme.mode].textColor.inverted} !important;
  background: ${props => props.theme[props.theme.mode].primary.main} !important;
}
.ant-calendar-month-panel-cell[class="ant-calendar-month-panel-cell"] .ant-calendar-month-panel-month:hover,
.ant-calendar-month-panel-current-cell:not(.ant-calendar-month-panel-selected-cell) .ant-calendar-month-panel-month:hover {
  color: ${props => props.theme[props.theme.mode].primary.main} !important;
  background: ${props => props.theme[props.theme.mode].primary.light} !important;
}
.ant-calendar-month-panel-year-select:hover {
  color: ${props => props.theme[props.theme.mode].primary.main} !important;
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
  color: black !important;
  background: ${props => props.theme[props.theme.mode].primary.main} !important;
}
.ant-calendar-year-panel-cell:not(.ant-calendar-year-panel-selected-cell) .ant-calendar-year-panel-year:hover {
  color: black !important;
  background: ${props => props.theme[props.theme.mode].primary.light} !important;
}
.ant-calendar-year-panel-decade-select:hover {
  color: ${props => props.theme[props.theme.mode].primary.main} !important;
}
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
  color: ${props => props.theme[props.theme.mode].textColor.inverted} !important;
  background: ${props => props.theme[props.theme.mode].primary.main} !important;
}
.ant-calendar-decade-panel-cell:not(.ant-calendar-decade-panel-selected-cell) .ant-calendar-decade-panel-decade:hover {
  color: ${props => props.theme[props.theme.mode].primary.main} !important;
  background: ${props => props.theme[props.theme.mode].primary.light} !important;
}
& .ant-calendar-today-btn{
  color: ${props => props.theme[props.theme.mode].primary.main} !important;
} 
svg {
    color: ${props => props.theme[props.theme.mode].primary.main};
}

@media screen and (max-width: 991px) {
  && input {
    padding: calc(.5rem);
  }
}
`;
const DatePickerWrapper = props => {
  return <DatePicker dropdownClassName={props.className} {...props}/>;
};
export default styled(DatePickerWrapper).attrs({className: 'inputField'})`${DatePickerStyles}`;