import React, { Fragment, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import Button from "../../../components/button";
import HeaderWrapper from "../../../components/header/index";
import useWithdrawForm from "../../../appcomponents/EscrowTrakker/forms/useWithdrawForm";
import moment from "moment";
import printJS from 'print-js';
import BankFieldName from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/BankName";
import OptionsField from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/OptionsField";
import AccountNameField from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/AccountName";
import Field from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/index";
import DatePicker from "../../../components/datepickers/DatePicker";
import JobNameField from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/JobName";
import Image from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/uploadImageWithTitle";
import Switch from "../../../components/switch/index";
import TextArea from "../../../components/input/TextArea";
import Split from "../../../appcomponents/EscrowTrakker/formcontrols/splits";
import { openModal, closeAllModal } from "../../../actions/Modal";
import Job from "../addtranscation/Job";
import { updateTransactionRequest } from "../../../actions/transaction/Deposit&WithDrawlTransaction";
import AddAccountName from "../addtranscation/AddAccountName";

import TransactionTypeCard from "../../../appcomponents/EscrowTrakker/edittransaction/TransactionTypeCard";
import ClearedCard from "../../../appcomponents/EscrowTrakker/edittransaction/ClearedCard";

const Withdraw = props => {
  let { transaction, edit, referrerState } = props;
    let ab = JSON.parse(localStorage.getItem("isCleared")) ? JSON.parse(localStorage.getItem("isCleared")) : "" ;
  const [checkTrue, setCheckTrue] = useState(ab[props?.match?.params?.id]?.[0] ? true : false);
  const [accountID, setAccountID] = useState("");
  const [accountName, setAccountName] = useState(undefined);
  const mainForm = useWithdrawForm({
    initialValues: {
      bankName: transaction[0].bank_account_id,
      tenantName: transaction[0].tenant_id,
      propertyName: transaction[0].property_id,
      amount: transaction.reduce((total, curr) => total + parseFloat(curr.amount), 0),

      ...(transaction[0].is_split ? {
        splits: transaction.map(val => ({
          amount: val.amount,
          account_head_id: val.account_head_id
          //job_id: val.job_id
        }))
      } : 
      {
        accountName: transaction[0].account_head_id,
        accountNameString: transaction[0].account_name
        //jobName: transaction[0].job_id
      }),

      payee: transaction[0].payor_id,
      dateOfTransaction: transaction[0].date_of_transaction ? moment(transaction[0].date_of_transaction) : "",
      checkNumber: transaction[0].check_number,
      cleared: transaction[0].is_cleared === true,
      additionalNote: transaction[0].additional_note,
      image: transaction[0].image_url ? { image_url: transaction[0].image_url, thumb_image_url: transaction[0].thumb_image_url } : "",
      type: "withdrawal"
    },
    //validationSchema: deposit,
    //apiCall: { addTransactionRequest },

    onSubmit: values => props.updateTransactionRequest(values, props.match.params.id, () => {
      if(props?.location?.state?.referrer=="/reconcile")
      {
        props.history.push({
          pathname: `/reconcile`,
          state: {
            referrer: props.location.pathname,
            ...referrerState, 
            clearedBalance: (checkTrue && values.cleared==false) ? ((props?.location?.state?.referrerState?.clearedBalance)-(values.amount-(props?.location?.state?.referrerState?.clearedBalance))) : props?.location?.state?.referrerState?.clearedBalance
          }})
      }
      else{
      props.history.push({
        pathname: `/view-transaction/${props.match.params.id}`,
        state: props.location.state
      })}
    })
  });

  console.log("FORM", mainForm)
  return (
    <Row type="flex" gutter={[30, 20]}>
      <Col span={24}>
        <HeaderWrapper
        maintainSpans
        bisect
         rightAlign={[
          <Button
          loading={props.loading}
          onClick={() => {
            if(props.edit)
              mainForm.handleSubmit();
            else {
              props.history.push({
                pathname: `/edit-transaction/${props.match.params.id}`,
                state: props.location.state
              })
            }
          }}
          >
            {props.edit ? "Save Changes" : "Edit Transaction"}
          </Button>
        ]} 
         externalscreen={1} 
         />
      </Col>
      <Col xs={24} md={8}>
        <Row type="flex" gutter={[0, 20]}>
          <Col span={24}>
            <TransactionTypeCard type="Withdrawal" />
          </Col>
          <Col span={24}>
            <ClearedCard
              name="cleared"
              disabled={!edit}
              checked={mainForm.values.cleared}
              onChange={e => mainForm.customHandleChange(e, "cleared")}
              onBlur={e => mainForm.customHandleBlur(e, "cleared")}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} md={16} lg={12}>
        <Row type="flex" gutter={[20, 20]}>
          <Col xs={24} sm={12}>
            <BankFieldName
              title="Bank Account Name"
              placeholder="Select Bank Account"
              addBtnText="Add Bank Account"
              disabled={!edit}
              initialValue={transaction[0].bank_account_name}
              name="bankName"
              link="/add-bank"
              onChange={e => mainForm.customHandleChange(e, "bankName")}
              onBlur={e => mainForm.customHandleBlur(e, "bankName")}
            />

            <span className="dangerColor caption textCenter">
              {mainForm.touched["bankName"] && mainForm.errors["bankName"]}
            </span>
          </Col>

          <Col xs={24} sm={12}>
            <OptionsField
              disabled={!edit}
              title="Tenant Name"
              addBtnText="Add Tenant"
              name="tenantName"
              initialValue={transaction[0].tenant_name}
              link="/add-contact"
              contactType="tenant"
              placeholder="Select Tenant"
              onChange={e => mainForm.customHandleChange(e, "tenantName")}
              onBlur={e => mainForm.customHandleBlur(e, "tenantName")}
            />
            <span className="dangerColor caption textCenter">
              {mainForm.touched["tenantName"] && mainForm.errors["tenantName"]}
            </span>
          </Col>

          <Col xs={24} sm={12}>
                <JobNameField
                  title="Property Name"
                  disabled={!edit}
                  addBtnText="Add Property"
                  //initialValue={transaction[0].is_split === true ? undefined : [transaction[0].property_name, transaction[0].property_id]}
                  initialValue={[transaction[0].property_name, transaction[0].property_id]}
                  placeholder="Select Property"
                  addBtnClick={() =>
                    props.openModal(null, <Job />, null, null, {
                      footer: null
                    })
                  }
                  name="propertyName"
                  onChange={e => mainForm.customHandleChange(e, "propertyName")}
                  onBlur={e => mainForm.customHandleBlur(e, "propertyName")}
                />
                <span className="dangerColor caption textCenter">
                  {mainForm.touched["propertyName"] && mainForm.errors["propertyName"]}
                </span>
              </Col> 

          <Col xs={24} sm={12}>
            <Field
            disabled={!edit}
              title="Amount"
              placeholder={`${props.settings.currency || "$"}0.00`}
              value={mainForm.values.amount}
              //onChange={mainForm.handleTotalChange}
              //onBlur={mainForm.handleBlur}
              name="amount"
              onChange={e => mainForm.customHandleChange(e, "amount")}
              onBlur={e => mainForm.customHandleBlur(e, "amount")}
            />
            <span className="dangerColor caption textCenter">
              {mainForm.touched["amount"] && mainForm.errors["amount"]}
            </span>
          </Col>

          <Col span={24}>
            <Split
            disabled={!edit}
              splits={mainForm.values.splits}
              initialValues={transaction[0].is_split === true ? 
                transaction.map(val => ({
                  account_head_name: val.account_name,
                  account_head_id: val.account_id,
                  job_name: val.job_name,
                  job_id: val.job_id
                })) : undefined
              }
              amount={mainForm.values.amount}
              remainingAmount={
                (parseFloat(mainForm.values.amount) || 0) - mainForm.splitTotal
              }
              editTransaction={true}
              errors={mainForm.errors.splits}
              touched={mainForm.touched.splits}
              onSplitChange={mainForm.handleSplitChange}
              onSplitBlur={mainForm.handleSplitBlur}
              onAddSplit={mainForm.addSplit}
              onClearSplits={() => {
              mainForm.clearSplits({ accountName: accountID, accountNameString: accountName });
              }}
              accountType="expense"
            />
            <div className="dangerColor caption textCenter">
              {mainForm.touched["is_split"] && mainForm.errors["noOfSplits"]}
            </div>
            <div className="dangerColor caption textCenter">
              {mainForm.touched["is_split"] &&
                mainForm.errors["remainingAmount"]}
            </div>
          </Col>

          {!(mainForm.values.splits && mainForm.values.splits.length) ? (
            <React.Fragment>
              <Col xs={24} sm={12}>
                <AccountNameField
                  title="Account Name"
                  disabled={!edit}
                  addBtnText="Add Account Name"
                  accValue={[mainForm.values.accountNameString, mainForm.values.accountName]}
                  accountType="expense"
                  placeholder="Select Account Name"
                  onMouseDown={e => e.preventDefault()}
                  addBtnClick={() =>
                    props.openModal(
                      null,
                      <AddAccountName accType="expense" />,
                      null,
                      null,
                      { footer: null }
                    )
                  }
                  name="accountName"
                  onChange={(e, v, name) => { 
                  mainForm.customHandleChange(e, "accountName");
                  mainForm.customHandleChange(name, "accountNameString");
                  console.log("ABVD", name); 
                  localStorage.setItem("accname", name);
                  setAccountName(name);
                  setAccountID(e);
                }}
                  onBlur={e => mainForm.customHandleBlur(e, "accountName")}
                />
                <span className="dangerColor caption textCenter">
                  {mainForm.touched["accountName"] &&
                    mainForm.errors["accountName"]}
                </span>
              </Col>

              {/* <Col xs={24} sm={12}>
                <JobNameField
                  title="Job Name"
                  disabled={!edit}
                  addBtnText="Add Job Name"
                  initialValue={transaction[0].is_split === true ? undefined : [transaction[0].job_name, transaction[0].job_id]}
                  placeholder="Select Job"
                  addBtnClick={() =>
                    props.openModal(null, <Job />, null, null, {
                      footer: null
                    })
                  }
                  name="jobName"
                  onChange={e => mainForm.customHandleChange(e, "jobName")}
                  onBlur={e => mainForm.customHandleBlur(e, "jobName")}
                />
                <span className="dangerColor caption textCenter">
                  {mainForm.touched["jobName"] && mainForm.errors["jobName"]}
                </span>
              </Col> */}
            </React.Fragment>
          ) : null}

          <Col xs={24} sm={12}>
            <OptionsField
              title="Payee"
              disabled={!edit}
              addBtnText="Add Payee"
              initialValue={transaction[0].payor_name}
              customerValue={mainForm.values.customer}
              link="/add-contact"
              contactType="payor"
              placeholder="Select Payee"
              name="payee"
              onChange={e => mainForm.customHandleChange(e, "payee")}
              onBlur={e => mainForm.customHandleBlur(e, "payee")}
            />

            <span className="dangerColor caption textCenter">
              {mainForm.touched["payee"] && mainForm.errors["payee"]}
            </span>
          </Col>

          <Col xs={24} sm={12}>
            <Field
              title="Date Of Transaction"
              disabled={!edit}
              customInput={DatePicker}
              value={mainForm.values.dateOfTransaction}
              placeholder="MM/DD/YY"
              format="MM-DD-YY"
              name="dateOfTransaction"
              onChange={e =>
                mainForm.customHandleChange(e, "dateOfTransaction")
              }
              onBlur={e => mainForm.customHandleBlur(e, "dateOfTransaction")}
            />
            <span className="dangerColor caption textCenter">
              {mainForm.touched["dateOfTransaction"] &&
                mainForm.errors["dateOfTransaction"]}
            </span>
          </Col>

          <Col span={24}>
            <Row type="flex" gutter={20}>
              <Col xs={24} sm={12}>
                <Row type="flex" gutter={[0, 20]}>
                  <Col span={24}>
                    <Field
                      title="Check Number"
                      placeholder="Check Number"
                      disabled={!edit}
                      value={mainForm.values.checkNumber}
                      name="checkNumber"
                      onChange={e =>
                        mainForm.customHandleChange(e, "checkNumber")
                      }
                      onBlur={e => mainForm.customHandleBlur(e, "checkNumber")}
                    />
                    <span className="dangerColor caption textCenter">
                      {mainForm.touched["checkNumber"] &&
                        mainForm.errors["checkNumber"]}
                    </span>
                  </Col>

                  <Col span={24}>
                    <Field
                      title="Additional Notes"
                      disabled={!edit}
                      value={mainForm.values.additionalNote}
                      //name="additionalNotex"
                      placeholder="Note"
                      style={{ height: "8.8rem" }}
                      customInput={TextArea}
                      name="additionalNote"
                      onChange={e =>
                        mainForm.customHandleChange(e, "additionalNote")
                      }
                      onBlur={e => mainForm.customHandleBlur(e, "additionalNote")}
                    />

                    <span className="dangerColor caption textCenter">
                      {mainForm.touched["additionalNote"] &&
                        mainForm.errors["additionalNote"]}
                    </span>
                  </Col>
                </Row>
              </Col>

              <Col xs={24} sm={12}>
              <Row type="flex" gutter={[10, 10]} justify="center">
                  <Col span={24}>
                    <Image
                      title="Image"
                      btnText="Upload Image"
                      listType="picture-card"
                      initialPreview={transaction[0].image_url}
                      disabled={!edit}
                      // style={{ height: "9rem" }}
                      className="avatar-uploader"
                      name="image"
                      onChange={e =>
                        mainForm.customHandleChange(e.originFileObj, "image")
                      }
                      //  onBlur={e => mainForm.customHandleBlur(e, "image")}
                    />
                    <span className="dangerColor caption textCenter">
                      {mainForm.touched["image"] && mainForm.errors["image"]}
                    </span>
                  </Col>
                  <Col span={8}>
                    <Button
                    block
                      //disabled={!transaction[0].image_url}
                      disabled={!mainForm.values.image}
                      //loading={props.loading}
                      onClick={() => {
                          props.history.push({
                          //pathname: `/reports/send-report/${props.match.params.id}`,
                          pathname: `/send-transactionimage`,
                          state: {
                            referrer: props.location.pathname,
                            referrerState: props.location.state,
                            image: transaction[0].image_url
                          }
                          //format: "pdf"
                          })
                          // props.openModal(null, <ExportImage />, null, null, {
                          //   footer: null
                          // })
                      }}
                      >
                        {/* {props.edit ? "Save Changes" : "Edit Transaction"} */}Export Image
                    </Button>
                  </Col>
                  <Col span={8}>
                  {/* <ReactToPrint
                    trigger={() => <a href="#">Print</a>}
                      //<Button
                      // block
                      //   //loading={props.loading}
                      // //   onClick={() => {
                      // //     props.history.push({
                      // //     //pathname: `/reports/send-report/${props.match.params.id}`,
                      // //     //pathname: `/reports/send-report`,
                      // //     //state: props.location.state,
                      // //     data: transaction[0].image_url
                      // //     //format: "pdf"
                      // //     })
                      // //     // props.openModal(null, <ExportImage />, null, null, {
                      // //     //   footer: null
                      // //     // })
                      // // }}
                      //   >
                      //     {/* {props.edit ? "Save Changes" : "Edit Transaction"} }Print Image
                      // </Button>}
                    content={transaction[0].image_url}
                      //transaction[0].image_url}
                  />
                  <Deposit ref={el => (this.componentRef = el)} /> */}
                    <Button
                    block
                    //disabled={!transaction[0].image_url}
                    disabled={!mainForm.values.image}
                      //loading={props.loading}
                      onClick={(e)=>{e.preventDefault(); printJS(transaction[0].image_url, 'image');}}
                        //() => {
                        //props.history.push({
                        //pathname: `/reports/send-report/${props.match.params.id}`,
                        //pathname: `/reports/send-report`,
                        //state: props.location.state,
                        //data: transaction[0].image_url
                        //format: "pdf"
                        //})
                        // props.openModal(null, <ExportImage />, null, null, {
                        //   footer: null
                        // })
                   // }}
                      >
                        {/* {props.edit ? "Save Changes" : "Edit Transaction"} */}Print Image
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default withRouter(
  connect(
    storeState => ({
      AddBankAccount: storeState.AddBankAccount,
      loading: storeState.transaction.updateTransactionLoading, 
      settings: storeState.settings.settings
    }),
    {
      // getBankAccountsRequest
      updateTransactionRequest
    }
  )(Withdraw)
);
