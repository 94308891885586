import {
    GET_STATEMENTS_REQUEST,
    GET_STATEMENTS_SUCCESS,
  GET_STATEMENTS_ERROR,
  UPLOAD_STATEMENTS_REQUEST,
  UPLOAD_STATEMENTS_SUCCESS,
  UPLOAD_STATEMENTS_ERROR,
    DELETE_STATEMENTS_REQUEST,
    DELETE_STATEMENTS_SUCCESS,
    DELETE_STATEMENTS_ERROR,
    ADD_STATEMENTS_REQUEST,
    ADD_STATEMENTS_SUCCESS,
    ADD_STATEMENTS_ERROR
  } from "../configurations/Types";
  
  const INITIALS_STATE = {
    // for single bank trasaction
    
    // for All banks transaction
    statements: [],
    addStatementLoading: false,
    uploadStatementLoading: false,

  };
  
  export default (state = INITIALS_STATE, action) => {
    switch (action.type) {

     
  
      // GET ALL BANKS
      case GET_STATEMENTS_REQUEST: {
        return Object.assign({}, state, {
          statements: action.flag ? [] : state.statements,
          addStatementLoading: true
       
        });
      }
      case GET_STATEMENTS_SUCCESS: {
        let statements = [];
        console.log("THis is success, ", action);
        if (action.body) {
          statements = action.body.statements;
          console.log("THis is success state, ", statements);
   
        }
        console.log("THis is success state, ", statements);
        return Object.assign({}, state, {
      
          statements,
          addStatementLoading: false
       
        });
      }
      case GET_STATEMENTS_ERROR: {
        return Object.assign({}, state, {
          addStatementLoading: false
        });
      }
      
      //UPLOAD STATEMENTS 
      case UPLOAD_STATEMENTS_REQUEST: {
        return Object.assign({}, state, {
          // statements: action.flag ? [] : state.statements,
          uploadStatementLoading: true
        
        });
      }
      case UPLOAD_STATEMENTS_SUCCESS: {
        let statements = [];
        console.log("THis is success, ", action);
        if (action.body) {
          statements = action.body.statements;
          console.log("THis is success state, ", statements);
      
        }
        console.log("THis is success state, ", statements);
        return Object.assign({}, state, {
     
          uploadStatementLoading: false
         
        });
      }
      case UPLOAD_STATEMENTS_ERROR: {
        return Object.assign({}, state, {
          uploadStatementLoading: false
        });
      }
        
      default: {
        return state;
      }
    }
  };
  