import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import moment from "moment";
import useTransferForm from "../../../appcomponents/EscrowTrakker/forms/useTransferForm";
import BankFieldName from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/BankName";
import OptionsField from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/OptionsField";
import AccountNameField from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/AccountName";
import Field from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/index";
import DatePicker from "../../../components/datepickers/DatePicker";
import JobNameField from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/JobName";
import TextArea from "../../../components/input/TextArea";
import Button from "../../../components/button/index";
import Split from "../../../appcomponents/EscrowTrakker/formcontrols/splits";
// import { loginSchema } from "../../../configurations/Schemas/login";
import { openModal, closeAllModal } from "../../../actions/Modal";
import { updateTransferRequest } from "../../../actions/transaction/TransferTransaction";
import { getTransactionRequest } from "../../../actions/transaction/Transaction";
import HeaderWrapper from "../../../components/header/index";
import { getSingleBankRequest } from "../../../actions/BankAccount";
import Job from "../addtranscation/Job";

import AddAccountName from "../addtranscation/AddAccountName";

import TransactionTypeCard from "../../../appcomponents/EscrowTrakker/edittransaction/TransactionTypeCard";
import ClearedCard from "../../../appcomponents/EscrowTrakker/edittransaction/ClearedCard";

const TransferTransaction = props => {
  let { transaction, edit } = props;

  const mainForm = useTransferForm({
    initialValues: {

      bankName: transaction[0].bank_account_id,
      tenantName: transaction[0].tenant_id,
      //propertyName: transaction[0].property_id,
      accountName: transaction[0].account_head_id,
      //fromJobName: transaction[0].job_id,
      //fromAccountType: transaction[0].account_head_type,

      amount: transaction[0].amount,

      // toLawyerName: transaction[1].lawyer_id,
      // toCustomerName: transaction[1].customer_id,
      // ...(transaction[0].is_split === "true" ? {
      //   splits: transaction.slice(1).map(val => ({
      //     amount: val.amount,
      //     account_head_id: val.account_head_id,
      //     job_id: val.job_id
      //   }))
      // } : 
      // {
      //   toAccountName: transaction[1].account_head_id,
      //   toJobName: transaction[1].job_id
      // }),
      //toAccountType: transaction[1].account_head_type,
      //toAccountType: "income",

      dateOfTransaction: transaction[0].date_of_transaction ? moment(transaction[0].date_of_transaction) : "",
      //checkNumber: transaction[0].check_number,
      //cleared: transaction[0].is_cleared === "true",
      //additionalNote: transaction[0].additional_note,
      type: "interest deposit"
    },
    //validationSchema: deposit,
    onSubmit: values => props.updateTransferRequest(values, props.match.params.id, () => {
        props.history.push({
            pathname: `/view-transaction/${props.match.params.id}`,
            state: props.location.state
        });
        //props.getTransactionRequest(props.match.params.id);
    })
  });
  const getTenant = (id, cb) => {
    props.getSingleBankRequest(id, (error, data) => {
      if (error) {
        //setLoading(false);
        console.log("I AM HERE too for tenant");
      } else {
        //setTenant(data);
        console.log("this is dataish for tenant: ", data);  
        //console.log("this is dataish for tenant that is set: ", Tenant); 
        mainForm.customHandleChange(data?.bank_account?.landlord_id, "tenantName");
        mainForm.customHandleChange(data?.bank_account?.landlord_name, "tenantID");
        //localStorage.setItem("tenantNewID", data?.bank_account?.landlord_id);
        //localStorage.setItem("tenantNewName", data?.bank_account?.landlord_name);
      }
    });
}
  console.log("FORM", mainForm);
  return (
    <Row type="flex" gutter={[30, 20]}>
      <Col span={24}>
        <HeaderWrapper
        maintainSpans
        bisect
         rightAlign={[
          <Button
          loading={props.loading}
          onClick={() => {
            if(props.edit)
              mainForm.handleSubmit();
            else {
              props.history.push({
                pathname: `/edit-transaction/${props.match.params.id}`,
                state: props.location.state
              })
            }
          }}
          >
            {props.edit ? "Save Changes" : "Edit Transaction"}
          </Button>
        ]} 
         externalscreen={1} 
         />
      </Col>
      <Col xs={24} md={8}>
        <Row type="flex" gutter={[0, 20]}>
          <Col span={24}>
            <TransactionTypeCard type="interest deposit" />
          </Col>
          {/* <Col span={24}>
            <ClearedCard
              name="cleared"
              disabled={!edit}
              checked={mainForm.values.cleared}
              onChange={e => mainForm.customHandleChange(e, "cleared")}
              onBlur={e => mainForm.customHandleBlur(e, "cleared")}
            />
          </Col> */}
        </Row>
      </Col>
      <Col xs={24} md={16} lg={12}>
        <Row type="flex" gutter={[20, 20]}>
          <Col xs={24} sm={12}>
            <BankFieldName
              title="Bank Account Name"
              placeholder="Select Bank Account"
              addBtnText="Add Bank Account"
              disabled={!edit}
              initialValue={transaction[0].bank_account_name}
              name="bankName"
              link="/add-bank"
              onChange={e => {
                mainForm.customHandleChange(e, "bankName");
                getTenant(e);
              }}
              onBlur={e => mainForm.customHandleBlur(e, "bankName")}
            />

            <span className="dangerColor caption textCenter">
              {mainForm.touched["bankName"] && mainForm.errors["bankName"]}
            </span>
          </Col>

          <Col xs={24} sm={12}>
            <OptionsField
              disabled={true}
              title="Tenant Name"
              addBtnText="Add Tenant"
              name="tenantName"
              initialValue={transaction[0].tenant_name}
              propertyValue={mainForm.values.tenantID}
              link="/add-contact"
              contactType="tenant"
              placeholder="Select Tenant"
              onChange={e => mainForm.customHandleChange(e, "tenantName")}
              onBlur={e => mainForm.customHandleBlur(e, "tenantName")}
            />
            <span className="dangerColor caption textCenter">
              {mainForm.touched["tenantName"] && mainForm.errors["tenantName"]}
            </span>
          </Col>

          {/* <Col xs={24} sm={12}>
              <JobNameField
              key={formId}
                title="Property Name"
                addBtnText="Add Property Name"
                placeholder="Select Property"
                addBtnClick={() =>
                  props.openModal(null, <Job />, null, null, {
                    footer: null
                  })
                }
                name="propertyName"
                onChange={e => mainForm.customHandleChange(e, "propertyName")}
                onBlur={e => mainForm.customHandleBlur(e, "propertyName")}
              />
              <span className="dangerColor caption textCenter">
                {mainForm.touched["propertyName"] && mainForm.errors["propertyName"]}
              </span>
        </Col> */}

             {/* <Col xs={24} sm={12}>
                <JobNameField
                  title="Property Name"
                  disabled={!edit}
                  addBtnText="Add Property"
                  initialValue={transaction[0].is_split === true ? undefined : [transaction[0].property_name, transaction[0].property_id]}
                  placeholder="Select Property"
                  addBtnClick={() =>
                    props.openModal(null, <Job />, null, null, {
                      footer: null
                    })
                  }
                  name="propertyName"
                  onChange={e => mainForm.customHandleChange(e, "propertyName")}
                  onBlur={e => mainForm.customHandleBlur(e, "propertyName")}
                />
                <span className="dangerColor caption textCenter">
                  {mainForm.touched["propertyName"] && mainForm.errors["propertyName"]}
                </span>
              </Col>  */}
          {/* <Col xs={24} sm={12}>
            <OptionsField
            disabled={!edit}
              title="Property Name"
              initialValue={transaction[0].property_name}
              addBtnText="Add Property"
              link="/add-contact"
              contactType="property"
              placeholder="Select Property"
              name="propertyName"
              onChange={(e, name) => {
                mainForm.customHandleChange(e, "propertyName");
                // if(!mainForm.values.payor) {
                //   mainForm.customHandleChange(e, "payor");
                //   mainForm.customHandleChange(name, "property");
                // }
              }}
              onBlur={e => mainForm.customHandleBlur(e, "propertyName")}
            />
            <span className="dangerColor caption textCenter">
              {mainForm.touched["propertyName"] &&
                mainForm.errors["propertyName"]}
            </span>
          </Col> */}

          <Col xs={24} sm={12}>
            <Field
            disabled={!edit}
              title="Amount"
              placeholder={`${props.settings.currency || "$"}0.00`}
              value={mainForm.values.amount}
              //onChange={mainForm.handleTotalChange}
              //onBlur={mainForm.handleBlur}
              name="amount"
              onChange={e => mainForm.customHandleChange(e, "amount")}
              onBlur={e => mainForm.customHandleBlur(e, "amount")}
            />
            <span className="dangerColor caption textCenter">
              {mainForm.touched["amount"] && mainForm.errors["amount"]}
            </span>
          </Col>

          {/* <Col span={24}>
            <Split
            disabled={!edit}
              splits={mainForm.values.splits}
              initialValues={transaction[0].is_split === true ? 
                transaction.map(val => ({
                  account_head_name: val.account_head_name,
                  account_head_id: val.account_head_id,
                  job_name: val.job_name,
                  job_id: val.job_id
                })) : undefined
              }
              amount={mainForm.values.amount}
              remainingAmount={
                (parseFloat(mainForm.values.amount) || 0) - mainForm.splitTotal
              }
              errors={mainForm.errors.splits}
              touched={mainForm.touched.splits}
              onSplitChange={mainForm.handleSplitChange}
              onSplitBlur={mainForm.handleSplitBlur}
              onAddSplit={mainForm.addSplit}
              onClearSplits={mainForm.clearSplits}
              accountType="income"
            />
            <div className="dangerColor caption textCenter">
              {mainForm.touched["is_split"] && mainForm.errors["noOfSplits"]}
            </div>
            <div className="dangerColor caption textCenter">
              {mainForm.touched["is_split"] &&
                mainForm.errors["remainingAmount"]}
            </div>
          </Col> */}

          
              <Col xs={24} sm={12}>
                <AccountNameField
                  title="Account Name"
                  disabled={!edit}
                  addBtnText="Add Account Name"
                  initialValue={transaction[0].is_split === true ? undefined : [transaction[0].account_name, transaction[0].account_id]}
                  accountType="income"
                  placeholder="Select Account Name"
                  onMouseDown={e => e.preventDefault()}
                  addBtnClick={() =>
                    props.openModal(
                      null,
                      <AddAccountName accType="income" />,
                      null,
                      null,
                      { footer: null }
                    )
                  }
                  name="accountName"
                  onChange={e => mainForm.customHandleChange(e, "accountName")}
                  onBlur={e => mainForm.customHandleBlur(e, "accountName")}
                />
                <span className="dangerColor caption textCenter">
                  {mainForm.touched["accountName"] &&
                    mainForm.errors["accountName"]}
                </span>
              </Col>

              {/* <Col xs={24} sm={12}>
                <JobNameField
                  title="Job Name"
                  disabled={!edit}
                  addBtnText="Add Job Name"
                  initialValue={transaction[0].is_split === "true" ? undefined : [transaction[0].job_name, transaction[0].job_id]}
                  placeholder="Select Job"
                  addBtnClick={() =>
                    props.openModal(null, <Job />, null, null, {
                      footer: null
                    })
                  }
                  name="jobName"
                  onChange={e => mainForm.customHandleChange(e, "jobName")}
                  onBlur={e => mainForm.customHandleBlur(e, "jobName")}
                />
                <span className="dangerColor caption textCenter">
                  {mainForm.touched["jobName"] && mainForm.errors["jobName"]}
                </span>
              </Col> */}
            
          {/* <Col xs={24} sm={12}>
            <OptionsField
              title="Payor"
              disabled={!edit}
              addBtnText="Add Payor"
              initialValue={transaction[0].payor_name}
              customerValue={mainForm.values.customer}
              link="/add-contact"
              contactType="payor"
              placeholder="Select Payor"
              name="payor"
              onChange={e => mainForm.customHandleChange(e, "payor")}
              onBlur={e => mainForm.customHandleBlur(e, "payor")}
            />

            <span className="dangerColor caption textCenter">
              {mainForm.touched["payor"] && mainForm.errors["payor"]}
            </span>
          </Col> */}

          <Col xs={24} sm={12}>
            <Field
              title="Date Of Transaction"
              disabled={!edit}
              customInput={DatePicker}
              value={mainForm.values.dateOfTransaction}
              placeholder="MM/DD/YY"
              format="MM-DD-YY"
              name="dateOfTransaction"
              onChange={e =>
                mainForm.customHandleChange(e, "dateOfTransaction")
              }
              onBlur={e => mainForm.customHandleBlur(e, "dateOfTransaction")}
            />
            <span className="dangerColor caption textCenter">
              {mainForm.touched["dateOfTransaction"] &&
                mainForm.errors["dateOfTransaction"]}
            </span>
          </Col>

          {/* <Col span={24}>
            <Row type="flex" gutter={20}>
              <Col xs={24} sm={12}>
                <Row type="flex" gutter={[0, 20]}>
                  <Col span={24}>
                    <Field
                      title="Check Number"
                      placeholder="Check Number"
                      disabled={!edit}
                      value={mainForm.values.checkNumber}
                      name="checkNumber"
                      onChange={e =>
                        mainForm.customHandleChange(e, "checkNumber")
                      }
                      onBlur={e => mainForm.customHandleBlur(e, "checkNumber")}
                    />
                    <span className="dangerColor caption textCenter">
                      {mainForm.touched["checkNumber"] &&
                        mainForm.errors["checkNumber"]}
                    </span>
                  </Col>

                  <Col span={24}>
                    <Field
                      title="Additional Notes"
                      disabled={!edit}
                      value={mainForm.values.additionalNote}
                      //name="additionalNotex"
                      placeholder="Note"
                      style={{ height: "8.8rem" }}
                      customInput={TextArea}
                      name="additionalNote"
                      onChange={e =>
                        mainForm.customHandleChange(e, "additionalNote")
                      }
                      onBlur={e => mainForm.customHandleBlur(e, "additionalNote")}
                    />

                    <span className="dangerColor caption textCenter">
                      {mainForm.touched["additionalNote"] &&
                        mainForm.errors["additionalNote"]}
                    </span>
                  </Col>
                </Row>
              </Col>

              <Col xs={24} sm={12}>
                <Row type="flex">
                  <Col span={24}>
                    <Image
                      title="Image"
                      btnText="Upload Image"
                      listType="picture-card"
                      initialPreview={transaction[0].image_url}
                      disabled={!edit}
                      // style={{ height: "9rem" }}
                      className="avatar-uploader"
                      name="image"
                      onChange={e =>
                        mainForm.customHandleChange(e.originFileObj, "image")
                      }
                      //  onBlur={e => mainForm.customHandleBlur(e, "image")}
                    />
                    <span className="dangerColor caption textCenter">
                      {mainForm.touched["image"] && mainForm.errors["image"]}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col> */}
        </Row>
      </Col>
    </Row>
  );
};
const mapStateToProps = state => ({loading: state.transaction.updateTransferLoading, settings: state.settings.settings});

export default withRouter(
  connect(mapStateToProps, { updateTransferRequest, getTransactionRequest, openModal, closeAllModal, getSingleBankRequest })(TransferTransaction)
);
