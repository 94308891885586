import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { Row, Col, Divider } from "antd";
import {
  FiEdit,
  FiMoreHorizontal,
  FiAlertTriangle,
  FiInfo
} from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
// import {withRouter} from "react-router-dom"
import {
  deleteTransactionRequest,
  ClearedTransactionsRequest
} from "../../actions/SearchTransactions";
// import ScreenWidth from "../../components/hooks/useScreenWidth";
import Button from "../../components/button";
import TransferTotalCard from "../../appcomponents/EscrowTrakker/dashboard/TransferTotalCard";
import Legend from "../../appcomponents/general/Legend";
import HeaderWrapper from "../../components/header/index";
import { getBankTransactionsRequest } from "../../actions/Transaction";
import { getUserSettingRequest } from "../../actions/Settings";
import Loader from "../../components/loading";
// import TableWrapper from "../../components/react-table";
import MonthPickerFilter from "../../appcomponents/EscrowTrakker/detailbanks/FiltersTransaction";
import LinkTrasaction from "../../appcomponents/EscrowTrakker/detailbanks/LinkButtons";
///////////////////////
import moment from "moment";
import { openModal } from "../../actions/Modal";
import PopconfirmWrapper from "../../components/pop-confirm";

import TableWrapper from "../../components/react-table";
import TagWrapper from "../../components/tag";
import Switch from "../../components/switch";
import PopOver from "../../components/popover";
import YearPicker from "../../components/datepickers/DatePicker";
import BankAccountSummary from "./BankAccountSummary";
import { withRouter } from "react-router-dom";
import { AccountingFormat } from "../../configurations/Config";
import JobsName from "../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/JobName";
import OptionsField from "../../../src/appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/OptionsField";

const BankAccountDetails = props => {
  console.log("yesyes: ", localStorage.getItem("landlordName"));
  //tag color schemes
  let map = {
    DEPOSIT: "primary",
    WITHDRAWAL: "secondary",
    "interest deposit": "fourth"
    //Banned: "fourth
  };
  console.log("props match newwww: ", props);
  console.log("props match: ", props.match.params.id);
  const [selectedMonth, setSelectedMonth] = useState("");
  // single Bank Transactions state
  const [selectedYear, setSelectedYear] = useState(moment());
  const [tableChanged, setTableChanged] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [bankSummary, setBankSummary] = useState(false);
  const [filters, setFilters] = useState({
    id: props.match.params.id,
    month: props.location.state ? props.location.state.month : "",
    year: props.location.state
      ? props.location.state.year
      : moment().format("YYYY"),
    // job_id: props.location.state
    //   ? props.location.state.job && props.location.state.job.jobId
    //   : ""
    tenant_id: props.location.state
      ? props.location.state.tenant && props.location.state.tenant.tenantId
      : ""
  });

  const [bank, setBank] = useState(null);
  const [job, setJob] = useState(
    props.location.state ? props.location.state.job : null
  );
    const [tenant, setTenant] = useState("");
  // single Bank Transactions state

  // const transDetail = props.transactionDetails;
  // useEffect(() => {
  //   props.getBankTransactionsRequest(state.id);
  // }, []);
  const [state, setState] = useState({
    apiCall: (...params) => props.getBankTransactionsRequest(...params)
  });
  console.log("PARAMS ARE THIS: ", state);
  useEffect(() => {
    if (props.transactionDetails) {
      setState({
        ...state,
        ...props.transactionDetails
      });
      setBank({
        bankId: props.match.params.id,
        bankName: props.transactionDetails.bankAccName
      });
    }
  }, [props.transactionDetails]);

  useEffect(() => {
    props.getUserSettingRequest();
  }, []);

  const handleFilterChange = val => {
    //fun(val);
    setFilters({ ...filters, month: val });
  };

  // single bank transaction tables alll functions
  const handleYearChange = val => {
    // console.log("handleYearChange", val);
    // let year = date.format("YYYY");
    setOpen(false);
    setFilters({ ...filters, year: val.format("YYYY") });
    setSelectedYear(val);
  };
  const handleTenantSelect = (id, name) => {
    //fun(val);
    setFilters({ ...filters, tenant_id: id });
    //if(name===tenant_name){
      //setTenant({ tenantName: name, tenantId: id });   
    //}
    setTenant({ tenantName: name, tenantId: id });
  };

  // single bank transaction tables alll functions
  // console.log(
  //   "props.transactionDetails.bankTransactions",
  //   props.transactionDetails.bankTransactions
  // );

  // console.log(props, "componrnytt");

  // const handleFilterChange = (val, fun) => {
  //   fun(val);
  // };
  // console.log("toLocaleString()", state.outStanding);

  return (
    <Fragment>
      <Row
        type="flex"
        justify="center"
        align="middle"
        gutter={[60, 30]}
        style={{ marginBottom: 20 }}
      >
        <Col span={24}>
          <HeaderWrapper
            maintainSpans
            bisect
            externalscreen={1}
            rightAlign={[
              <Button
                bankDetail={state}
                style={{ padding: "0.596vw 0.8vw" }}
                onClick={() =>
                  props.history.push({
                    pathname: `/edit-bank/${filters.id}`
                  })
                }
              >
                Edit Bank Account
              </Button>
            ]}
          />
        </Col>
      </Row>
      {props.transactionDetails.bankTransLoading && false ? (
        <Loader />
      ) : (
        <Fragment>
          <Row type="flex" gutter={[40, 20]} justify="center" align="stretch">
            <Col xs={24} md={12} lg={8}>
              <TransferTotalCard
                loading={props.transactionDetails.bankTransLoading}
                heading={state.bankAccName}
                label={localStorage.getItem("landlordName")}
                amount={AccountingFormat(state.totalBal, state.currency)}
              />
            </Col>
            <Col xs={24} md={12} lg={8}>
              <TransferTotalCard
                loading={props.transactionDetails.bankTransLoading}
                heading="Outstanding Balance"
                amount={
                  state.outStanding < 0 ? (
                    <span style={{ color: "#FF494C" }}>
                      {AccountingFormat(state.outStanding, state.currency)}
                    </span>
                  ) : (
                    AccountingFormat(state.outStanding, state.currency)
                  )
                }
              />
            </Col>
            <Col xs={24} md={12} lg={8}>
              <TransferTotalCard
                loading={props.transactionDetails.bankTransLoading}
                heading={"Total Cleared"}
                amount={AccountingFormat(state.clearedBal, state.currency)}
              />
            </Col>
          </Row>
          {/* Bank Transaction Start */}
          <Row
            type="flex"
            align="middle"
            justify="space-between"
            gutter={[0, 20]}
            style={{ margin: "20px 0" }}
          >
            <Col xs={24} md={12} lg={8}>
              {" "}
              <LinkTrasaction bank={bank} />
            </Col>

            <Col xs={24} md={12}>
              <Row type="flex" justify="end" align="middle" gutter={[30, 20]}>
                <Col xs={24} sm={24} lg={8}>
                  {/* <JobsName
                    placeholder="All Properties"
                    noBorder
                    withoutAddButton
                    hideDeleteBtn
                    initialValue={job && [job.jobName, job.jobId]}
                    onChange={handleJobSelect}
                    withClearOption
                  /> */}
                  <OptionsField
                  //title="Tenant Name"
                  //addBtnText="Add Tenant"
                  name="tenantName"
                  //link="/add-contact"
                  withClearOption
                  contactType="tenant"
                  placeholder="Select Tenant"
                  onChange={handleTenantSelect}
                  id = {props.match.params.id}
                  isBankACC={true}
                  withoutAddButton
                  // onChange={e => mainForm.customHandleChange(e, "tenantName")}
                  // onBlur={e => mainForm.customHandleBlur(e, "tenantName")}
                />
                {/* <span className="dangerColor caption textCenter">
                  {mainForm.touched["tenantName"] && mainForm.errors["tenantName"]}
                </span> */}
                </Col>
                {/* <Col xs={24} sm={12} md={8}>
                  <MonthPickerFilter
                    months={{
                      value: filters.month,
                      onChange: val => handleFilterChange(val)
                    }}
                  />
                </Col>

                <Col xs={24} sm={12} md={8}>
                  <YearPicker
                    style={{ width: "100%" }}
                    className="no-mr"
                    style={{ width: "100%" }}
                    allowClear={false}
                    open={isOpen}
                    picker="year"
                    filters={filters}
                    value={selectedYear}
                    onOpenChange={open => setOpen(open)}
                    onFocus={() => setOpen(true)}
                    onBlur={() => setOpen(false)}
                    format="YYYY"
                    noBorder
                    mode="year"
                    placeholder="Year"
                    onPanelChange={handleYearChange}
                  />
                </Col> */}
              </Row>
            </Col>
          </Row>
          <Row
            className="mr-b"
            type="flex"
            align="middle"
            justify="space-between"
          >
            <Col>
              {props.settings && props.settings.is_reconciled ? (
                props.settings.is_reconciled === "true" ? (
                  <h4>Reconciled transactions hidden</h4>
                ) : (
                  <h4>Reconciled transactions shown</h4>
                )
              ) : null}
            </Col>
            {/* <Col>
              <Legend
              label="Cleared"
              />
            </Col> */}
          </Row>
          {/* new Table Wrapper */}
          <TableWrapper
            tableData={props.transactionDetails.bankTransactions}
            getData={state.apiCall}
            getTrProps={(state, rowInfo, column) => ({
              onClick: () =>
                props.history.push({
                  pathname: `/view-transaction/${rowInfo.original.transaction_source_id}`,
                  state: {
                    referrer: props.match.url,
                    referrerState: {
                      month: filters.month,
                      year: filters.year,
                      tenant
                    }
                  }
                }),
              style: { cursor: "pointer" }
              //className: rowInfo && rowInfo.original.cleared === "true" ? "cleared" : ""
            })}
            getTdProps={(state, rowInfo, column, c, d) => {
              return column.className === "clickable" ? {
                  onClick: e => e.stopPropagation(),
                  style: { cursor: "default" }
                } : {

                }
              }
            }
            // search={search}
            filters={filters}
            pageSize={10}
            dataCount={props.transactionDetails.bankTransactionCount}
            // loading={props.transactionDetails.tableLoading}
            //enableReInit={true}
            tableChanged={tableChanged}
            content={[
              {
                name: "Account Name",
                id: "account_name",
                render: r => (
                  <span
                    // style={{
                    //   fontStyle: r.original.is_split === "true" ? "italic" : "",
                    // }}
                    className={
                      r.original.is_split === true
                        ? null
                        : r.original.account_type === "income"
                        ? "appColor"
                        : "secondaryAppColor"
                    }
                  >
                    {r.original.is_split === true ? (
                      <TagWrapper
                      style={{
                        width: "100px",
                        backgroundColor: "orange"
                        //fontStyle:"italic"
                      }}
                      className="small"
                      // type={
                        // map[
                        //   // r.original.is_recurring === "true"
                        //   //   ? "RECURRING"
                        //   //   :
                        //   //r.original.transaction_type.toUpperCase()
                        // ]
                      //}
                    >
                      {
                        // r.original.is_recurring === "true"
                        //   ? "Recurring"
                        //   :
                        "Splits"
                      }
                    </TagWrapper>
                      // <span style={{ fontStyle: "italic", color: "red" }}> Splits</span>
                    ) : r.original.account_name !== "" ? (
                      r.original.account_name
                    ) : (
                      "-"
                    )}
                  </span>
                )
              },
              {
                name: "Tenant Name",
                id: "tenant_name"
              },
              {
                name: "Property Name",
                id: "property_name"
              },
              {
                name: "Date",
                id: "date_of_transaction",
                render: r => (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    {r.original.date_of_transaction
                      ? moment(r.original.date_of_transaction).format(
                          "MM/DD/YYYY"
                        )
                      : "-"}
                    &nbsp;
                    <FiInfo
                      style={{ pointer: "cursor" }}
                      onClick={e => {
                        console.log("Modal Attaced");
                        e.stopPropagation();
                        props.openModal(
                          null,
                          <BankAccountSummary banAccount={r.original} />,
                          null,
                          null,
                          {
                            footer: null
                          }
                        );
                      }}
                    />
                  </span>
                )
              },

              {
                name: "Amount",
                id: "amount",
                render: r => (
                  <span
                    // style={{
                    //   fontStyle: r.original.is_split === "true" ? "italic" : "",
                    // }}
                    className={
                      // r.original.is_split === "true"
                      //   ? ""
                      //   :
                      r.original.account_type === "income"
                        ? "appColor" :
                        r.original.transaction_type === "interest withdrawal" || r.original.transaction_type === "withdrawal" ? 
                        "secondaryAppColor" : "appColor"
                    }
                  >
                    {r.original.is_split === "true"
                      ? AccountingFormat(r.original.amount, r.original.currency)
                      : AccountingFormat(
                          r.original.amount,
                          r.original.currency
                        )}
                  </span>
                )
              },
              {
                name: "Cleared",
                id: "cleared",
                className: "clickable",
                render: r => (
                  console.log("r orignal cleared: ", r.original.cleared),
                  ( r.original.transaction_type === "interest deposit" || r.original.transaction_type === "interest withdrawal" ? "-"
                  // <div style={{ width: "100%" }} onClick={e => e.stopPropagation()}>
                  : <React.Fragment>
                    {r.original.transaction_type != "transfer" && <PopconfirmWrapper
                      //onClick={e => e.stopPropagation()}
                      title={
                        <h4>
                          Are you sure you want to
                          {r.original.cleared === true
                            ? ` unclear `
                            : ` clear`}{" "}
                          this transaction?
                        </h4>
                      }
                      icon={
                        <FiAlertTriangle
                          style={{
                            position: "absolute",
                            color: "#f44336",
                            left: 0,
                            top: 0,
                            marginTop: 6
                          }}
                        />
                      }
                      cancelText="No"
                      okText="Yes"
                      onConfirm={() =>
                        props.ClearedTransactionsRequest(
                          r.original.transaction_source_id,
                          r.original.cleared === true ? false : true,
                          () => setTableChanged(!tableChanged)
                        )
                      }
                    >
                      <div
                        style={{
                          //padding: "8px",
                          //width: "100%",
                          display: "flex",
                          alignItems: "center"
                        }}
                        //onClick={e => e.stopPropagation()}
                      >
                      <Switch
                        disabled
                        style={{
                          //padding: "8px",
                          //width: "100%",
                          opacity:"1"
                        }}
                        checked={r.original.cleared === true ? true : false}
                        />
                      </div>
                    </PopconfirmWrapper>}
                  </React.Fragment>
                  // </div>
                ))
              },
              {
                name: "Transaction",
                id: "transaction_type",
                large: true,
                render: r => (
                  console.log("transaction type: ", r.original.transaction_type),
                  <React.Fragment>
                    <TagWrapper
                      style={{ width: "100%", textTransform: "capitalize", backgroundColor: r.original.transaction_type === "deposit" || r.original.transaction_type === "interest deposit" ? "#58ba27" : "#ff4a4d"}}
                      className="small"
                      type={
                        map[
                          // r.original.is_recurring === "true"
                          //   ? "RECURRING"
                          //   :
                          r.original.transaction_type.toUpperCase()
                        ]
                      }
                    >
                      {// r.original.is_recurring === "true"
                      //   ? "Recurring"
                      //   :
                      r.original.transaction_type
                        ? r.original.transaction_type
                        : "-"}
                    </TagWrapper>
                    <span style={{ marginLeft: "1rem" }}>
                      <PopOver
                        onClick={e => e.stopPropagation()}
                        // placement="left"
                        style={{ cursor: "pointer" }}
                        content={
                          <div onClick={e => e.stopPropagation()}>
                            <div
                              style={{ padding: "8px" }}
                              style={{
                                padding: "8px",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center"
                              }}
                              onClick={() =>
                                props.history.push({
                                  pathname: `/view-transaction/${r.original.transaction_source_id}`,
                                  state: {
                                    referrer: props.match.url,
                                    referrerState: {
                                      month: filters.month,
                                      year: filters.year,
                                      job
                                    }
                                  }
                                })
                              }
                            >
                              <FiEdit onClick="" style={{ marginRight: 5 }} />
                              <span>Edit</span>
                            </div>

                            <Divider style={{ margin: 0 }} />

                            {/* poprConfirm */}

                            <PopconfirmWrapper
                              onClick={e => e.stopPropagation()}
                              title={
                                <h4>
                                  Are you sure you want to delete this
                                  transaction?
                                </h4>
                              }
                              icon={
                                <FiAlertTriangle
                                  style={{
                                    position: "absolute",
                                    color: "#f44336",
                                    left: 0,
                                    top: 0,
                                    marginTop: 6
                                  }}
                                />
                              }
                              cancelText="No"
                              okText="Yes"
                              onConfirm={() =>
                                props.deleteTransactionRequest(
                                  r.original.transaction_source_id,
                                  () => setTableChanged(!tableChanged)
                                )
                              }
                            >
                              {/* <ButtonWrapper className="deleteBtn"> */}
                              <div
                                onClick={e => e.stopPropagation()}
                                style={{
                                  padding: "8px",
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                <AiOutlineDelete
                                  className="deleteIcon"
                                  style={{ color: "#FF494C", marginRight: 5 }}
                                />
                                <span>Delete</span>
                              </div>
                              {/* </ButtonWrapper> */}
                            </PopconfirmWrapper>

                            {/* <Divider style={{ margin: 0 }} />
                            <PopconfirmWrapper
                              title={
                                <h4>
                                  Are you sure you want to
                                  {r.original.cleared === "true"
                                    ? ` unclear `
                                    : ` clear`}{" "}
                                  this transaction?
                                </h4>
                              }
                              icon={
                                <FiAlertTriangle
                                  style={{
                                    position: "absolute",
                                    color: "#f44336",
                                    left: 0,
                                    top: 0,
                                    marginTop: 6
                                  }}
                                />
                              }
                              cancelText="No"
                              okText="Yes"
                              onConfirm={() =>
                                props.ClearedTransactionsRequest(
                                  r.original.transaction_source_id,
                                  r.original.cleared === "true"
                                    ? "false"
                                    : "true",
                                  () => setTableChanged(!tableChanged)
                                )
                              }
                            >
                              <div
                                style={{
                                  padding: "8px",
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                <Switch
                                  checked={
                                    r.original.cleared === "true" ? true : false
                                  }
                                  size="small"
                                  style={{ marginRight: 5 }}
                                />{" "}
                                <span>Cleared</span>
                              </div>
                            </PopconfirmWrapper> */}
                          </div>
                        }
                      >
                        <FiMoreHorizontal style={{ color: "#154D94" }} />
                      </PopOver>
                    </span>
                  </React.Fragment>
                )
              }
            ]}
          />
        </Fragment>
      )}
      {/* Build Table to view the Transactions */}
      {/* <TableWrapper
        // ------- Map state to props
        tableData={props.transactions.transactions}
        // ------- Dispatch request
        getData={props.MessagesRequest}
        // ------- filter data according to the query params
        filters={{
          month: selectedMonth !== "" ? selectedMonth : null,
          year: selectedYear
        }}
        pageSize={10}
        // Total count for calculation
        dataCount={props.transactions.total_count}
        content={[
          {
            name: "Account Name",
            id: "id",
            //centered: true,
            //small: true,
            sortable: false,
            render: r => {
              return (
                <span>
                  {r.original.id}
                </span>
              );
            }
          },
          {
            name: "Amount",
            id: "amount",
            render: r => {
              return <span>${r.original.amount}</span>;
            }
          },
          {
            name: "Type",
            id: "type",
            sortable: false,
            //render: r => <TypeIndicator type={r.original.typeId} />
          },
          {
            name: "Date",
            id: "date",
            sortable: false,
            //render: r => (
            //  <span>{moment(r.original.createdAt).format("DD/MM/YYYY")}</span>
            //)
          }
        ]} 
      /> */}
    </Fragment>
  );
};

export default withRouter(
  connect(
    storeState => ({
      bankDetails: storeState.AddBankAccount,
      transactionDetails: storeState.transaction,
      settings: storeState.settings.settings
    }),
    {
      getBankTransactionsRequest,
      deleteTransactionRequest,
      ClearedTransactionsRequest,
      getUserSettingRequest,
      openModal
    }
  )(BankAccountDetails)
);
