import React, { Fragment } from "react";
import { Col } from "antd";
import PieContainer from "./PieContainer";
import PieChart from "../../../components/charts/PieChart";
import BarChart from "../../../components/charts/BarChart";
import {AccountingFormat} from "../../../configurations/Config"

import useScreenWidth from "../../../components/hooks/useScreenWidth";

const chartColor = [
  "#003F5C",
  "#2F4B7C",
  "#665191",
  "#A05195",
  "#D45087",
  "#F95D6A",
  "#FF7C43",
  "#FFA600",
  "#47ACB1",
  "#F26522",
  "#542923",
  "#286C4F",
  "#96247A",
  "#FFCD34",
  "#3D0053",
  "#4F209C"
];

const appColors = [
  "#58ba27",
  "#ff4a4d"
]

const PieCharts = props => {
  console.log("Charts Props", props);
  const {
    chartLoad,
    totalAmount,
    currency,
    accountType,
    transactions,
    incomeTrans,
    expenseTrans,
    totalIncome,
    totalExpense,
    transTotals,
    totalsByDate
  } = props.charts;
  const obj = Object.keys(transactions[0]);
  console.log("Charts data obj: ", obj);
  console.log("transtotals: ", incomeTrans);
  const size = useScreenWidth();
  console.log("sadder: ", totalAmount);
  return (
    <Fragment>
      {accountType.toLowerCase() !== "both" ? (
        // if Account Head is selected, account_type will be income | expense
        <Col xs={24} md={12}>
          {/* If you go to PieContainer.js file. 
          I have commented the Responsve Container because of it Chart is not showing up */}
          <PieContainer
            container={{
              width: "100%",
              height: "100%"
            }}
            heading={
              <h4>
                {accountType.toLowerCase() === "income" ? (
                  <span className="appColor">Income</span>
                ) : (
                  <span className="dangerColor">Expense</span>
                )}{" "}
                By {props.filterText}
              </h4>
            }
            totalAmount={AccountingFormat(totalAmount, currency)}
          >
            {transactions && transactions.length ? (
              <PieChart
              pieColor={chartColor}
              account={props.account}
              tenant={props.customer}
              property={props.job}
              total={totalAmount}
              data={transactions}
              loading={chartLoad}
              totalAmount={totalAmount}
              width={size < 450 || size < 990 && size > 767 ? 200 : 300}
            />
            ) : (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: 300 }}>
                <h4>No transactions</h4>
              </div>
            )}
          </PieContainer>
        </Col>
      ) : (
        <Fragment>
          <Col xs={24} md={12}>
            <PieContainer
              heading={
                <h4>
                  <span className="appColor">Income</span>
                  {" "}By {props.filterText}
                </h4>
              }
              totalAmount={AccountingFormat(totalIncome, currency)}
            >
              {incomeTrans && incomeTrans.length ? (
                <PieChart
                account={props.account}
                tenant={props.customer}
                property={props.job}
                total={totalIncome}
                pieColor={chartColor}
                data={incomeTrans}
                loading={chartLoad}
                totalAmount={totalIncome}
                width={size < 450 || size < 990 && size > 767 ? 200 : 300}
                />
              ) : (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: 300 }}>
                  <h4>No income transactions</h4>
                </div>
              )}
            </PieContainer>
          </Col>
          <Col xs={24} md={12}>
            <PieContainer
              heading={
                <h4>
                  <span className="dangerColor">Expense</span>
                  {" "}By {props.filterText}
                </h4>
              }
              totalAmount={AccountingFormat(totalExpense, currency)}
            >
              {expenseTrans && expenseTrans.length ? (
                <PieChart
                  pieColor={chartColor}
                  account={props.account}
                  tenant={props.customer}
                  property={props.job}
                  total={totalExpense}
                  data={expenseTrans}
                  loading={chartLoad}
                  totalAmount={totalExpense}
                  width={size < 450 || size < 990 && size > 767 ? 200 : 300}
                />
              ) : (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: 300 }}>
                  <h4>No expense transactions</h4>
                </div>
              )}
            </PieContainer>
          </Col>
          <Col xs={24} md={12}>
            <PieContainer
              heading={
                <h4>
                  <span className="dangerColor">Expense</span>
                  {" "}vs{" "}<span className="appColor">Income</span>{" "}By {props.filterText}
                </h4>
              }
              negative={totalIncome - totalExpense < 0}
              totalAmount={AccountingFormat(totalIncome - totalExpense, currency)}
            >
              {transTotals && transTotals.length ? (
                <PieChart
                pieColor={appColors}
                total={totalAmount}
                data={transTotals}
                loading={chartLoad}
                totalAmount={totalIncome + totalExpense}
                width={size < 450 || size < 990 && size > 767 ? 200 : 300}
                />
              ) : (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: 300 }}>
                  <h4>No transactions</h4>
                </div>
              )}
            </PieContainer>
          </Col>
          <Col xs={24} md={12}>
            <PieContainer
              heading={
                <h4>
                  <span className="dangerColor">Expense</span>
                  {" "}vs{" "}<span className="appColor">Income</span>{" "}Histogram By {props.filterText}
                </h4>
              }
              negative={totalIncome - totalExpense < 0}
              totalAmount={AccountingFormat(totalIncome - totalExpense, currency)}
              //totalAmount={currency + totalExpense}
            >
              {totalsByDate && totalsByDate.length ? (
                <BarChart barColor={appColors} data={totalsByDate} width={size < 450 || size < 990 && size > 767 ? 200 : size < 1500 && size > 989 ? 300 : 500} margin={size < 450 || size < 990 && size > 767 ? { top: 0, right: 20, left: 20, bottom: 0 } : { top: 0, right: 50, left: 50, bottom: 0 }}/>
              ) : (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: 300 }}>
                  <h4>No transactions</h4>
                </div>
              )}
            </PieContainer>
          </Col>
          {/* Histrogram comes down here */}
        </Fragment>
      )}
    </Fragment>
  );
};

export default PieCharts;
