import React from "react";
import { Layout, Row, Col } from "antd";
import { withRouter, Link } from "react-router-dom";
import styled from "styled-components";
import ContentWrapper from "../../components/maincontent";
import Image from "../../components/image";
import useScreenWidth from "../../components/hooks/useScreenWidth";
import { FiTwitter, FiFacebook, FiLinkedin, FiInstagram } from "react-icons/fi";
import { fullFooterLogo } from "../../configurations/Config";
import appleDownload from "../../assets/icons/apple-store-apple.svg";
import androidDownload from "../../assets/icons/google-play-badge.svg";
import BackButton from "../../appcomponents/shares/header/BackButton";
const ContentWrap = styled(ContentWrapper)`
  margin: 0;
  padding: 0;
  min-height: auto;
`;
const LayoutFlow = styled(Layout)`
  min-height: 100vh;
  background-color: #ffffff;
  text-align: center;
  .footerSection {
    margin-top: 7.5rem !important;
  }
`;

const ContainerFlow = styled(Layout)`
  background-color: ${props =>
    props.theme[props.theme.mode].background.primary};
  width: 85%;
  margin: 2.65vw auto;
  position: relative;

  & .iconBtn {
    background-color: ${props => props.theme[props.theme.mode].secondary.main};
    display: inline-block;
    padding: 8px;
    border-radius: 5px;
  }
  .socialIcons {
    border-radius: 70%;
    background-color: #efad4e;
    height: 2.2rem;
    width: 2.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Footer = props => {
  const size = useScreenWidth();
  return (
    <div className="footerSection">
      <ContainerFlow
        style={{
          marginTop: 0,
          overflow: "hidden"
        }}
      >
        <Row
          gutter={[60, 20]}
          type="flex"
          align="middle"
          justify="center"
          className="textCenter"
        >
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={6}
            xl={6}
            className={size >= 991 ? "textLeft" : "textCenter"}
          >
            {fullFooterLogo()}
          </Col>
          <Col span={24} xs={24} sm={24} md={24} lg={18} xl={18}>
            <Row
              type="flex"
              align="middle"
              gutter={[20, 20]}
              justify={size >= 992 ? "end" : "center"}
            >
              <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>
                    <Image src={appleDownload} alt="IOS" />
                  </div>
                </a>
              </Col>
              <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>
                    <Image src={androidDownload} alt="ANDROID" />
                  </div>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* //try */}
        <Row
          gutter={[20, 40]}
          type="flex"
          align="middle"
          justify={size >= 991 ? "start" : "space-around"}
          className="textCenter caption"
          style={{ color: "black", marginTop: "2rem", marginBottom: "4rem" }}
        >
          <Col xs={24} md={12} lg={8} style={{ textAlign: "left" }}>
            <p className="fontMedium">
              &copy; Copyright EscrowTrakker, all rights reserved
            </p>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Row type="flex" justify="center">
              <Col span={10}>
                <Link
                  to="/privacy"
                  style={{ color: "#1E1E22", textDecoration: "underline" }}
                  className="fontMedium"
                >
                  Privacy Policy
                </Link>
              </Col>
              <Col span={10}>
                <Link
                  to="/terms-and-conditions"
                  style={{ color: "#1E1E22", textDecoration: "underline" }}
                  className="fontMedium"
                >
                  Terms & Condition
                </Link>
              </Col>
            </Row>
          </Col>

          <Col xs={24} md={24} lg={8}>
            <Row
              type="flex"
              justify={size >= 991 ? "end" : "center"}
              gutter={10}
            >
              <Col span={4}>
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socialIcons"
                >
                  <FiFacebook className="whiteColor normal" />
                </a>
              </Col>

              <Col span={4}>
                <a
                  href="https://www.instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socialIcons"
                >
                  <FiInstagram className="whiteColor normal" />
                </a>
              </Col>

              <Col span={4}>
                <a
                  href="https://www.twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socialIcons"
                >
                  <FiTwitter className="whiteColor normal" />
                </a>
              </Col>

              <Col span={4}>
                <a
                  href="https://www.linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socialIcons"
                >
                  <FiLinkedin className="whiteColor normal" />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* try */}
      </ContainerFlow>
    </div>
  );
};

const StaticPage = props => {
  return (
    <ContentWrap>
      <LayoutFlow>
        <Row
          style={{
            padding: "4vh 0px 0px",
            flexGrow: 1,
            flexWrap: "nowrap",
            flexDirection: "column",
            width: "85%",
            margin: "0 auto"
          }}
          type="flex"
          justify="space-between"
          align="middle"
        >
          <Col
            span={24}
            style={{ textAlign: props.linked ? "left" : "center" }}
          >
            <BackButton action={() => props.history.push("/")} title="Back" />
          </Col>
          <Col
            style={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              marginTop: "12vh",
              marginBottom: "12vh"
            }}
            span={24}
          >
            <Row
              style={{ flexDirection: "column", flexGrow: 1 }}
              type="flex"
              justify="space-between"
            >
              <Col span={24}>
                <Row type="flex" justify="center">
                  <Col span={24}>
                    <h2 style={{ marginBottom: "1em" }}>{props.heading}</h2>
                  </Col>
                  <Col xxl={16} xl={16} lg={16} md={16} sm={20} xs={24}>
                    <p style={{ textAlign: "left" }} className="hintColor">
                      {props.content}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Col span={24} className="caption">
          <Footer />
        </Col>
      </LayoutFlow>
    </ContentWrap>
  );
};

export default withRouter(StaticPage);
