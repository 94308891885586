import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Select from "../../../../components/input/Select";
import Option from "../../../../components/input/SelectOptions";
import BankName from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/BankName";
import OptionsField from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/OptionsField";
import MonthPicker from "../../../../appcomponents/EscrowTrakker/detailbanks/FiltersTransaction";
import DatePicker from "../../../../components/datepickers/DatePicker";
//import Select from "../../../../components/input/Select";
//import Option from "../../../../components/input/SelectOptions";
import useInterestCalcForm from "../../forms/reports/useInterestCalcForm";
import { connect } from "react-redux";
import RootForm from "../../../../components/form/index";
import Button from "../../../../components/button";
import { changePassword } from "../../../../configurations/Schemas/index";
import { Row, Col } from "antd";
import { changePasswordRequest } from "../../../../actions/App";
import { transferJournalLedgerRequest } from "../../../../actions/Reports";
import { openModal, closeAllModal } from "../../../../actions/Modal";
import Field from "../../../EscrowTrakker/formcontrols/InputWithTitle/index";
import moment from "moment";
import InterestCalculatorResult from "./InterestCalculatorResult";
import { FiPercent } from "react-icons/fi";
import { message } from 'antd';
import info from "../../../../components/message/index";
import { getUserSettingRequest } from "../../../../actions/Settings";
const InterestCalc = props => {
  const [yearPickerOpen, setYearPickerOpen] = useState(false);

  const mainForm = useInterestCalcForm({
    initialValues: props.initialValues ? {
      ...props.initialValues
    } : {
      tenant_id: "",
      movein_date: moment().format("YYYY-MM-DD"),
      moveout_date: moment().format("YYYY-MM-DD"),
      interestRate: "",
      amount: "",
      // date_type: "monthly",
      // month: moment().month() + 1,
      // year: moment().year()
    },
    //props.transferJournalLedgerRequest({ ...values, format: props.format }, report
    onSubmit: values => {
      //props.closeAllModal();
      console.log("values are: ", values);
      props.openModal(
        null,
        <InterestCalculatorResult values={values} />,
        null,
        null,
        {
          footer: null
        }
      );
      // props.history.push({ pathname: "/reports/preview-journal-transfer-ledger", state: { 
      //     referrer: "/reports",
      //     referrerState: {
      //       ...props.location.state,
      //       transferJournalLedgerValues: {
      //         formValues: values,
      //         format: props.format
      //       }
      //     }, 
      //     report, 
      //     format: props.format 
      //   } 
      // })
    }
  })

  console.log("FORM", mainForm)
  console.log("FORMER", mainForm.values)
  console.log("FORMER TYPE", Date.parse(mainForm.values.moveout_date)<Date.parse(mainForm.values.movein_date));
  const CompareDates = () => {
    var d1 = Date.parse(mainForm.values.moveout_date);
    var d2 = Date.parse(mainForm.values.movein_date);
    if (d1 === d2) {
      console.log("yesyesyes");
      //message.info("Move out date should be greater than Move in date", 1000);
        //return false;
        info("info", "Move out date should be greater than Move in date");
    }
    else {
      console.log("yesyesyesye");
    }
  }
  // useEffect(() => {
  //   CompareDates();
  // }, [])
  useEffect(() => {
    props.getUserSettingRequest();
  }, []);
  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      className="textCenter"
      gutter={[0, 30]}
    >
      <Col span={24} style={{ padding: 0 }}>
        <h4 className="mr-b mr-t-md" style={{ marginBottom: "2.5rem" }}>
        Interest Calculator
        </h4>
      </Col>

      <Col xs={24} sm={20} md={22} lg={16}>
        <Row type="flex" justify="center">
          {/* <Col span={24} style={{ textAlign: "left" }}>
            <OptionsField
              className="inputField"
              //title="Lawyer Name"
              //addBtnText="Add Lawyer"
              name="tenant_id"
              //link="/add-contact"
              contactType="tenant"
              placeholder="Select Tenant"
              withoutAddButton
              withClearOption
              //isInterest={true}
              initialValue={mainForm.values.tenant_name}
              isCharts={true}
              onChange={(id, name) => {
                mainForm.customHandleChange(id, "tenant_id");
                mainForm.customHandleChange(name, "tenant_name");
              }}
              onBlur={e => mainForm.customHandleBlur(e, "tenant_id")}
            />
            {mainForm.touched["tenant_id"] && mainForm.errors["tenant_id"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["tenant_id"] && mainForm.errors["tenant_id"]}
            </div> : null}
          </Col> */}
          
          <Col span={24} style={{ textAlign: "left" }}>
          <OptionsField
            title="Tenant Name"
            addBtnText="Add Tenant"
            name="tenantName"
            //link="/add-contact"
            withoutAddButton
            isCharts={true}
            contactType="tenant"
            placeholder="Select Tenant"
            initialValue={mainForm.values.tenant_name}
            //initialValue={JSON.parse(localStorage.getItem("tenantName"))}
            onChange={(id,name) => {
              mainForm.customHandleChange(id, "tenant_id");
              mainForm.customHandleChange(name, "tenant_name");
            }}
            //initialValue={localStorage.getItem("tenantName") ? localStorage.getItem("tenantName") : null}
            onBlur={e => mainForm.customHandleBlur(e, "tenantName")}

          />
          {mainForm.touched["tenant_id"] && mainForm.errors["tenant_id"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["tenant_id"] && mainForm.errors["tenant_id"]}
            </div> : null}
        </Col>  

          <Col span={24} style={{ textAlign: "left", marginTop: "10px" }}>
            <h4>Move In Date</h4>
          </Col>
          <Col span={24} style={{ textAlign: "left" }}>
            <DatePicker
              allowClear={false}
              //title="Move In Date"
              //open={yearPickerOpen}
              //onOpenChange={open => setYearPickerOpen(open)}
              modalField
              className="inputField"
              name="movein_date"
              value={
                mainForm.values.movein_date
                  ? moment(mainForm.values.movein_date)
                  : undefined
              }
              //onChange={e => mainForm.customHandleChange(e, "year")}
              //onFocus={() => setYearPickerOpen(true)}
              onBlur={(e) => {
                mainForm.customHandleBlur(e, "movein_date");
                //setYearPickerOpen(false);
              }}
              style={{ width: "100%" }}
              //picker="year"
              format="MM-DD-YYYY"
              //mode="year"
              placeholder="MM/DD/YY"
              onChange={(e) => {
                // if(!mainForm.values.date_type === "quaterly")
                //   mainForm.customHandleChange(e.year(), "year")
                // else
                //   mainForm.onChangeQuarterYear(e, "year")
                mainForm.customHandleChange(e.format("YYYY-MM-DD"), "movein_date");

                //setYearPickerOpen(false);
              }}
            />
            {mainForm.touched["movein_date"] && mainForm.errors["movein_date"] ? (
              <div className="dangerColor caption mr-b">
                {mainForm.touched["movein_date"] && mainForm.errors["movein_date"]}
              </div>
            ) : null}
          </Col>

          <Col span={24} style={{ textAlign: "left" }}>
            <h4>Move Out Date</h4>
          </Col>
          <Col span={24} style={{ textAlign: "left" }}>
            <DatePicker
              allowClear={false}
              //title="Move In Date"
              //open={yearPickerOpen}
              //onOpenChange={open => setYearPickerOpen(open)}
              modalField
              className="inputField"
              name="moveout_date"
              value={
                mainForm.values.moveout_date
                  ? moment(mainForm.values.moveout_date)
                  : undefined
              }
              //onChange={e => mainForm.customHandleChange(e, "year")}
              //onFocus={() => setYearPickerOpen(true)}
              onBlur={(e) => {
                mainForm.customHandleBlur(e, "moveout_date");
                //setYearPickerOpen(false);
              }}
              style={{ width: "100%" }}
              //picker="year"
              format="MM-DD-YYYY"
              //mode="year"
              placeholder="MM/DD/YY"
              onChange={(e) => {
                // if(!mainForm.values.date_type === "quaterly")
                //   mainForm.customHandleChange(e.year(), "year")
                // else
                //   mainForm.onChangeQuarterYear(e, "year")
                mainForm.customHandleChange(e.format("YYYY-MM-DD"), "moveout_date");
                //CompareDates();  
                //setYearPickerOpen(false);
              }}
            />
            {mainForm.touched["moveout_date"] && mainForm.errors["moveout_date"] ? (
              <div className="dangerColor caption mr-b">
                {mainForm.touched["moveout_date"] && mainForm.errors["moveout_date"]}
              </div>
            ) : null}
          </Col>

        <Col span={24} style={{ textAlign: "left" }}>
          <Field
          //key={formId}
            title="Interest Amount"
            name="interestRate"
            value={mainForm.values.interestRate}
            suffix={<FiPercent style={{ fontSize: "0.91rem" }} />}
            //checkValue={props?.location?.state?.initialValues?.interestRate ? props.location.state.initialValues.interestRate : null}
            onChange={e => mainForm.customHandleChange(e, "interestRate")}
            onBlur={e => mainForm.customHandleBlur(e, "interestRate")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["interestRate"] && mainForm.errors["interestRate"]}
          </span>
        </Col>

        <Col span={24} style={{ textAlign: "left" }}>
          <Field
          //key={formId}
            title="Escrow Amount"
            placeholder={`${props.settings.currency ? props.settings.currency : "$"}0.00`}
            value={mainForm.values.amount}
            //onChange={mainForm.handleTotalChange}
            //onBlur={mainForm.handleBlur}
            name="amount"
            onChange={e => mainForm.customHandleChange(e, "amount")}
            onBlur={e => mainForm.customHandleBlur(e, "amount")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["amount"] && mainForm.errors["amount"]}
          </span>
        </Col>

          <Col span={16} className="mr-t-md">
            <Button loading={props.reports.transferJournalLedgerLoading} block onClick={mainForm.handleSubmit}>Calculate</Button>
          </Col>

          <Col span={16} className="mr-t">
            <Button block outlined onClick={props.closeAllModal}>Cancel</Button>
          </Col>
          
        </Row>
      </Col>
    </Row>
  );
};

export default withRouter(connect(state => ({ reports: state.Reports, settings: state.settings.settings }), { transferJournalLedgerRequest, getUserSettingRequest, closeAllModal, openModal })(InterestCalc));